import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Input, Label, Modal } from 'semantic-ui-react';
import { LogErrors, notifyError, removeId, translateContent, translateErrorMessages, translateSuccessMessages } from '../../../resources/lib';
import { addDataToCollection, updateDataByDocId } from '../../../resources/firebaseLib';

const BusinessWorkTimesModal = (props) => {
    const [open, setOpen] = useState(false)
    const [formData, setFormData] = useState({ ...props.item })

    const content = translateContent(localStorage.getItem('Language'))
    const errorMessages = translateErrorMessages(localStorage.getItem('Language'))
    const successMessages = translateSuccessMessages(localStorage.getItem('Language'))

    const restoreValues = useCallback(async () => {
        setFormData(props.item)
    }, [props.item])

    useEffect(() => {
        if (!open)
            restoreValues()
    }, [restoreValues, open])

    const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
    const weekDaysNames = content.modals.work_times.week_day_names

    async function populateCollection(data) {
        try {
            return await addDataToCollection('business_work_times', validateWeekdayKeys(data))
        } catch (error) {
            LogErrors(error)
            return false
        }
    }

    async function handleSubmit(event) {
        event.preventDefault()
        if (props.new) {
            const result = await populateCollection(formData)
            if (result) {
                setOpen(false)
                props.handleModal(successMessages.created)
            }
            else {
                notifyError(errorMessages.contact_support)
            }
        }
        else {
            const result = await updateDataByDocId('business_work_times', formData.id, validateWeekdayKeys(removeId(formData)))
            if (result) {
                setOpen(false)
                props.handleModal(successMessages.updated)
            }
            else {
                notifyError(errorMessages.contact_support)
            }
        }
    }

    function validateWeekdayKeys(obj) {
        const result = { ...obj }

        Object.keys(result).forEach((key) => {
            if (key !== "businessId" && key !== "id" && key !== "ownerEmail") {
                if (result[key].startsWith("-") || result[key].endsWith("-")) {
                    result[key] = ""
                }
            }
        })

        return result
    }

    function handleInputChange(event) {
        const { name, value } = event.target
        const splitData = name.split('-')
        const splitTimes = formData[splitData[0]].split('-')
        let realValue = -1
        if (splitData[1] === '1')
            realValue = `${value}-${splitTimes[1]}`
        else
            realValue = `${splitTimes[0]}-${value}`
        setFormData((prevFormData) => ({ ...prevFormData, [splitData[0]]: realValue }))
    }

    function convertTimeToMinutes(time) {
        try {
            const [hours, minutes] = time.split(':').map(Number)
            return hours * 60 + minutes
        }
        catch (err) {
            return 0
        }
    }


    function getDayInputs(day, name) {
        const splitHours = formData[day].includes('-') ? formData[day].split('-') : ['', '']
        return (
            <React.Fragment key={day}>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <label className='big-text shadow-text brown-text'>{name}</label>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field required={splitHours[1] !== ''}>
                        <label className='large-text shadow-text white-text'>{content.modals.work_times.open_label}</label>
                        <Input
                            type='time'
                            placeholder={`${name}`}
                            name={`${day}-1`}
                            value={splitHours[0]}
                            onChange={handleInputChange}
                            required={splitHours[1] !== ''}
                        />
                    </Form.Field>
                    <Form.Field required={splitHours[0] !== ''}>
                        <label className='large-text shadow-text white-text'>{content.modals.work_times.close_label}</label>
                        <Input
                            type='time'
                            placeholder={`${name}`}
                            name={`${day}-2`}
                            value={splitHours[1] === 'undefined' ? '' : splitHours[1]}
                            onChange={handleInputChange}
                            required={splitHours[0] !== ''}
                        />
                        {(splitHours[0] && splitHours[1] && (convertTimeToMinutes(splitHours[0]) > convertTimeToMinutes(splitHours[1]))) &&
                            <Label basic color='red' pointing>
                                {errorMessages.worktimes_error}
                            </Label>
                        }
                    </Form.Field>
                </Form.Group>
            </React.Fragment>
        )
    }

    function validateData(formData) {
        for (let day of weekDays) {
            if (formData[day] !== '') {
                const splitHours = formData[day].includes('-') ? formData[day].split('-') : ['', '']
                if ((splitHours[0] && splitHours[1] && (convertTimeToMinutes(splitHours[0]) > convertTimeToMinutes(splitHours[1])))) {
                    return false
                }
            }
        }
        return true
    }


    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={props.component}
        >
            {open &&
                <>
                    <Modal.Header id='modal-title' className='black-back-color bigger-text shadow-text'>{props.new ? content.modals.work_times.title_new : content.modals.work_times.title_update}</Modal.Header>
                    <Modal.Content scrolling className='secondary-back-color'>
                        <Form onSubmit={handleSubmit}>
                            {weekDays.map((day, index) => {
                                return getDayInputs(day, weekDaysNames[index])
                            })
                            }
                            <Button
                                id='modal-cancel-btn'
                                type='button'
                                circular
                                className='third-color-active-btn'
                                onClick={() => setOpen(false)}>
                                {content.modals.cancel}
                            </Button>
                            <Button
                                id='modal-accept-btn'
                                circular
                                type='submit'
                                content={content.modals.save}
                                labelPosition='right'
                                icon='checkmark'
                                className='primary-color-active-btn'
                                disabled={validateData(formData) === false}
                            />
                        </Form>
                    </Modal.Content>
                </>
            }
        </Modal>
    )
}

export default BusinessWorkTimesModal
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'
import { Button, Container, Divider, Form, Header, Input, Segment } from 'semantic-ui-react';
import {  notifyError, translateContent, translateErrorMessages, notifySuccess, translateSuccessMessages, LogErrors } from '../../../resources/lib';
import { Navigate } from 'react-router-dom';
import Footer from '../../Global/Footer';

const RecoverPassword = () => {
    const [formData, setFormData] = useState('')
    const [token, setToken] = useState('')
    const [isVerified, setIsVerified] = useState(process.env.REACT_APP_ENV === 'dev' ? true : false)

    const content = translateContent(localStorage.getItem('Language'))
    const errorMessages = translateErrorMessages(localStorage.getItem('Language'))
    const successMessages = translateSuccessMessages(localStorage.getItem('Language'))

    async function handlePasswordReset(event) {
        event.preventDefault()
        if (isVerified) {
            const auth = getAuth()
            sendPasswordResetEmail(auth, formData)
                .then(() => {
                    notifySuccess(successMessages.password_reset_email_sent)
                })
                .catch((error) => {
                    LogErrors(error)
                    notifyError(errorMessages.change_password)
                })
        }
        else{
            notifyError(errorMessages.verify_robot)
        }
    }

    function handleInputChange(event) {
        const { value } = event.target
        setFormData(value)
    }

    function handleVerify(token) {
        setToken(token)
        setIsVerified(true)
    }

    function handleExpired() {
        setToken('')
        setIsVerified(false)
    }

    if (localStorage.getItem('UserInfo'))
        return <Navigate to='/' />

    return (
        <Container fluid>
            <Segment basic className='space-segment'>
                <Header id='page-title' inverted className='huge-text shadow-text' as='h1'>{content.reset_password.title}</Header>
                <Divider />
            </Segment>
            <Container fluid>
                <Container className='half-view'>
                    <Segment basic>
                        <div className='forms-small-box'>
                            <Form size='big' onSubmit={handlePasswordReset}>
                                <Form.Field required>
                                    <label className='big-text shadow-text brown-text'>{content.reset_password.email_label}</label>
                                    <Input icon='user' iconPosition='left' type='email' maxLength="100" placeholder={content.reset_password.email_placeholder} name="email" value={formData} onChange={handleInputChange} required />
                                </Form.Field>
                                {process.env.REACT_APP_ENV !== 'dev' &&
                                    <Form.Field>
                                        <div className='center-contents'>
                                            <ReCAPTCHA size='compact' sitekey='6LepmogkAAAAALDhmuvQ3yjtIPKfT4QwihCdbN51' onChange={e => handleVerify(e, token)} onExpired={handleExpired} />
                                        </div>
                                    </Form.Field>
                                }
                                <Button id='login-btn' circular size='big' className='secondary-color-btn' type='submit'>{content.reset_password.button}</Button>
                            </Form>
                        </div>
                    </Segment>
                </Container>
            </Container>
            <Footer />
        </Container>
    )
}

export default RecoverPassword
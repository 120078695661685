import React, { useState } from 'react';
import { Image, Modal } from 'semantic-ui-react';

const ImageModal = (props) => {
    const [open, setOpen] = useState(false)

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={props.component}
            centered
        >
            <Modal.Content>
                <Image
                    wrapped
                    size='massive'
                    src={props.src}
                    className='center-image'
                />
            </Modal.Content>
        </Modal>
    )
}

export default ImageModal

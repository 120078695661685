import { getCurrentDateTime } from "./lib"

export const emailTitlesEN = {
  accountCreated: `Reservado - New Account Created`,
  businessCreated: `Reservado - New Business Created`,
  bookingCreated: `Reservado - Booking Created`,
  bookingConfirmed: `Reservado - Booking Confirmed`,
  bookingWarning: `Reservado - Booking Reminder`,
  bookingFeedback: `Reservado - Feedback`,
  bookingAccepted: `Reservado - Booking Accepted by Customer`,
  bookingUpdated: `Reservado - Booking Updated`,
  accountDeleted: `Reservado - Account Deleted`,
  businessDeleted: `Reservado - Business Deleted`,
  bookingDeleted: `Reservado - Booking Cancelled`
}

export const emailTextEN = {
  accountCreated: 'This accType account has been created - fullAccName(accEmail).',
  businessCreated: 'This business has been created - fullAccName, the business(businessName)(accEmail).',
  bookingCreated: 'The booking in the name of fullAccName, at the business(businessName) is created and awaiting confirmation!',
  bookingConfirmed: 'The booking in the name of fullAccName, at the business(businessName) is confirmed!',
  bookingWarning: 'Don’t forget your booking for bookingDate, at the business(businessName).',
  bookingFeedback: 'Click the link below to share your experience with us.; Your opinion is essential for us to continue improving our service.;feedback/businessId/guid',
  bookingAccepted: 'The booking in the name of fullAccName, at the business(businessName) has been accepted by the customer and is ready to be reviewed!',
  bookingUpdated: 'The booking in the name of fullAccName, at the business(businessName) has been updated!',
  accountDeleted: 'This accType account is being deleted! - fullAccName; In case of problems or errors, contact support.',
  businessDeleted: 'fullAccName, your business(businessName) is being deleted!; In case of problems or errors, contact support.',
  bookingDeleted: 'The booking in the name of fullAccName, at the business(businessName) has been cancelled!'
}

export const errorMessagesEN = {
  contact_support: 'An error occurred, please contact support.',
  login_contact_support: 'An error occurred in data validation, please contact support.',
  generic_error: 'An error occurred.',
  account_not_active: 'Your account is not yet active.',
  account_doesnt_exist: 'Your account does not exist.',
  verify_robot: 'Please verify that you are not a robot.',
  booking_doesnt_exist: 'The booking does not exist.',
  change_password: 'Error sending the email to change the password.',
  booking_creation_failed: 'Inability to create the reservation, please contact support.',
  service_creation_failed: 'Inability to create the service, please contact support.',
  service_images_creation_failed: 'Inability to create the service images, please contact support.',
  service_images_delete_failed: 'Inability to delete the service images, please contact support.',
  login_invalid_email: 'Invalid email address.',
  login_user_disabled: 'This account has been disabled.',
  login_user_not_found: 'There is no account for this email.',
  login_wrong_pword: 'Incorrect password.',
  login_wrong_cred: 'Incorrect Email or Password.',
  login_too_many: 'Access to this account has been temporarily disabled due to many failed login attempts.',
  failed_to_create_booking_confirm_email: 'An error occurred, in the creation of the booking confirmation email.',
  failed_to_create_booking_warning_email: 'An error occurred, in the creation of the booking warning email.',
  failed_to_create_booking_delete_email: 'An error occurred, in the creation of the booking deletion email.',
  failed_to_create_feedback_token: 'An error occurred, in the creation of the feedback token.',
  failed_to_create_feedback_email: 'An error occurred, in the creation of the booking feedback email.',
  failed_to_update_booking_status: 'Failed to update booking status.',
  failed_to_delete_feedback_token: 'An error occurred, in the deletion of the feedback token.',
  failed_to_delete_booking_emails: 'An error occurred, in the deletion of this booking emails.',
  failed_to_delete_booking: 'Failed to delete booking.',
  failed_to_delete_rating: 'Failed to delete rating.',
  failed_to_create_rating: 'Failed to create rating.',
  register: 'Failed to create account.',
  register_same_email: 'If you want to use the same email from other acountType account, you need to use the same password.',
  register_email_creation: 'An error occurred, in the account creation email setup.',
  register_license_creation: 'An error occurred, in the account license creation.',
  register_account_creation: 'An error occurred, in the account creation.',
  worktimes_error: 'The closing time cannot be less than the opening time.',
  business_error: 'There is already a company with this name created.',
}

export const otherErrorMessagesEN = {

}

export const successMessagesEN = {
  business_deleted: 'Business deleted.',
  updated: 'Updated successfully!',
  booking_deleted: 'Booking deleted.',
  status_updated: 'Status updated.',
  service_deleted: 'Service deleted.',
  menu_deleted: 'Dish deleted.',
  social_deleted: 'Social network deleted.',
  pMethod_deleted: 'Payment method deleted.',
  group_deleted: 'Service group deleted.',
  menu_group_deleted: 'Menu deleted.',
  work_time_deleted: 'Work time deleted.',
  booking_confirmed_by_business: 'Booking confirmed by the business.',
  password_reset_email_sent: 'An email will be sent so you can change your password.',
  password_reset_finished: 'Your password has been successfully reset.',
  created: 'Created successfully!'
}

export function supportAccountCreationEmailPT(accountType, firstName, lastName, email) {
  const type = accountType === 'client' ? 'Utilizador' : 'Empresa'
  return {
    title: 'Reservado - Reservation Created',
    body: `This ${type} account has been created - ${firstName} ${lastName} (${email}).`
  }
}

export function supportBusinessCreationEmailPT(ownerFirstName, ownerLastName, businessEmail, businessName) {
  return {
    title: `Reservado - Business Created ${getCurrentDateTime()}`,
    body: `This business has been created - ${ownerFirstName} ${ownerLastName} (${businessEmail}), the business (${businessName}).`
  }
}

export function accountUpdateStatusEmailPT(status, firstName, lastName, email) {
  return {
    title: `Reservado - Account ${(status ? "Active" : "Inactive")}`,
    body: `${firstName} ${lastName} (${email}), your account is ${(status ? "active" : "inactive")}! ${(status ? "You can start using Reservado." : "In case of any issues or errors, please contact support.")}`
  }
}

export function accountDeleteEmailPT(accountType, firstName, lastName, email) {
  const type = accountType === 'client' ? 'Utilizador' : 'Empresa'
  return {
    title: `Reservado - Account Deleted ${getCurrentDateTime()}`,
    body: `This ${type} account has been deleted - ${firstName} ${lastName} (${email}).`
  }
}

export function businessUpdateStatusEmailPT(status, ownerFirstName, ownerLastName, businessEmail, businessName) {
  return {
    title: `Reservado - Company ${(status ? "Active" : "Inactive")}`,
    body: `${ownerFirstName} ${ownerLastName} (${businessEmail}), your company (${businessName}) is ${(status ? "active" : "inactive")}! ${(status ? "You can start managing it on Reservado." : "In case of problems or errors, please contact support.")}`
  }
}

export function businessDeleteEmailPT(ownerFirstName, ownerLastName, businessEmail, businessName) {
  return {
    title: `Reservado - Company Deleted`,
    body: `${ownerFirstName} ${ownerLastName} (${businessEmail}), your company (${businessName}) is being deleted!; In case of problems or errors, please contact support.`
  }
}

export function bookingCreationEmailPT(clientFistName, clientLastName, clientEmail, businessEmail, businessName) {
  return {
    title: `Reservado - Booking Created`,
    body: `The booking on behalf of ${clientFistName} ${clientLastName} (${clientEmail}), at the company (${businessName} (${businessEmail})), has been created and is awaiting confirmation!`
  }
}

export function bookingUpdateStatusEmailPT(status, clientFirstname, clientLastName, clientEmail, businessEmail, businessName) {
  return {
    title: `Reservado - Booking ${(status ? "Confirmed" : "Cancelled")}`,
    body: `The booking on behalf of ${clientFirstname} ${clientLastName} (${clientEmail}), at the company (${businessName} (${businessEmail})), has been ${(status ? "confirmed" : "cancelled")}!`
  }
}

export function bookingUpdateEmailPT(clientFirstname, clientLastName, clientEmail, businessEmail, businessName, isService = false) {
  return {
    title: `Reservado - Booking Updated`,
    body: `The booking on behalf of ${clientFirstname} ${clientLastName} (${clientEmail}), at the company (${businessName} (${businessEmail})), has been updated!
    ${(isService ? "One of the services is no longer available." : "")}`
  }
}

export function bookingDeleteEmailPT(clientFirstname, clientLastname, clientEmail, businessEmail, businessName, isService = false) {
  return {
    title: `Reservado - Booking Canceled`,
    body: `The booking on behalf of ${clientFirstname} ${clientLastname} (${clientEmail}), at the company (${businessName} (${businessEmail})), has been canceled!
    ${(isService ? "This service is no longer available." : "")}`
  }
}

export function bookingUserAccpetEmailPT(clientFirstname, clientLastname, clientEmail, businessEmail, businessName) {
  return {
    title: `Reservado - Booking Accepted by the Client`,
    body: `The booking on behalf of ${clientFirstname} ${clientLastname} (${clientEmail}), at the company (${businessName} (${businessEmail})), has been accepted by the client and is ready for review!`
  }
}

export function bookingRememberEmailPT(bookingDate, bookingTime, businessEmail, businessName) {
  return {
    title: `Reservado - Booking Reminder - ${bookingDate} ${bookingTime}`,
    body: `Don't forget your booking for ${bookingDate} ${bookingTime} at the company (${businessName} (${businessEmail})).`
  }
}

export const contentEN = {
  menu: {
    terms: 'Terms and Conditions',
    businesses: 'Businesses',
    guide: 'Guide',
    agenda: 'Agenda',
    bookings: 'Bookings',
    login: 'Log In/Create Account',
    lang: 'Language',
    drop: {
      login: 'Log In',
      create: 'Create Account',
      my_account: 'My Account',
      logout: 'Log Out'
    }
  },
  home: {
    title: 'Welcome to Reservado, where you can manage your bookings your way!'
  },
  select_account: {
    title: 'Account Type',
    client_title: 'Client',
    client_text: 'Account type that allows you to create bookings at available businesses in the application.',
    bussiness_title: 'Business',
    business_text: 'Account type that enables you to manage bookings made by clients at your businesses.',
    button: 'Choose'
  },
  login: {
    title: 'Sign In',
    email_label: 'Email',
    email_placeholder: 'example@example.com',
    password_label: 'Password',
    password_placeholder: '*****',
    password_button_message_show: 'Show Password',
    password_button_message_hide: 'Hide Password',
    button: 'Sign In'
  },
  reset_password: {
    title: 'Reset Password',
    email_label: 'Email',
    email_placeholder: 'example@example.com',
    password_label: 'Password',
    password_placeholder: '*****',
    button: 'Reset'
  },
  register: {
    title: 'Create Account',
    first_name_label: 'First Name',
    first_name_placeholder: 'First Name',
    last_name_label: 'Last Name',
    last_name_placeholder: 'Last Name',
    birth_day_label: 'Date of Birth',
    email_label: 'Email',
    email_placeholder: 'example@example.com',
    password_label: 'Password',
    password_placeholder: '*****',
    password_button_message_show: 'Show Password',
    password_button_message_hide: 'Hide Password',
    address_label: 'Address',
    address_placeholder: 'Address',
    city_label: 'City',
    city_placeholder: 'City',
    postal_label: 'Postal Code',
    postal_placeholder: '****-***',
    contact_label: 'Contact',
    contact_placeholder: '9********',
    button: 'Create'
  },
  register_success: {
    title: 'Create Account',
    subtitle: 'Your account has been successfully created and is being verified.',
    message: 'Once you receive an email confirming that your account is active, you will be able to log in!'
  },
  businesses: {
    title: 'Businesses',
    table_headers_text: ['Name', 'Email', 'Contact', 'Address', 'City', 'Postal Code', 'Categories', 'Website', 'Status', 'Actions'],
    table_headers_values: ['name', 'email', 'contact', 'address', 'city', 'postalCode', 'categories', 'website', 'isActive'],
    table_short_headers_text: ['Name', 'Contact', 'City', 'Postal Code', 'Status', 'Actions'],
    table_short_headers_values: ['name', 'contact', 'city', 'postalCode', 'isActive'],
    table_headers_preview_text: ['Name', 'Email', 'Contact', 'Address', 'City', 'Postal Code', 'Categories', 'Website', 'Booking Link', 'Actions'],
    table_headers_preview_values: ['name', 'email', 'contact', 'address', 'city', 'postalCode', 'categories', 'website', 'link'],
    table_short_headers_preview_text: ['Name', 'Contact', 'City', 'Postal Code', 'Booking Link', 'Actions'],
    table_short_headers_preview_values: ['name', 'contact', 'city', 'postalCode', 'link'],
    link_warning_services: 'Add active hours and services first',
    link_warning_menus: 'Add active hours and dishes first',
    link_text: 'Open link',
    delete_action: 'Delete business:',
    create_first_business_title: 'Create Your First Business!',
    create_first_business_info: 'Once it\'s active, you will receive an email to set it up.',
    button: 'Add Business'
  },
  business_preview: {
    title: 'Basic Information',
    work_times_title: 'Work Schedule',
    socials_title: 'Social Networks',
    pMethods_title: 'Payment Methods',
    folders_title: 'Service Groups',
    menus_title: 'Menu Groups',
    sidebar: {
      agenda: 'Agenda',
      bookings: 'Bookings',
      services: 'Services',
      menus: 'Dish',
      prefs: 'Preferences'
    }
  },
  work_times: {
    table_headers_text: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Actions'],
    table_headers_values: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
    closed: 'Closed',
    delete_action: 'Delete work schedule?',
    button: 'Add Work Schedule'
  },
  socials: {
    table_headers_text: ['Name', 'Link', 'Actions'],
    table_headers_values: ['name', 'url'],
    delete_action: 'Delete Social Network -',
    button: 'Add Social Network'
  },
  pMethods: {
    table_headers_text: ['Name', 'Actions'],
    table_headers_values: ['name'],
    delete_action: 'Delete Payment Method -',
    button: 'Add Payment Method'
  },
  folders: {
    table_headers_text: ['Name', 'Actions'],
    table_headers_values: ['name'],
    delete_action: 'Delete Service Group -',
    button_services: 'Add Service Group',
    button_menus: 'Add Menu Group'
  },
  bookings: {
    business_table_headers_text: ['Date', 'Time', 'Name', 'Contact', 'Services', 'Duration', 'Type', 'Accepted by Client', 'Accepted by Business', 'Actions'],
    business_table_headers_values: ['date', 'time', 'fullName', 'contact', 'servicesNames', 'duration', 'isManual', 'isUserActive', 'isActive'],
    business_table_headers_menu_text: ['Date', 'Time', 'Name', 'Contact', 'People Amount', 'Type', 'Accepted by Client', 'Accepted by Business', 'Actions'],
    business_table_headers_menu_values: ['date', 'time', 'fullName', 'contact', 'amount', 'isManual', 'isUserActive', 'isActive'],
    business_short_table_headers_text: ['Date', 'Time', 'Name', 'Type', 'Accepted by Client', 'Accepted by Business', 'Actions'],
    business_short_table_headers_values: ['date', 'time', 'fullName', 'isManual', 'isUserActive', 'isActive'],
    client_table_headers_text: ['Date', 'Time', 'Company', 'Contact', 'Services', 'Duration', 'Accepted by You', 'Accepted by Company', 'Actions'],
    client_table_headers_values: ['date', 'time', 'business.name', 'business.contact', 'servicesNames', 'duration', 'isUserActive', 'isActive'],
    client_table_headers_menu_text: ['Date', 'Time', 'Company', 'Contact', 'People Amount', 'Accepted by You', 'Accepted by Company', 'Actions'],
    client_table_headers_menu_values: ['date', 'time', 'business.name', 'business.contact', 'amount', 'isUserActive', 'isActive'],
    client_short_table_headers_text: ['Data', 'Hora', 'Empresa', 'Aceite por ti', 'Aceite pela Empresa', 'Ações'],
    client_short_table_headers_values: ['date', 'time', 'business.name', 'isUserActive', 'isActive'],
    type_online: 'Online',
    tyep_internal: 'Internal',
    current_button: 'Current Bookings',
    all_button: 'All Bookings',
    delete_action: 'Delete booking -',
    button: 'Add Booking'
  },
  agenda: {
    button: 'Today',
    closed: 'Closed'
  },
  services: {
    table_headers_text: ['Icon', 'Name', 'Price', 'Duration', 'Status', 'Actions'],
    table_headers_values: ['icon', 'name', 'price', 'time', 'isActive'],
    switch_on: 'Activate',
    switch_off: 'Deactivate',
    delete_action: 'Delete service -',
    button: 'Add Service'
  },
  menus: {
    table_headers_text: ['Icone', 'Nome', 'Preço', 'Descrição', 'Estado', 'Ações'],
    table_headers_values: ['icon', 'name', 'price', 'description', 'isActive'],
    switch_on: 'Activate',
    switch_off: 'Deactivate',
    delete_action: 'Delete dish -',
    button: 'Add dish'
  },
  clientBusinessServicePreview: {
    free_text: 'Free',
    allServices_folder: 'All Services',
    allMenus_folder: 'All Dishes'
  },
  feedback: {
    title: 'Feedback Form',
    rating_label: 'How would you rate your experience?',
    description_label: 'Write a brief description of your thoughts about the experience',
    button: 'Submit'
  },
  carouselBusiness: {
    headers: [
      'Easy',
      'No Hassle',
      'Double Check',
      'No Discomfort',
      'Information is Power',
      `Let's Go!`
    ],
    descriptions: [
      'With our application, small business owners/companies can finally have easy control over their bookings.',
      'They can register on the platform and list their services, and then share a direct link with their customers so they can book their services with no hassle!',
      'We have a two-sided booking system, which means both the company and the customer need to confirm the chosen date and time before the booking is confirmed.',
      'This means bookings are only made when both parties agree - no unpleasant surprises.',
      'Additionally, our application sends email updates to keep everyone involved informed about upcoming bookings.',
      `So, if you're looking for an easy and efficient way to manage your bookings and keep your customers happy, give our application a try!`
    ]
  },
  carouselClient: {
    headers: [
      'Easy Booking',
      'Discover New Places',
      'Instant Reservations',
      'Explore Diversity',
      'Plan Your Day',
      'Valued Feedback'
    ],
    descriptions: [
      'Make your reservations easily and quickly, without complications. With just a few clicks, secure your spot at your favorite venues.',
      'Explore a variety of establishments and discover new places to eat, relax, or enjoy activities. Find the perfect ambiance for every occasion.',
      'Don\'t waste time in lines or waiting. Make your reservations instantly and ensure your presence at the best places without delays.',
      'Enjoy the diversity of options our platform offers. From cozy restaurants to relaxing spas, there is something for every taste and need.',
      'Organize your day in advance by booking your appointments and activities with ease. Plan meals, treatments, and leisure moments conveniently.',
      'Share your opinion and help businesses improve their services. Your feedback is valuable and contributes to an even better experience for all users.'
    ]
  },
  typeChoice: {
    client: 'Are you a client?',
    business: 'Are you a business?',
    yesClient: 'Yes, I am a Client!',
    yesBusiness: 'Yes, I am a Business!'
  },
  lastBusiness: {
    title: 'Last Added Businesses'
  },
  mission: {
    title: 'Our mission to help local businesses conquer their space in the digital world',
    text: 'In the digital age, we aim to be the trusted partner that helps local businesses establish an online presence. We empower companies with tools to enhance reservation management and increase their visibility. Our mission is to be the bridge that leads these businesses to success.',
    join_us_text: 'Join us and be a part of the growth!',
    join_us_button: 'Create your account now!'
  },
  brand: {
    meaning_title: 'Discover the meaning behind our brand name',
    meaning_text_1: 'is a name that embodies the commitment to provide a special place for every local business, enabling a remarkable online presence and more efficient reservation management. It is the platform that connects entrepreneurs to their customers, expanding horizons and bridging distances.',
    meaning_text_2: 'is the gateway to a new era of local businesses, where each has its own space, identity, and the opportunity to grow and thrive in the digital age.',
    logo_title: 'The meaning behind our logo',
    logo_text: 'A visual representation of our mission and values. With a seaside holiday home as an icon, it evokes feelings of tranquility, vacation, and relaxation, which is what we want for our users.'
  },
  features: {
    title: 'Enjoy Our Unique Advantages',
    names: [
      'Simplify customer reservation management',
      'Provide an easy-to-use booking platform',
      'Enable customers to make real-time reservations',
      'Deliver automatic notifications to customers and businesses regarding reservations',
      'Allow businesses to customize their pages and profiles according to their needs'
    ]
  },
  packages: {
    title: "Explore our packages",
    lists: [
      {
        name: 'Basic',
        text: [
          "Ideal for businesses taking their first steps in online booking management.",
          "Allows you to set up your company and showcase your online presence with a personalized page.",
          "Manage working hours and available services.",
          "Access to the agenda to keep track of bookings.",
          "No online bookings or email alerts."
        ]
      },
      {
        name: 'Premium',
        text: [
          "For businesses seeking a comprehensive solution for booking management.",
          "Includes all features of the Basic Package.",
          "Ability to receive online bookings through the company's personalized page.",
          "Email alerts to keep customers informed about their bookings.",
          "Customer feedback to enhance the experience."
        ]
      },
      {
        name: 'Restaurants',
        text: [
          "Specially designed for restaurants and catering companies.",
          "Offers all the features of the Premium Package.",
          "Advanced customization for dishes and menus.",
          "Specific booking options for the restaurant sector.",
          "Effective booking management to optimize the customer experience."
        ]
      }
    ]
  },
  target_clients: {
    title1: 'Discover the perfect solution for your business',
    title2: 'and register for free!',
    names: [
      'Barber Shop',
      'Lawyer',
      'Car Workshop',
      'Nutritionist',
      'Psychologist',
      'Electrician',
      'Carpenter',
      'Plumber',
      'Furniture Assembly',
      'Musician',
      'Personal Trainer',
      'Photographer'
    ],
    more_text: 'And more...'
  },
  last_updates: {
    titles: [
      'Major theme update 2.0',
      'Companies can now choose the "Restoration" category',
      'Services have price ranges',
      'Services Separated by Groups',
      'Information on Accepted Payment Methods',
      'Services Available on the Cloud',
      'Integration with Social Networks',
      'Major Theme Update',
      'Category Limitation Removed',
      'View Reservation Details Action',
      'Detailed Service View',
      'Enhancements in Booking Creation',
      'Improvements in Create and Update Services Page',
      'New Account Type Selection Page',
      'New Logo'
    ],
    descriptions: [
      'Services can now have their prices set in ranges, offering greater flexibility in value configuration.',
      'Now, with the new feature of service groups, it\'s even easier for customers to find exactly what they are looking for.',
      'Businesses now have the option to clearly inform customers about the accepted payment methods, making transactions smoother.',
      'The infrastructure of our application has been fully migrated to the cloud, ensuring a significant increase in the reliability and performance of the service!',
      'Businesses can now add links to their social networks so that customers can follow their work there.',
      'The color scheme now uses the primary colors found in our logo.',
      'Businesses can have one or more categories from now on.',
      'In the reservations table, we added the "View" option for reservation details, making it easy for you to get important information.',
      'Now, when clicking on the service name on the company page, you can see all the service details in a complete view.',
      'We added a text box for additional reservation information, allowing customers to provide extra details needed for the company where they are making the reservation.',
      'Now, information is presented clearly and organized, and we added a text box for product description, allowing you to detail your service even better.',
      'Now, when creating an account or logging in, you can easily choose the account type that best suits your needs. With our new page, we make the process even more intuitive and personalized.',
      'We updated our image to reflect the simplicity and innovation of our reservation platform.'
    ],
    show_more_button: 'Show Latest Updates',
    show_less_button: 'Hide Latest Updates'
  },
  modals: {
    business: {
      title_new: 'New Business',
      title_update: 'Update Business',
      name_label: 'Name',
      name_placeholder: 'Name',
      categories_label: 'Categories',
      categories_drop_default: 'Categories',
      email_label: 'Email',
      email_placeholder: 'example@example.com',
      address_label: 'Address',
      address_placeholder: 'Address',
      city_label: 'City',
      city_placeholder: 'City',
      postal_label: 'Postal Code',
      postal_placeholder: '****-***',
      contact_label: 'Contact',
      contact_placeholder: '9********',
      website_label: 'Website',
      website_placeholder: 'https://example.com'
    },
    work_times: {
      title_new: 'New Work Schedule',
      title_update: 'Update Work Schedule',
      week_day_names: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      open_label: 'Open',
      close_label: 'Close'
    },
    services: {
      title_new: 'New Service',
      title_update: 'Update Service',
      baisc_info_subtitle: 'Basic Information',
      name_label: 'Name',
      name_placeholder: 'Name',
      price_label: 'Price',
      priceInput: {
        exact: 'Value',
        fromto: 'From',
        upto: 'Up to',
        from: 'From'
      },
      duration_label: 'Duration',
      folder_label: 'Group',
      folder_empty_text: 'No group',
      description_label: 'Description',
      description_placeholder: 'Write a brief description of your service...',
      images_subtitle: 'Images',
      icon_subtitle: 'Customize Icon',
      icon_label: 'Icon',
      color_label: 'Color',
      icon_preview_text: 'Icon Preview'
    },
    menus: {
      title_new: 'New Dish',
      title_update: 'Update Dish',
      baisc_info_subtitle: 'Basic Information',
      name_label: 'Name',
      name_placeholder: 'Name',
      price_label: 'Price',
      priceInput: {
        exact: 'Value',
        fromto: 'From',
        upto: 'Up to',
        from: 'From'
      },
      duration_label: 'Duration',
      folder_label: 'Group',
      folder_empty_text: 'No group',
      description_label: 'Description',
      description_placeholder: 'Write a brief description of your dish...',
      images_subtitle: 'Images',
      icon_subtitle: 'Customize Icon',
      icon_label: 'Icon',
      color_label: 'Color',
      icon_preview_text: 'Icon Preview'
    },
    socials: {
      title_new: 'New Social Network',
      title_update: 'Update Social Network',
      social_label: 'Social Network',
      social_drop_default: 'Social Network',
      link_label: 'Link',
      link_placeholder: 'https://example.com'
    },
    pMethods: {
      title_new: 'New Payment Method',
      pMethod_label: 'Payment Method',
      pMethod_drop_default: 'Payment Method'
    },
    folders: {
      title_new_services: 'New Services Group',
      title_update_services: 'Update Services Group',
      title_new_menus: 'New Menu Group',
      title_update_menus: 'Update Menu Group',
      name_label: 'Name'
    },
    service_preview: {
      title: 'Service Information',
      price_label: 'Price',
      duration_label: 'Duration',
      description_label: 'Description',
      without_description_text: 'No description.',
      carousel_title: 'Images',
      button: 'Close'
    },
    business_preview: {
      title: 'Business Information',
      without_website_text: 'No website configured.',
      button: 'Close'
    },
    booking: {
      title_new: 'New Booking',
      title_update: 'Update Booking',
      date_label: 'Date',
      hour_label: 'Time',
      hour_drop_text: 'Time',
      amount_label: 'People Amount',
      services_label: 'Services',
      services_drop_text: 'Services',
      duration_label: 'Duration',
      add_info_label: 'Additional Information',
      add_info_placeholder: 'Write additional information about the booking here...',
      booking_details_subtitle: 'Booking Details',
      client_details_subtitle: 'Client Details',
      first_name_label: 'First Name',
      first_name_placeholder: 'First Name',
      last_name_label: 'Last Name',
      last_name_placeholder: 'Last Name',
      email_label: 'Email',
      email_placeholder: 'example@example.com',
      contact_label: 'Contact',
      contact_placeholder: '9********',
      not_available: 'Not available on this day',
      cancel: 'Cancel',
      save: 'Save'
    },
    booking_preview: {
      title: 'Booking Information',
      business_label: 'Business',
      client_label: 'Client',
      date_label: 'Date',
      hour_label: 'Time',
      contact_label: 'Contact',
      service_label: 'Services',
      duration_label: 'Duration',
      amount_label: 'People Amount',
      add_info_label: 'Additional Information',
      without_add_info_text: 'No additional information.',
      book_again: 'Book Again!'
    },
    confirm: {
      yes: 'Yes',
      no: 'No'
    },
    close: 'Close',
    cancel: 'Cancel',
    save: 'Save',
    remove: 'Remove'
  },
  client_business_preview: {
    client_business: {
      button: 'Book Now!',
      socials_title: 'Social Networks',
      pMethods_tile: 'Payment Methods'
    },
    work_times: {
      title: 'Work Schedule',
      week_day_names: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      closed: 'Closed'
    },
    services: {
      title: 'Services'
    },
    menus: {
      title: 'Dishes'
    }
  },
  new_business: {
    title_new: 'New Business',
    name_label: 'Name',
    name_placeholder: 'Name',
    categories_label: 'Categories',
    categories_drop_default: 'Categories',
    email_label: 'Email',
    email_placeholder: 'example@example.com',
    address_label: 'Address',
    address_placeholder: 'Address',
    city_label: 'City',
    city_placeholder: 'City',
    postal_label: 'Postal Code',
    postal_placeholder: '****-***',
    contact_label: 'Contact',
    contact_placeholder: '9********',
    website_label: 'Website',
    website_placeholder: 'https://example.com',
    cancel: 'Cancel',
    save: 'Save'
  },
  new_booking: {
    title: 'New Booking',
    date_label: 'Date',
    hour_label: 'Time',
    hour_drop_text: 'Time',
    amount_label: 'People Amount',
    services_label: 'Services',
    services_drop_text: 'Services',
    duration_label: 'Duration',
    add_info_label: 'Additional Information',
    add_info_placeholder: 'Write additional information about the booking here...',
    not_available: 'Not available on this day',
    button: 'Book',
    services: {
      title: 'Services'
    },
    menus: {
      title: 'Dishes'
    }
  },
  my_account: {
    title: 'My Account',
    warning_message: 'Any changes to the profile will require a session restart',
    sidebar: {
      change_password: 'Reset Password',
      basic_info: 'Basic Information',
      delete_account: 'Delete Account'
    },
    change_password: {
      title: 'Reset Password',
      button: 'Reset'
    },
    basic_info: {
      title: 'Basic Information',
      first_name_label: 'First Name',
      first_name_placeholder: 'First Name',
      last_name_label: 'Last Name',
      last_name_placeholder: 'Last Name',
      day_of_birth_label: 'Date of Birth',
      address_label: 'Address',
      address_placeholder: 'Address',
      city_label: 'City',
      city_placeholder: 'City',
      postal_label: 'Postal Code',
      postal_placeholder: '****-***',
      contact_label: 'Contact',
      contact_placeholder: '9********',
      edit_button: 'Edit',
      cancel_button: 'Cancel',
      save_button: 'Save'
    },
    delete_account: {
      title: 'Delete Account',
      button: 'Delete'
    }
  },
  footer: {
    title: 'Get in touch with us here',
    support_button: 'Our Support'
  },
  multi_drop: {
    results_text: 'No results...'
  },
  image_input_gird: {
    message: 'Drag & Drop or Click to Upload'
  },
  start_component: {
    title: 'Rating'
  },
  priceOptions: [
    { key: 'exact', text: 'Exact price', value: 'exact' },
    { key: 'upto', text: 'Price up to', value: 'upto' },
    { key: 'from', text: 'Price from', value: 'from' },
    { key: 'range', text: 'Price between values', value: 'range' },
  ],
  nothing_to_show: 'Nothing to show...',
  view: 'View',
  edit: 'Edit',
  delete: 'Delete',
  accept: 'Accept',
  hour: 'hour',
  hours: 'hours',
  minute: 'minutes',
  minutes: 'minutes'
}

import React from 'react'
import { Container } from 'semantic-ui-react'
import NavBar from './NavBar'

const AppHeader = () => {
    return (
        <div>
            <Container fluid id='container-no-margin'>
                <NavBar />
            </Container>
        </div>
    )
}

export default AppHeader
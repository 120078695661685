import React, { useState } from 'react';
import { Button, Container, Form, Header, Segment } from 'semantic-ui-react';
import { handleReload, notifyError, decrytptString, readValueInLocalStorage, getRegisterMinAge, readLocalStorageUserValue, translateErrorMessages, translateContent, formatCities, validateAccountUpdateObject, readLocalStorageTokenValue, saveValueInLocalStorage } from '../../../resources/lib';
import { getDocIdBy, updateDataByDocId } from '../../../resources/firebaseLib';
import AppLoader from '../../Global/AppLoader';
import Breadcrumbs from '../../Global/Breadcrumbs';
import CustomDropdown from '../../Global/Dropdown/Dropdown';

const BasicInfo = (props) => {
    const [userData, setUserData] = useState({ ...props.userData })
    const [isPIUpdate, setIsPIUpdate] = useState(false)

    const content = translateContent(localStorage.getItem('Language'))
    const errorMessages = translateErrorMessages(localStorage.getItem('Language'))

    async function handleSubmit(event) {
        event.preventDefault()
        const type = decrytptString(readValueInLocalStorage('Type', 'type')) === 'client' ? 'client_user' : 'client_business'
        const docId = await getDocIdBy(type, 'uid', decrytptString(readLocalStorageUserValue('token')))
        if (docId !== null) {
            let result = await updateDataByDocId(type, docId, userData)
            if(result){
                window.location.pathname = "/"
                const dataToSave = {
                    user: {
                        firstName: userData.firstName,
                        lastName: userData.lastName,
                        token: readLocalStorageTokenValue()
                    }
                }
                saveValueInLocalStorage('UserInfo', dataToSave)
                handleReload()
            }
            else{
                notifyError(errorMessages.contact_support)
            }
        }
        else {
            setUserData(props.userData)
            notifyError(errorMessages.contact_support)
        }
    }

    function handleInputChange(event) {
        const { name, value } = event.target
        setUserData((prevFormData) => ({ ...prevFormData, [name]: value }))
    }

    function toggleEdit() {
        setIsPIUpdate(!isPIUpdate)
        setUserData(props.userData)
    }

    function handleDropdown(id) {
        setUserData((prevFormData) => ({ ...prevFormData, city: id }))
    }

    if (!userData)
        return <AppLoader />
    return (
        <Container fluid>
            <br />
            <Breadcrumbs list={[content.my_account.title, content.my_account.basic_info.title]} />
            <Container>
                <Segment basic>
                    <Header inverted className='huge-text shadow-text' as='h1'>{content.my_account.basic_info.title}</Header>
                </Segment>
                <div className='my-profile-size'>
                    <Segment basic>
                        {!isPIUpdate &&
                            <Form size='big'>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label className='big-text shadow-text brown-text'>{content.my_account.basic_info.first_name_label}</label>
                                        <input placeholder={content.my_account.basic_info.first_name_placeholder} name="firstName" value={userData.firstName} disabled />
                                    </Form.Field>
                                    <Form.Field>
                                        <label className='big-text shadow-text brown-text'>{content.my_account.basic_info.last_name_label}</label>
                                        <input placeholder={content.my_account.basic_info.last_name_placeholder} name="lastName" value={userData.lastName} disabled />
                                    </Form.Field>
                                    <Form.Field>
                                        <label className='big-text shadow-text brown-text'>{content.my_account.basic_info.day_of_birth_label}</label>
                                        <input type='date' name="dayOfBirth" value={userData.dayOfBirth} disabled />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Field>
                                    <label className='big-text shadow-text brown-text'>{content.my_account.basic_info.address_label}</label>
                                    <input placeholder={content.my_account.basic_info.address_placeholder} name="address" value={userData.address} disabled />
                                </Form.Field>
                                <Form.Group widths='equal'>
                                    <Form.Field>
                                        <label className='big-text shadow-text brown-text'>{content.my_account.basic_info.city_label}</label>
                                        <input placeholder={content.my_account.basic_info.city_placeholder} name="city" value={userData.city} disabled />
                                    </Form.Field>
                                    <Form.Field>
                                        <label className='big-text shadow-text brown-text'>{content.my_account.basic_info.postal_label}</label>
                                        <input placeholder={content.my_account.basic_info.postal_placeholder} name="postalCode" value={userData.postalCode} disabled />
                                    </Form.Field>
                                    <Form.Field>
                                        <label className='big-text shadow-text brown-text'>{content.my_account.basic_info.contact_label}</label>
                                        <input placeholder={content.my_account.basic_info.contact_placeholder} name="contact" value={userData.contact} disabled />
                                    </Form.Field>
                                </Form.Group>
                                <Button circular size='large' className='secondary-color-btn' onClick={e => toggleEdit(e, '')}>{content.my_account.basic_info.edit_button}</Button>
                            </Form>
                        }
                        {isPIUpdate &&
                            <Form size='big'>
                                <Form.Group widths='equal'>
                                    <Form.Field required>
                                        <label className='big-text shadow-text brown-text'>{content.my_account.basic_info.first_name_label}</label>
                                        <input placeholder={content.my_account.basic_info.first_name_placeholder} maxLength="30" name="firstName" value={userData.firstName} onChange={handleInputChange} required />
                                    </Form.Field>
                                    <Form.Field required>
                                        <label className='big-text shadow-text brown-text'>{content.my_account.basic_info.last_name_label}</label>
                                        <input placeholder={content.my_account.basic_info.last_name_placeholder} maxLength="30" name="lastName" value={userData.lastName} onChange={handleInputChange} required />
                                    </Form.Field>
                                    <Form.Field required>
                                        <label className='big-text shadow-text brown-text'>{content.my_account.basic_info.day_of_birth_label}</label>
                                        <input type='date' name="dayOfBirth" max={getRegisterMinAge()} value={userData.dayOfBirth} onChange={handleInputChange} required />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Field required>
                                    <label className='big-text shadow-text brown-text'>{content.my_account.basic_info.address_label}</label>
                                    <input placeholder={content.my_account.basic_info.address_label} maxLength="80" name="address" value={userData.address} onChange={handleInputChange} required/>
                                </Form.Field>
                                <Form.Group widths='equal'>
                                    <Form.Field required>
                                        <label className='big-text shadow-text brown-text'>{content.my_account.basic_info.city_label}</label>
                                        <CustomDropdown data={formatCities()} text={content.my_account.basic_info.city_label} handleDropdown={handleDropdown} default={userData.city} />
                                    </Form.Field>
                                    <Form.Field required>
                                        <label className='big-text shadow-text brown-text'>{content.my_account.basic_info.postal_label}</label>
                                        <input title='ex: 8700-000' placeholder={content.my_account.basic_info.postal_placeholder} name="postalCode" pattern='[0-9]{4}-[0-9]{3}' value={userData.postalCode} onChange={handleInputChange} required/>
                                    </Form.Field>
                                    <Form.Field required>
                                        <label className='big-text shadow-text brown-text'>{content.my_account.basic_info.contact_label}</label>
                                        <input title='ex: 961234567 or 221123456' placeholder={content.my_account.basic_info.contact_placeholder} name="contact" maxLength='9' pattern='[0-9]{9}' value={userData.contact} onChange={handleInputChange} required />
                                    </Form.Field>
                                </Form.Group>
                                <Button circular size='large' className='third-color-active-btn' onClick={toggleEdit} >{content.my_account.basic_info.cancel_button}</Button>
                                {!validateAccountUpdateObject(userData) &&
                                    <Button circular size='large' className='primary-color-active-btn' onClick={handleSubmit} disabled>{content.my_account.basic_info.save_button}</Button>
                                }
                                {validateAccountUpdateObject(userData) &&
                                    <Button circular size='large' className='primary-color-active-btn' onClick={handleSubmit}>{content.my_account.basic_info.save_button}</Button>
                                }
                            </Form>
                        }
                    </Segment>
                </div>
            </Container>
        </Container>
    )
}

export default BasicInfo
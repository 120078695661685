import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Grid, Header, Segment } from 'semantic-ui-react'
import { translateContent } from '../../../resources/lib'

const JoinUs = () => {
    const content = translateContent(localStorage.getItem('Language'))

    return (
        <Container fluid className='space-segment divider-container' id='container-no-margin'>
            <Container>
                <Grid stackable columns='equal'>
                    <Grid.Row>
                    <Grid.Column>
                            <Segment circular size='massive' as={Link} to='/register/business' className='third-color-btn bigger-text'>{content.mission.join_us_button}</Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Header as='h2' className='primary-text-color huge-text shadow-text'>{content.mission.join_us_text}</Header>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </Container>
    )

}

export default JoinUs
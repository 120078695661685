import React from 'react'
import { Container, Header, Segment } from 'semantic-ui-react'
import Footer from '../Footer'

const TermsNConditions = () => {
    return (
        <Container fluid>
            <Segment basic className='space-segment'>
                <Header inverted className='huge-text shadow-text' as='h1'>Termos e Condições de Uso</Header>
            </Segment>
            <Container fluid>
                <Container className='forms-small-box brown-text' textAlign='justified'>
                    <Header>1. Introdução</Header>

                    <p>Bem-vindo ao <b>Reservado</b>. O <b>Reservado</b> é fornecido pela <b>Reservado</b> ("nós", "nosso" ou "empresa"). Ao utilizar o <b>Reservado</b>, concorda com os seguintes <b>Termos e Condições de Uso</b> ("Termos"). Por favor, leia-os cuidadosamente.</p>

                    <Header>2. Privacidade</Header>
                    <ul className='no-bullets'>
                        <li>
                            <Header as='h4'>Confidencialidade das Informações</Header>
                            <p>Estamos comprometidos em proteger a privacidade dos dados dos nossos usuários.</p>
                            <p>A seguir, explicamos como lidamos com as informações recolhidas:</p>

                            <ol type="a">
                                <li>
                                    <p><b>Contas de clientes</b></p>
                                    <p>Se você possui uma conta de cliente, suas informações pessoais só serão acessíveis por você e pelas empresas onde realiza reservas. Essas empresas terão acesso às suas informações apenas para fins de gerenciamento de reservas e não compartilharão suas informações com terceiros não autorizados.</p>
                                </li>
                                <li>
                                    <p><b>Contas de empresas</b></p>
                                    <p>Se você possui uma conta de empresa, suas informações pessoais só serão acessíveis por você e pelos seus clientes. Seus clientes terão acesso às suas informações apenas para fins de realização e gerenciamento de reservas e não compartilharão suas informações com terceiros não autorizados.</p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <Header as='h4'>Segurança</Header>
                            <p>A segurança das suas informações pessoais é importante para nós. Implementamos medidas técnicas e organizacionais adequadas para proteger suas informações pessoais contra acesso não autorizado, uso indevido, perda, destruição ou alteração. No entanto, nenhum método de transmissão pela Internet ou método de armazenamento eletrônico é 100% seguro. Embora nos esforcemos para usar meios comercialmente aceitáveis para proteger suas informações pessoais, não podemos garantir sua segurança absoluta.</p>
                        </li>
                    </ul>

                    <Header>3. Recolha e Uso de Dados</Header>

                    <p>Ao se registrar no <b>Reservado</b>, você concorda em fornecer informações precisas e completas, incluindo seu nome, endereço, número de telefone e endereço de e-mail. Essas informações são recolhidas para facilitar o contato entre os clientes e as empresas, para garantir a veracidade das contas e para fins de business intelligence. Ao fornecer essas informações, concorda que podemos usá-las de acordo com a nossa Política de Privacidade.</p>

                    <Header>4. Restrições de Uso</Header>

                    <Header as='h4'>Concorda em não usar o <b>Reservado</b> para:</Header>
                    <ol type="a">
                        <li>Criar contas falsas ou fornecer informações falsas;</li>
                        <li>Violar quaisquer leis, regulamentos ou regras aplicáveis;</li>
                        <li>Infringir os direitos de propriedade intelectual, privacidade ou outros direitos de terceiros;</li>
                        <li>Assediar, abusar ou prejudicar outros usuários;</li>
                        <li>Publicar, transmitir ou compartilhar conteúdo ilegal, difamatório, obsceno ou ofensivo.</li>
                    </ol>

                    <Header>5. Propriedade Intelectual</Header>

                    <p>Todo o conteúdo no <b>Reservado</b>, incluindo, mas não limitado a, textos, gráficos, logotipos, ícones, imagens, clipes de áudio, downloads digitais e software, é propriedade da <b>Reservado</b> ou de seus respectivos proprietários e é protegido por leis de direitos autorais internacionais e locais.</p>

                    <Header>6. Modificações nos Termos e Condições</Header>

                    <p>Reservamo-nos o direito de modificar os Termos a qualquer momento e a nosso exclusivo critério. As modificações entrarão em vigor imediatamente após serem publicadas no <b>Reservado</b>. É sua responsabilidade rever periodicamente os Termos para estar ciente de quaisquer alterações. Se continuar a usar o <b>Reservado</b> após a publicação de alterações nos Termos, isso constituirá a sua aceitação das alterações.</p>

                    <Header>7. Isenção de Garantias e Limitação de Responsabilidade</Header>
                    <p>O <b>Reservado</b> é fornecido "como está" e "conforme disponível". Nós não garantimos que o <b>Reservado</b> seja ininterrupto, seguro, livre de erros ou que atenda às suas expectativas. Nós nos isentamos de todas as garantias, expressas ou implícitas, incluindo, mas não limitado a, garantias implícitas de comercialização, adequação a um propósito específico e não violação.</p>

                    <p>Em nenhuma circunstância a <b>Reservado</b> será responsável por danos diretos, indiretos, acidentais, especiais, consequenciais ou punitivos resultantes do uso ou da incapacidade de usar o <b>Reservado</b>, mesmo que tenhamos sido informados da possibilidade de tais danos. A limitação de responsabilidade aplica-se na extensão máxima permitida por lei.</p>

                    <Header>8. Indemnização</Header>
                    <p>Concorda em indemnizar, defender e isentar a <b>Reservado</b>, seus diretores, funcionários, agentes e afiliados de quaisquer reivindicações, responsabilidades, danos, perdas e despesas, incluindo, sem limitação, honorários advocatícios razoáveis, decorrentes de ou relacionados com o seu uso do <b>Reservado</b>, sua violação destes Termos ou a violação de direitos de terceiros.</p>

                    <Header>9. Legislação Aplicável e Jurisdição</Header>
                    <p>Estes Termos e quaisquer disputas ou reivindicações relacionadas ao <b>Reservado</b> serão regidos e interpretados de acordo com as leis do Portugal, sem consideração aos conflitos de princípios legais. Concorda em submeter-se à jurisdição exclusiva dos tribunais localizados em Portugal para resolver quaisquer disputas decorrentes destes Termos.</p>

                    <Header>10. Rescisão</Header>
                    <p>Reservamo-nos o direito de encerrar ou suspender o seu acesso ao <b>Reservado</b> a qualquer momento e por qualquer motivo, incluindo, mas não limitado a, violações destes Termos. Após a rescisão, todas as disposições destes Termos que, por sua natureza, devam sobreviver à rescisão, continuarão em vigor.</p>

                    <Header>11. Contato</Header>
                    <p>Se tiver alguma dúvida sobre estes Termos ou o <b>Reservado</b>, entre em contato conosco pelo e-mail <b>oficial.reservado@gmail.com</b>.</p>

                    <Header>Ao utilizar esta aplicação, você concorda em cumprir e ficar vinculado aos nossos Termos e Condições de Uso. Por favor, leia-os cuidadosamente antes de prosseguir.</Header>
                    <Header>Data da última atualização: 07/04/2023</Header>
                </Container>
            </Container>
            <Footer />
        </Container>
    )
}

export default TermsNConditions
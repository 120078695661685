import React, { useState } from 'react';
import { Form, Dropdown, Input, Grid } from 'semantic-ui-react';
import { translateContent } from '../../../../resources/lib';

const PriceInput = ({ formData, handleInputChange, handleRangeTypeDropdown }) => {
    const content = translateContent(localStorage.getItem('Language'))
    const [priceType, setPriceType] = useState(formData.rangeType)

    const priceOptions = content.priceOptions

    function changePriceType(type) {
        setPriceType(type)
        handleRangeTypeDropdown(type)
    }

    return (
        <Form.Field required>
            <label className='big-text shadow-text white-text'>{content.modals.services.price_label}</label>
            <Dropdown
                selection
                options={priceOptions}
                defaultValue={formData.rangeType}
                onChange={(e, { value }) => changePriceType(value)}
            />

            {priceType === 'exact' && (
                <Input label={content.modals.services.priceInput.exact} type="number" step="0.01" min={0} placeholder='0' name="price" value={formData.price} onChange={handleInputChange} required />
            )}
            {priceType === 'upto' && (
                <Input label={content.modals.services.priceInput.upto} type="number" step="0.01" min={0} name="price" value={formData.price} onChange={handleInputChange} required />
            )}
            {priceType === 'from' && (
                <Input label={content.modals.services.priceInput.from} type="number" step="0.01" min={0} name="price" value={formData.price} onChange={handleInputChange} required />
            )}
            {priceType === 'range' && (
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column>
                            <Input
                                fluid
                                label={content.modals.services.priceInput.fromto}
                                type="number"
                                step="0.01"
                                min={0}
                                name="price"
                                value={formData.price}
                                onChange={handleInputChange}
                                required
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <Input
                                fluid
                                label={content.modals.services.priceInput.upto}
                                type="number"
                                step="0.01"
                                min={0}
                                name="priceTo"
                                value={formData.priceTo}
                                onChange={handleInputChange}
                                required
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )}
        </Form.Field>
    )
}

export default PriceInput
import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { auth } from '../../../firebase';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { Button, Container, Divider, Form, Header, Input, Segment } from 'semantic-ui-react'
import { Navigate, useParams } from "react-router-dom"
import { LogErrors, formatCities, getCurrentDateTime, getRegisterMinAge, notifyError, supportEmail, translateContent, translateEmailTextNewAccount, translateEmailTitles, translateErrorMessages, validateAccountCreateObject } from '../../../resources/lib'
import { addDataToCollection, createEmail } from '../../../resources/firebaseLib'
import Footer from '../../Global/Footer'
import CustomDropdown from '../../Global/Dropdown/Dropdown';

const Register = () => {
    const { type } = useParams()
    const [formData, setFormData] = useState(
        {
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            dayOfBirth: '',
            contact: '',
            address: '',
            city: '',
            postalCode: ''
        })
    const [token, setToken] = useState('')
    const [isVerified, setIsVerified] = useState(process.env.REACT_APP_ENV === 'dev' ? true : false)
    const [showPWord, setShowPWord] = useState(false)

    const content = translateContent(localStorage.getItem('Language'))
    const errorMessages = translateErrorMessages(localStorage.getItem('Language'))

    async function register(data) {
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, data.email, data.password)
            return userCredential.user.uid
        } catch (error) {
            LogErrors(error)
            if (error.code === 'auth/email-already-in-use') {
                try {
                    const userCredential = await signInWithEmailAndPassword(auth, data.email, data.password)
                    return userCredential.user.uid
                } catch (signInError) {
                    if (signInError.code === 'auth/invalid-credential' || signInError.code === 'auth/too-many-requests') {
                        const realType = type !== 'client' ? 'user' : 'business';
                        notifyError(errorMessages.register_same_email.replaceAll('acountType', realType))
                    } else {
                        notifyError(errorMessages.register)
                    }
                    LogErrors(signInError)
                    return null
                }
            } else {
                notifyError(errorMessages.register)
                return null
            }
        }
    }

    async function populateCollection(userType, data, uid) {
        try {
            const addData = {
                uid: uid,
                firstName: data.firstName.trim(),
                lastName: data.lastName.trim(),
                dayOfBirth: data.dayOfBirth,
                contact: data.contact,
                email: data.email.trim(),
                address: data.address.trim(),
                city: data.city,
                postalCode: data.postalCode,
                creationDate: getCurrentDateTime(),
                isActive: 0,
                isDelete: 0
            }

            return await addDataToCollection(`client_${userType}`, addData)
        } catch (error) {
            LogErrors(error)
            return false
        }
    }

    async function addLicense(uid, userType) {
        try {
            if (userType === 'business') {
                const addData = {
                    uid: uid,
                    type: 'basic',
                    endDate: ''
                }
                return await addDataToCollection('licenses', addData)
            }
            else
                return true
        } catch (error) {
            LogErrors(error)
            return false
        }
    }

    async function postData(userType, data) {
        let result = await register(data)
        if (result !== null) {
            let uid = result
            result = await populateCollection(userType, data, uid)
            if (result) {
                result = await addLicense(uid, userType)
                if (result) {
                    result = await createEmail(supportEmail, translateEmailTitles('accountCreated'), translateEmailTextNewAccount(
                        {
                            accType: userType.toUpperCase(),
                            firstName: formData.firstName,
                            lastName: formData.lastName,
                            email: formData.email
                        }
                    ))
                    if (!result) {
                        await signOut(auth)
                        notifyError(errorMessages.register_email_creation)
                        return false
                    }
                    else {
                        await signOut(auth)
                        return true
                    }
                }
                else {
                    await signOut(auth)
                    notifyError(errorMessages.register_license_creation)
                    return false
                }
            }
            else {
                await signOut(auth)
                notifyError(errorMessages.register_account_creation)
                return false
            }
        }
        else {
            await signOut(auth)
            return false
        }
    }

    async function handleSubmit(event, type) {
        event.preventDefault()
        const realType = type === 'client' ? 'user' : 'business'
        if (isVerified) {
            let result = await postData(realType, formData)
            if (result) {
                window.location.pathname = "/register/success"
            }
        }
        else {
            notifyError(errorMessages.verify_robot)
        }
    }

    function handleInputChange(event) {
        const { name, value } = event.target
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))
    }

    function handleVerify(token) {
        setToken(token)
        setIsVerified(true)
    }

    function handleExpired() {
        setToken('')
        setIsVerified(false)
    }

    function handleDropdown(id) {
        setFormData((prevFormData) => ({ ...prevFormData, city: id }))
    }

    if (localStorage.getItem('UserInfo'))
        return <Navigate to='/' />
    return (
        <Container fluid>
            <Segment basic className='space-segment'>
                <Header id='page-title' inverted className='huge-text shadow-text' as='h1'>{content.register.title}</Header>
                <Divider />
            </Segment>
            <Container fluid>
                <Container>
                    <Segment basic>
                        <div className='forms-medium-box'>
                            <Form size='big' onSubmit={e => handleSubmit(e, type)}>
                                <Form.Group widths='equal'>
                                    <Form.Field required>
                                        <label className='big-text shadow-text brown-text'>{content.register.first_name_label}</label>
                                        <input placeholder={content.register.first_name_placeholder} maxLength="30" name="firstName" value={formData.firstName} onChange={handleInputChange} required />
                                    </Form.Field>
                                    <Form.Field required>
                                        <label className='big-text shadow-text brown-text'>{content.register.last_name_label}</label>
                                        <input placeholder={content.register.last_name_placeholder} maxLength="30" name="lastName" value={formData.lastName} onChange={handleInputChange} required />
                                    </Form.Field>
                                    <Form.Field required>
                                        <label className='big-text shadow-text brown-text'>{content.register.birth_day_label}</label>
                                        <input type='date' name="dayOfBirth" max={getRegisterMinAge(type)} value={formData.dayOfBirth} onChange={handleInputChange} required />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field required>
                                        <label className='big-text shadow-text brown-text'>{content.register.email_label}</label>
                                        <input type='email' maxLength="100" placeholder={content.register.email_placeholder} name="email" value={formData.email} onChange={handleInputChange} required />
                                    </Form.Field>
                                    {!showPWord &&
                                        <Form.Field required>
                                            <label className='big-text shadow-text brown-text'>{content.register.password_label}</label>
                                            <Input action={
                                                <Button id='pword-btn' circular className='third-back-color' title={content.register.password_button_message_show} icon='eye' onClick={() => setShowPWord(!showPWord)}></Button>
                                            }
                                                type='password' minLength="8" maxLength="20" placeholder={content.register.password_placeholder} name="password" value={formData.password} onChange={handleInputChange} required />
                                        </Form.Field>
                                    }
                                    {showPWord &&
                                        <Form.Field required>
                                            <label className='big-text shadow-text brown-text'>{content.register.password_label}</label>
                                            <Input action={
                                                <Button id='pword-btn' circular className='third-back-color' title={content.register.password_button_message_hide} icon='eye slash' onClick={() => setShowPWord(!showPWord)}></Button>
                                            }
                                                type='text' minLength="8" maxLength="20" placeholder={content.register.password_placeholder} name="password" value={formData.password} onChange={handleInputChange} required />
                                        </Form.Field>
                                    }
                                </Form.Group>
                                <Form.Field required>
                                    <label className='big-text shadow-text brown-text'>{content.register.address_label}</label>
                                    <input type='text' placeholder={content.register.address_placeholder} maxLength="70" name='address' value={formData.address} onChange={handleInputChange} required />
                                </Form.Field>
                                <Form.Group widths='equal'>
                                    <Form.Field required>
                                        <label className='big-text shadow-text brown-text'>{content.register.city_label}</label>
                                        <CustomDropdown data={formatCities()} text={content.register.city_label} handleDropdown={handleDropdown} default={formData.city} />
                                    </Form.Field>
                                    <Form.Field required>
                                        <label className='big-text shadow-text brown-text'>{content.register.postal_label}</label>
                                        <input title='ex: 8700-000' type='text' placeholder={content.register.postal_placeholder} name='postalCode' pattern='[0-9]{4}-[0-9]{3}' value={formData.postalCode} onChange={handleInputChange} required />
                                    </Form.Field>
                                    <Form.Field required>
                                        <label className='big-text shadow-text brown-text'>{content.register.contact_label}</label>
                                        <input title='ex: 961234567 or 221123456' type='text' placeholder={content.register.contact_placeholder} name='contact' pattern='[0-9]{9}' value={formData.contact} onChange={handleInputChange} required />
                                    </Form.Field>
                                </Form.Group>
                                {process.env.REACT_APP_ENV !== 'dev' &&
                                    <Form.Field>
                                        <div className='center-contents'>
                                            <ReCAPTCHA size='compact' sitekey='6LepmogkAAAAALDhmuvQ3yjtIPKfT4QwihCdbN51' onChange={e => handleVerify(e, token)} onExpired={handleExpired} />
                                        </div>
                                    </Form.Field>
                                }
                                {!validateAccountCreateObject(formData) &&
                                    <Button circular size='big' className='secondary-color-btn' type='submit' disabled>{content.register.button}</Button>
                                }
                                {validateAccountCreateObject(formData) &&
                                    <Button circular size='big' className='secondary-color-btn' type='submit'>{content.register.button}</Button>
                                }
                            </Form>
                        </div>
                    </Segment>
                </Container>
            </Container>
            <Footer />
        </Container>
    )
}

export default Register
import React, { useEffect, useState } from 'react';
import { auth } from '../../../firebase';
import { Container, Header, Icon, Menu, Segment, Sidebar } from 'semantic-ui-react';
import { decrytptString, readValueInLocalStorage, readLocalStorageUserValue, translateContent } from '../../../resources/lib';
import { getDataBy, isLogged } from '../../../resources/firebaseLib';
import AppLoader from '../../Global/AppLoader';
import EmailPasswordUpdate from './EmailPasswordUpdate';
import BasicInfo from './BasicInfo';
import DeleteAccount from './DeleteAccount';
import { Link, useParams } from 'react-router-dom';

const MyProfile = () => {
    const width = 768
    const { page } = useParams()
    const [userData, setUserData] = useState(null)
    const [visible, setVisible] = useState(false)
    const [isHidden, setIsHidden] = useState(window.innerWidth > width)

    const content = translateContent(localStorage.getItem('Language'))

    useEffect(() => {
        async function fetchData() {
            const type = decrytptString(readValueInLocalStorage('Type', 'type')) === 'client' ? 'client_user' : 'client_business'
            const result = await getDataBy(type, 'uid', decrytptString(readLocalStorageUserValue('token')))
            setUserData(result[0])
        }
        fetchData()
    }, [])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > width) {
                setIsHidden(true)
            } else {
                setIsHidden(false)
            }
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [width])

    if (!localStorage.getItem('UserInfo'))
        window.location.pathname = "/"
    if (!userData || !isLogged(auth))
        return <AppLoader />
    return (
        <Container fluid id='container-no-margin' className='business-container'>
            <Sidebar.Pushable>
                <Sidebar
                    as={Menu}
                    animation='overlay'
                    icon='labeled'
                    inverted
                    onHide={() => setVisible(false)}
                    vertical
                    visible={visible}
                    width='thin'
                >
                    <Menu.Item as={Link} to={`/account/email`} onClick={() => setVisible(false)}>
                        <Icon name='book' />
                        {content.my_account.sidebar.change_password}
                    </Menu.Item>
                    <Menu.Item as={Link} to={`/account/basic`} onClick={() => setVisible(false)}>
                        <Icon name='wrench' />
                        {content.my_account.sidebar.basic_info}
                    </Menu.Item>
                    <Menu.Item as={Link} to={`/account/delete`} onClick={() => setVisible(false)}>
                        <Icon name='delete' />
                        {content.my_account.sidebar.delete_account}
                    </Menu.Item>
                </Sidebar>

                <Sidebar.Pusher dimmed={visible}>
                    {isHidden &&
                        <div className='sidebar-toggle' onClick={() => setVisible(true)}>
                            <Icon name='chevron right' />
                        </div>
                    }
                    {!isHidden &&
                        <Menu inverted stackable className='square-corners'>
                            <Menu.Item as={Link} to={`/account/email`} onClick={() => setVisible(false)}>
                                <Icon name='book' />
                                {content.my_account.sidebar.change_password}
                            </Menu.Item>
                            <Menu.Item as={Link} to={`/account/basic`} onClick={() => setVisible(false)}>
                                <Icon name='wrench' />
                                {content.my_account.sidebar.basic_info}
                            </Menu.Item>
                            <Menu.Item as={Link} to={`/account/delete`} onClick={() => setVisible(false)}>
                                <Icon name='delete' />
                                {content.my_account.sidebar.delete_account}
                            </Menu.Item>
                        </Menu>
                    }

                    {(page !== 'delete' && page !== 'basic') &&
                        <div className='my-acc-header'>
                            <Segment inverted color='red'>
                                <Header>{content.my_account.warning_message}</Header>
                            </Segment>
                        </div>
                    }
                    {page === 'email' &&
                        <EmailPasswordUpdate userData={userData} />
                    }
                    {page === 'basic' &&
                        <BasicInfo userData={userData} />
                    }
                    {page === 'delete' &&
                        <DeleteAccount />
                    }
                </Sidebar.Pusher>
            </Sidebar.Pushable>

        </Container >
    )
}

export default MyProfile
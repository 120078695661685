import React from 'react';
//import { notifyError, notifySuccess } from '../../resources/lib';

const CopyLink = (props) => {

  /*const handleClick = async (e) => {
    e.preventDefault()

    try {
      await navigator.clipboard.writeText(props.link)
      notifySuccess('Link copiado e pronto a ser partilhado.')
    } catch (err) {
      notifyError('Falha ao copiar o link. Reporte o erro ao suporte, por favor.')
      console.error('Error with the share link', err)
    }
  }*/

  return (
    <a
      href={props.link}
      target='_blank'
      rel="noreferrer"
      className={props.isDark ? 'copy-dark-link shadow-text' : 'copy-link shadow-text'} >
      {props.text}
    </a>
  )
}

export default CopyLink

import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Header, Icon, Input, List, Segment } from 'semantic-ui-react';
import { LogErrors, addDateDays, getCurrentDate, getRandomColor, notifyError, takeDateDays, translateContent, translateErrorMessages } from '../../../resources/lib';
import AppLoader from '../../Global/AppLoader';
import BookingPreviewModal from '../Modals/BookingPreviewModal';
import { getBookingsBusinessAgenda } from '../../../resources/firebaseLib';

const BusinessAgenda = (props) => {
    const [bookingsData, setBookingsData] = useState(null)
    const [currDate, setCurrDate] = useState(getCurrentDate())
    const [onLoad, setOnLoad] = useState(false)

    const content = translateContent(localStorage.getItem('Language'))
    const errorMessages = translateErrorMessages(localStorage.getItem('Language'))

    useEffect(() => {
        if (localStorage.getItem('UserInfo')) {
            const fetchData = async () => {
                setOnLoad(true)
                try {
                    const data = await getBookingsBusinessAgenda(props.businessId, currDate)
                    setBookingsData(data)
                } catch (error) {
                    LogErrors(error)
                    notifyError(errorMessages.contact_support)
                }
                setOnLoad(false)
            }
            fetchData()
        }
    }, [currDate, errorMessages, props])

    function formatSize(value) {
        return String(value).padStart(2, '0')
    }

    function handleChange(event, action) {
        if (action === 'plus')
            setCurrDate(addDateDays(currDate, 1))
        else
            setCurrDate(takeDateDays(currDate, 1))
    }

    function handlePickDate(event) {
        const { value } = event.target
        setCurrDate(value)
    }

    function handleResetDate() {
        setCurrDate(getCurrentDate())
    }

    function getBookingSlots(booking) {
        let occSlots = []
        const endTime = getEndTime(booking.time, booking.duration)
        const startHour = Number(booking.time.split(':')[0])
        if (startHour < endTime[0]) {
            for (let i = startHour; i <= endTime[0]; i++) {
                occSlots.push(`${formatSize(i)}:00`)
            }
        }
        else
            occSlots = [`${formatSize(startHour)}:00`]
        return occSlots
    }

    function getWorktimesFromCurrDate(currDate) {
        if (currDate !== '') {
            const date = new Date(currDate)
            const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][date.getDay()].toLowerCase()
            const worktimes = props.worktimes[0][weekday].split('-')
            return worktimes
        }
        return ''
    }

    function getAgenda(dayWorktimes) {
        const startHour = Number(dayWorktimes[0].split(':')[0])
        const endHour = Number(dayWorktimes[1].split(':')[0])
        let slots = []
        for (let i = startHour; i <= endHour; i++) {
            const time = `${formatSize(i)}:00`
            const obj = {
                time: time,
                data: []
            }
            slots.push(obj)
        }
        return slots
    }

    function getColorWithoutRepeat(bgColors) {
        let color = getRandomColor()
        if (bgColors.length > 0) {
            while (bgColors.includes(color)) {
                color = getRandomColor()
            }
        }
        return color
    }

    function getSlotsData(bookings, agenda) {
        let bgColors = []
        bookings.forEach((booking) => {
            const color = getColorWithoutRepeat(bgColors)
            bgColors.push(color)
            booking.bgColor = color
            const bookingSlots = getBookingSlots(booking)
            bookingSlots.forEach((slot) => {
                let agendaSlot = agenda.find(item => item.time === slot)
                if (agendaSlot !== undefined) {
                    agendaSlot.data.push(booking)
                }
            })
        })
        return agenda
    }

    function getEndTime(startTime, duration) {
        const splitStart = startTime.split(':')
        const startHour = Number(splitStart[0])
        const startMin = Number(splitStart[1])
        const splitEnd = duration.split(':')
        const endHour = Number(splitEnd[0])
        const endMin = Number(splitEnd[1])

        let totalHours = startHour + endHour
        let totalMins = startMin + endMin

        const minsToHours = totalMins / 60
        if (minsToHours >= 1) {
            const hoursToAdd = Math.floor(minsToHours)
            totalMins = totalMins - (hoursToAdd * 60)
            totalHours = totalHours + hoursToAdd
        }

        return [totalHours, totalMins]
    }

    function getFormatedEndTime(startTime, duration) {
        const endTime = getEndTime(startTime, duration)

        return `${startTime} - ${formatSize(endTime[0])}:${formatSize(endTime[1])}`
    }

    function getCards(data) {
        let items = []
        data.forEach((item, index) => {
            const obj = {
                key: index,
                data: item,
                header: `${item.tempUser.firstName} ${item.tempUser.lastName}`,
                description: `${item.servicesNames}`,
                meta: item.servicesNames.length > 0 ?  `${getFormatedEndTime(item.time, item.duration)}` : item.time,
                style: {
                    backgroundColor: item.bgColor
                }
            }
            items.push(obj)
        })
        return (
            <Card.Group centered>
                {
                    items.map((item) => {
                        return (
                            <BookingPreviewModal key={item.key} item={item.data} component={
                                <Card key={item.key} style={item.style} >
                                    <Card.Content>
                                        <Card.Header>
                                            {item.header}
                                        </Card.Header>
                                        <Card.Meta>
                                            {item.meta}
                                        </Card.Meta>
                                        <Card.Description>
                                            {item.description}
                                        </Card.Description>
                                    </Card.Content>
                                </Card >
                            } />
                        )
                    })
                }
            </Card.Group>
        )
    }

    if (!bookingsData || !props.worktimes || onLoad)
        return <AppLoader />

    const dayWorktimes = getWorktimesFromCurrDate(currDate)

    if (dayWorktimes[0] === '')
        return (
            <Container>
                <Segment basic>
                    <Icon id='previous' name='left chevron' onClick={e => handleChange(e, 'minus')} />
                    <Input type='date' value={currDate} onChange={handlePickDate} />
                    <Icon id='next' name='right chevron' onClick={e => handleChange(e, 'plus')} />
                </Segment>
                <Segment basic className='agenda-today-btn'>
                    <Button circular onClick={handleResetDate} className='primary-color-btn'>Hoje</Button>
                </Segment>
                <Header>{content.agenda.closed}</Header>
            </Container>
        )

    const slots = getSlotsData(bookingsData, getAgenda(dayWorktimes))

    return (
        <Container fluid>
            <Segment basic>
                <Icon id='previous' name='left chevron' onClick={e => handleChange(e, 'minus')} />
                <Input type='date' value={currDate} onChange={handlePickDate} />
                <Icon id='next' name='right chevron' onClick={e => handleChange(e, 'plus')} />
            </Segment>
            <Segment basic className='agenda-today-btn'>
                {getCurrentDate() === currDate &&
                    <Button circular className='primary-color-active-btn' onClick={handleResetDate}>{content.agenda.button}</Button>
                }
                {getCurrentDate() !== currDate &&
                    <Button circular className='primary-color-btn' onClick={handleResetDate}>{content.agenda.button}</Button>
                }
            </Segment>
            <div className='scrollable-table'>
                <Segment basic>
                    <List divided relaxed>
                        {slots.map((slot) => (
                            <List.Item key={slot.time}>
                                <List.Content>
                                    <List.Header>{slot.time}</List.Header>
                                    {slot.data.length > 0 &&
                                        <List.Description>
                                            <br />
                                            {getCards(slot.data)}
                                        </List.Description>
                                    }
                                </List.Content>
                            </List.Item>
                        ))}
                    </List>
                </Segment>
            </div>
        </Container>
    )
}

export default BusinessAgenda


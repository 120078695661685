import React, { useState } from 'react'
import { Button, Container, Grid, Header, Modal, Segment } from 'semantic-ui-react'
import { formatTime, translateContent } from '../../../resources/lib'
import { Link } from 'react-router-dom'

const BookingPreviewModal = (props) => {
    const [open, setOpen] = useState(false)

    const content = translateContent(localStorage.getItem('Language'))

    function handleClick(event) {
        setOpen(false)
    }

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={props.component}
        >
            {open &&
                <>
                    <Modal.Header className='black-back-color bigger-text shadow-text'>{content.modals.booking_preview.title}</Modal.Header>
                    <Modal.Content scrolling className='forms-box secondary-back-color'>
                        <Container text>
                            <Segment basic>
                                <Grid padded>
                                    <Grid.Row>
                                        {props.isClient &&
                                            <Grid.Column mobile={16} computer={5}>
                                                <Header className='bigger-text shadow-text brown-text'>{content.modals.booking_preview.business_label}</Header>
                                                <p className='big-text shadow-text white-text'>{props.item.business.name}</p>
                                            </Grid.Column>
                                        }
                                        {!props.isClient &&
                                            <Grid.Column mobile={16} computer={5}>
                                                <Header className='bigger-text shadow-text brown-text'>{content.modals.booking_preview.client_label}</Header>
                                                <p className='big-text shadow-text white-text'>{`${props.item.tempUser.firstName} ${props.item.tempUser.lastName}`}</p>
                                            </Grid.Column>
                                        }
                                        <Grid.Column mobile={16} computer={6}>
                                            <Header className='bigger-text shadow-text brown-text'>{content.modals.booking_preview.date_label}</Header>
                                            <p className='big-text shadow-text white-text'>{props.item.date}</p>
                                        </Grid.Column>
                                        <Grid.Column mobile={16} computer={5}>
                                            <Header className='bigger-text shadow-text brown-text'>{content.modals.booking_preview.hour_label}</Header>
                                            <p className='big-text shadow-text white-text'>{props.item.time}</p>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        {props.isClient &&
                                            <Grid.Column mobile={16} computer={8}>
                                                <Header className='bigger-text shadow-text brown-text'>{content.modals.booking_preview.contact_label}</Header>
                                                <p className='big-text shadow-text white-text'>{props.item.business.contact}</p>
                                            </Grid.Column>
                                        }
                                        {!props.isClient &&
                                            <Grid.Column mobile={16} computer={8}>
                                                <Header className='bigger-text shadow-text brown-text'>{content.modals.booking_preview.contact_label}</Header>
                                                <p className='big-text shadow-text white-text'>{props.item.tempUser.contact}</p>
                                            </Grid.Column>
                                        }

                                        {props.item.servicesNames.length === 0 &&
                                            <Grid.Column mobile={16} computer={8}>
                                                <Header className='bigger-text shadow-text brown-text'>{content.modals.booking_preview.amount_label}</Header>
                                                <p className='big-text shadow-text white-text'>{props.item.amount}</p>
                                            </Grid.Column>
                                        }
                                    </Grid.Row>
                                    {props.item.servicesNames.length !== 0 &&
                                        <Grid.Row>
                                            <Grid.Column mobile={16} computer={8}>
                                                <Header className='bigger-text shadow-text brown-text'>{content.modals.booking_preview.service_label}</Header>
                                                <p className='big-text shadow-text white-text'>{props.item.servicesNames}</p>
                                            </Grid.Column>
                                            <Grid.Column mobile={16} computer={8}>
                                                <Header className='bigger-text shadow-text brown-text'>{content.modals.booking_preview.duration_label}</Header>
                                                <p className='big-text shadow-text white-text'>{formatTime(props.item.duration)}</p>
                                            </Grid.Column>
                                        </Grid.Row>
                                    }
                                    <Grid.Row>
                                        <Grid.Column mobile={16} computer={16}>
                                            <Header className='bigger-text shadow-text brown-text'>{content.modals.booking_preview.add_info_label}</Header>
                                            <p className='big-text shadow-text white-text preformatted-text'>{props.item.notes.length === 0 ? content.modals.booking_preview.without_add_info_text : props.item.notes}</p>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {props.href &&
                                        <Grid.Row>
                                            <Grid.Column mobile={16} computer={16}>
                                                <Button circular className='primary-color-btn' size='big' as={Link} to={props.href} target='_blank' rel='noopener noreferrer'>{content.modals.booking_preview.book_again}</Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                    }
                                </Grid>
                            </Segment>
                        </Container>
                    </Modal.Content>
                    <Modal.Actions className='secondary-back-color'>
                        <Button circular
                            content={content.modals.close}
                            labelPosition='right'
                            icon='checkmark'
                            onClick={(e) => handleClick(e)}
                            className='third-color-active-btn'
                        />
                    </Modal.Actions>
                </>
            }
        </Modal>
    )
}

export default BookingPreviewModal
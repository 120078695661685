import React from 'react';
import { Grid, Header, Segment } from 'semantic-ui-react';
import AppLoader from '../../Global/AppLoader';
import { translateContent } from '../../../resources/lib';

const ClientWortTimes = (props) => {
    const content = translateContent(localStorage.getItem('Language'))

    const tableHeaders = content.client_business_preview.work_times.week_day_names
    const tableFields = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
    if (!props.data)
        return <AppLoader />
    else {
        const data = props.data[0]
        return (
            <div className='forms-tiny-box'>
                <Segment basic>
                    <Grid id='bussines-worktimes-grid' columns={2} padded>
                        {tableFields.map((item, index) => (
                            <Grid.Row key={item}>
                                <Grid.Column mobile={16} computer={8}><Header inverted className='shadow-text'>{tableHeaders[index]}</Header></Grid.Column>
                                <Grid.Column mobile={16} computer={8}><Header className='secondary-text-color shadow-text'>{data[item] === '' ? content.client_business_preview.work_times.closed : data[item]}</Header></Grid.Column>
                            </Grid.Row>
                        ))
                        }
                    </Grid>
                </Segment>
            </div>
        )
    }
}

export default ClientWortTimes
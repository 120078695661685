import React, { useState } from 'react'
import { Button, Container, Grid, Header, Modal } from 'semantic-ui-react'
import { formatTime, translateContent, translateNFormatServicePrice } from '../../../resources/lib'
import ImageCarouselCustom from '../../Global/Images/ImageCarouselCustom'

const BusinessServicePreviewModal = (props) => {
    const { isRestaurant } = props
    const [open, setOpen] = useState(false)

    const content = translateContent(localStorage.getItem('Language'))

    function handleClick(event) {
        setOpen(false)
    }

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={props.component}
        >
            {open &&
                <>
                    <Modal.Header className='black-back-color bigger-text shadow-text'>{content.modals.service_preview.title} - {props.data.name}</Modal.Header>
                    <Modal.Content scrolling className='forms-box secondary-back-color'>
                        <Container text fluid>
                            <Grid padded>
                                <Grid.Row>
                                    <Grid.Column mobile={16} computer={isRestaurant ? 16 : 8}>
                                        <Header className='bigger-text shadow-text brown-text'>{content.modals.service_preview.price_label}</Header>
                                        <p className='big-text shadow-text white-text'>{(props.data.price > 0 ? `${translateNFormatServicePrice(props.data)}€` : content.clientBusinessServicePreview.free_text)}</p>
                                    </Grid.Column>
                                    {!isRestaurant &&
                                        <Grid.Column mobile={16} computer={8}>
                                            <Header className='bigger-text shadow-text brown-text'>{content.modals.service_preview.duration_label}</Header>
                                            <p className='big-text shadow-text white-text'>{formatTime(props.data.time)}</p>
                                        </Grid.Column>
                                    }
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column mobile={16} computer={16}>
                                        <Header className='bigger-text shadow-text brown-text'>{content.modals.service_preview.description_label}</Header>
                                        <p className='big-text shadow-text white-text preformatted-text'>{props.data.description == null || props.data.description?.length === 0 ? content.modals.service_preview.without_description_text : props.data.description}</p>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <ImageCarouselCustom path={props.data.imagePath} title={content.modals.service_preview.carousel_title} />
                                </Grid.Row>
                            </Grid>
                        </Container>
                    </Modal.Content>
                    <Modal.Actions className='secondary-back-color'>
                        <Button circular
                            content={content.modals.service_preview.button}
                            labelPosition='right'
                            icon='checkmark'
                            onClick={(e) => handleClick(e)}
                            className='third-color-active-btn'
                        />
                    </Modal.Actions>
                </>
            }
        </Modal>
    )
}

export default BusinessServicePreviewModal
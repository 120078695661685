import React, { useEffect, useState } from 'react'
import { Container, Divider, Grid, Header, Icon, Segment } from 'semantic-ui-react'
import { translateContent } from '../../../resources/lib'

const iconList = [
    'star',
    'certificate',
    'utensils'
]

const Packages = () => {
    const width = 768
    const [isHidden, setIsHidden] = useState(window.innerWidth > width)

    const content = translateContent(localStorage.getItem('Language'))

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > width) {
                setIsHidden(true)
            } else {
                setIsHidden(false)
            }
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [width])


    if (!isHidden)
        return (
            <Container fluid id='container-no-margin'>
                <Segment basic className='space-segment divider-container'>
                    <Segment basic className='segment-divider-full'>
                        <Header as='h2' className='brown-text huge-text shadow-text'>{content.packages.title}</Header>
                        <Divider />
                    </Segment>
                    <br />
                    <Grid stackable columns={content.packages.lists.length} textAlign='center'>
                        {content.packages.lists.map((item, index) => (
                            <Grid.Column key={index}>
                                <Icon name={iconList[index]} size='huge' className='secondary-color-icon' />
                                <Header as='h2' className='brown-text bigger-text shadow-text'>{item.name}</Header>
                                {item.text.map((text, index) => (
                                    <p key={index} className='secondary-text-color large-text shadow-text'>{text}</p>
                                ))}
                            </Grid.Column>
                        ))}
                    </Grid>
                </Segment>
            </Container>
        )


    return (
        <Container fluid>
            <Segment basic className='space-segment divider-container'>
                <Segment basic className='segment-divider'>
                    <Header as='h2' className='brown-text huge-text shadow-text'>{content.packages.title}</Header>
                    <Divider />
                </Segment>
                <br />
                <Grid stackable columns={content.packages.lists.length} textAlign='center'>
                    {iconList.map((item, index) => (
                        <Grid.Column key={index}>
                            <Icon name={item} size='huge' className='secondary-color-icon' />
                        </Grid.Column>
                    ))}
                </Grid>
                <Grid stackable columns={content.packages.lists.length} textAlign='center'>
                    {content.packages.lists.map((item, columnIndex) => (
                        <Grid.Column key={columnIndex}>
                            <Header as='h2' className='brown-text bigger-text shadow-text'>{item.name}</Header>
                            {item.text.map((text, index) => (
                                <p key={index} className='secondary-text-color large-text shadow-text'>{text}</p>
                            ))}
                        </Grid.Column>
                    ))}
                </Grid>
            </Segment>
        </Container>
    )
}

export default Packages
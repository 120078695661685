import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid'
import "react-toastify/dist/ReactToastify.css";
import { Buffer } from 'buffer';
import { colorOptionsPT, errorMessagesPT, iconOptionsPT, successMessagesPT, contentPT, categoriesPT, emailTitlesPT, emailTextPT, pMethodsOptionsPT } from './translatePT';
import { errorMessagesEN, successMessagesEN, contentEN, emailTitlesEN, emailTextEN } from './translateEN';

//CONSTS
export const supportEmail = "suporte.reservado@gmail.com"

//SESSION
export function destroyStorage() {
    localStorage.removeItem('SessionTime')
    localStorage.removeItem('Type')
    localStorage.removeItem('UserInfo')
    localStorage.removeItem('_grecaptcha')
    localStorage.removeItem('BookingUrl')
}

export function LogErrors(err) {
    if (process.env.REACT_APP_ENV === 'dev') {
        console.log(err)
    }
}

//EMPTY OBJECTS
export function getBusinessServiceEmptyObject(id) {
    return {
        businessId: id,
        name: '',
        price: 0,
        priceTo: 0,
        icon: 'check',
        color: 'black',
        time: '00:00',
        imagePath: '',
        folder: '',
        rangeType: 'exact',
        description: ''
    }
}

export function getBusinessMenuEmptyObject(id) {
    return {
        businessId: id,
        name: '',
        price: 0,
        priceTo: 0,
        icon: 'check',
        color: 'black',
        imagePath: '',
        folder: '',
        rangeType: 'exact',
        description: '',
        amount: 0
    }
}

export function getBusinessEmptyObject() {
    return {
        ownerUid: decrytptString(readLocalStorageUserValue('token')),
        name: '',
        email: '',
        contact: '',
        address: '',
        city: '',
        postalCode: '',
        categories: '',
        categoriesNames: '',
        isRestaurant: false,
        website: ''
    }
}

export function getWorktimesEmptyObject(id) {
    return {
        businessId: id,
        monday: '',
        tuesday: '',
        wednesday: '',
        thursday: '',
        friday: '',
        saturday: '',
        sunday: ''
    }
}

export function getSocialEmptyObject(id) {
    return {
        businessId: id,
        name: '',
        url: ''
    }
}

export function getPMethodEmptyObject(id) {
    return {
        businessId: id,
        name: ''
    }
}

export function getFolderEmptyObject(id) {
    return {
        businessId: id,
        name: ''
    }
}

export function getBookingEmptyObject(id) {
    return {
        bookingEmailId: `${uuidv4()} ${getCurrentDateTime()}`,
        businessId: id,
        clientId: localStorage.getItem('UserInfo') ? decrytptString(readLocalStorageUserValue('token')) : null,
        date: '',
        time: '',
        services: '',
        servicesNames: '',
        duration: '00:00',
        notes: '',
        amount: 0,
        isManual: 0,
        isUserActive: 1,
        isActive: 0
    }
}

export function getBookingUserEmptyObject(id) {
    return {
        bookingEmailId: `${uuidv4()} ${getCurrentDateTime()}`,
        businessId: id,
        clientId: '',
        date: '',
        time: '',
        services: '',
        servicesNames: '',
        duration: '00:00',
        notes: '',
        isManual: 1,
        isUserActive: 1,
        isActive: 0,
        tempUser: {
            firstName: '',
            lastName: '',
            email: '',
            contact: ''
        }
    }
}

//TOAST MESSAGES
export function notifySuccess(text) {
    toast.success(text, {
        position: toast.POSITION.TOP_RIGHT
    })
}

export function notifyError(error) {
    toast.error(error, {
        position: toast.POSITION.TOP_RIGHT
    })
}

//TRANSLATIONS
export function translateEmailTitles(key) {
    return `${emailTitlesPT[key]}|${emailTitlesEN[key]}`
}

export function translateEmailTextNewAccount(data) {
    let text = `${emailTextPT.accountCreated}|${emailTextEN.accountCreated}`
    text = text
        .replaceAll('accType', data.accType)
        .replaceAll('fullAccName', `${data.firstName} ${data.lastName}`)
        .replaceAll('accEmail', data.email)
    return text
}

export function translateEmailTextNewBusiness(data) {
    let text = `${emailTextPT.businessCreated}|${emailTextEN.businessCreated}`
    text = text
        .replaceAll('businessName', data.businessName)
        .replaceAll('fullAccName', `${data.firstName} ${data.lastName}`)
        .replaceAll('accEmail', data.email)
    return text
}

export function translateEmailTextNewBooking(data) {
    let text = `${emailTextPT.bookingCreated}|${emailTextEN.bookingCreated}`
    text = text
        .replaceAll('businessName', data.businessName)
        .replaceAll('fullAccName', `${data.firstName} ${data.lastName}`)
    return text
}

export function translateEmailTextConfirmBooking(data) {
    let text = `${emailTextPT.bookingConfirmed}|${emailTextEN.bookingConfirmed}`
    text = text
        .replaceAll('businessName', data.businessName)
        .replaceAll('fullAccName', `${data.firstName} ${data.lastName}`)
    return text
}

export function translateEmailTextWarningBooking(data) {
    let text = `${emailTextPT.bookingWarning}|${emailTextEN.bookingWarning}`
    text = text
        .replaceAll('businessName', data.businessName)
        .replaceAll('bookingDate', data.date)
    return text
}

export function translateEmailTextFeedback(data) {
    let text = `${emailTextPT.bookingFeedback}|${emailTextEN.bookingFeedback}`
    text = text
        .replaceAll('businessId', data.businessId)
        .replaceAll('guid', data.guid)
    return text
}

export function translateEmailTextAcceptBooking(data) {
    let text = `${emailTextPT.bookingAccepted}|${emailTextEN.bookingAccepted}`
    text = text
        .replaceAll('businessName', data.businessName)
        .replaceAll('fullAccName', `${data.firstName} ${data.lastName}`)
    return text
}

export function translateEmailTextUpdateBooking(data) {
    let text = `${emailTextPT.bookingUpdated}|${emailTextEN.bookingUpdated}`
    text = text
        .replaceAll('businessName', data.businessName)
        .replaceAll('fullAccName', `${data.firstName} ${data.lastName}`)
    return text
}

export function translateEmailTextDeleteBooking(data) {
    let text = `${emailTextPT.bookingDeleted}|${emailTextEN.bookingDeleted}`
    text = text
        .replaceAll('businessName', data.businessName)
        .replaceAll('fullAccName', `${data.firstName} ${data.lastName}`)
    return text
}

export function translateErrorMessages(lang) {
    switch (lang) {
        case 'EN': return errorMessagesEN
        default: return errorMessagesPT
    }
}

export function translateSuccessMessages(lang) {
    switch (lang) {
        case 'EN': return successMessagesEN
        default: return successMessagesPT
    }
}

export function translateContent(lang) {
    switch (lang) {
        case 'EN': return contentEN
        default: return contentPT
    }
}

export function translateCategories(lang, category) {
    switch (lang) {
        case 'EN': return category
        default: return findCategoryToTranslate(categoriesPT, category)
    }
}

export function translateCategoriesString(lang, category, isRestaurant) {
    if (isRestaurant == null || !isRestaurant) {
        switch (lang) {
            case 'EN': return category
            default: return loopToTranslate(categoriesPT, category)
        }
    }
    else {
        return translateRestaurant(lang)
    }
}

function translateRestaurant(lang) {
    switch (lang) {
        case 'EN': return 'Catering Industry'
        default: return "Restauração"
    }
}

function loopToTranslate(categories, categoryToTranslate) {
    const categoriesToTranslate = categoryToTranslate.includes(',') ? categoryToTranslate.split(', ') : [categoryToTranslate]
    let translatedCategories = []
    categoriesToTranslate.forEach(nameEN => {
        const foundCategory = findCategoryToTranslate(categories, nameEN)
        translatedCategories.push(foundCategory ? foundCategory : nameEN)
    })
    return translatedCategories.join(', ')
}

function findCategoryToTranslate(categories, value) {
    const foundCategory = categories.find(category => category.nameEN === value)

    return foundCategory ? foundCategory.namePT : null
}

export function formatNTranslateBreadcrumbUrlText(text) {
    switch (text) {
        case 'empresas':
        case 'businesses':
            return 'business'
        case 'minha conta':
        case 'my account':
            return 'account/basic'
        default:
            return text
    }
}

export function dropdownTransalateManager(option, text) {
    if (option === 'color')
        return translateColor(text)
    if (option === 'icon')
        return translateIcons(text)
    return text
}

function translateColor(color) {
    if (localStorage.getItem('Language') === 'PT') {
        const index = colorOptions.indexOf(color)
        if (index !== -1)
            return colorOptionsPT[index]
        else
            return color
    }
    else
        return color
}

function translateIcons(icon) {
    if (localStorage.getItem('Language') === 'PT') {
        const index = iconOptions.indexOf(icon)
        if (index !== -1)
            return iconOptionsPT[index]
        else
            return icon
    }
    else
        return icon
}

export function translateListOfPMethods(pMethodsList) {
    let translatedList = []
    pMethodsList.forEach(method => {
        translatedList.push({
            id: method.id,
            name: translatePMethods(method.name)
        })
    })
    return translatedList
}

export function translatePMethods(method) {
    if (localStorage.getItem('Language') === 'PT') {
        const index = pMethodsOptions.indexOf(method)
        if (index !== -1)
            return pMethodsOptionsPT[index]
        else
            return method
    }
    else
        return method
}

export function translateNFormatServicePrice(data) {
    const content = translateContent(localStorage.getItem('Language'))
    switch (data.rangeType) {
        case 'exact':
            return `${content.priceOptions[0].text}: ${data.price}`
        case 'upto':
            return `${content.priceOptions[1].text}: ${data.price}`
        case 'from':
            return `${content.priceOptions[2].text}: ${data.price}`
        default:
            return `${content.priceOptions[3].text}: ${data.price} - ${data.priceTo}`
    }
}

export function formatCategoriesNames(names) {
    return names.replaceAll(';', ', ')
}

export function setLanguage() {
    if (!localStorage.getItem('Language'))
        localStorage.setItem('Language', 'PT')
}

export function changeLanguage(lang) {
    localStorage.setItem('Language', lang.toUpperCase())
}

//OBJECT MANIPULATION
export function sortArrayByDateDesc(array) {
    return [...array].sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate))
}

export function sortArrayByDateAsc(array) {
    return [...array].sort((a, b) => new Date(a.creationDate) - new Date(b.creationDate))
}

export function sortArrayByJoinDateDesc(array) {
    return [...array].sort((a, b) => new Date(`${b.date} ${b.time}`) - new Date(`${a.date} ${a.time}`))
}

export function sortByNameAsc(array) {
    const newArray = [...array]
    
    newArray.sort((a, b) => {
        const nameA = a.name.toLowerCase()
        const nameB = b.name.toLowerCase()
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1
        }
        return 0
    })
    
    return newArray
}

export function removeId(objectWithId) {
    const { id, ...objectWithoutId } = objectWithId
    return objectWithoutId
}

export function validateLicense(license) {
    if (license.type !== 'premium')
        return false

    const targetDate = new Date(license.endDate)
    const now = new Date()
    return targetDate > now && license.type === 'premium'
}

export function getNamesFromIds(ids, allData) {
    let data = []
    ids.forEach(id => {
        const info = allData.find(element => element.id === id)
        data.push(info.name)
    })
    let answer = ''
    data.forEach((item, index) => {
        if (index === 0)
            answer += `${item}`
        else
            answer += `;${item}`
    })
    return answer
}

export function getDropdownOptions(list, options = null, hasIcons = false) {
    let newList = [...list]
    let newData = []
    newList.forEach((item, index) => {
        if (hasIcons)
            newData.push({
                key: index,
                icon: item,
                text: dropdownTransalateManager(options, item),
                value: item
            })
        else
            newData.push({
                key: index,
                text: dropdownTransalateManager(options, item),
                value: item
            })
    })
    newData.sort((a, b) => a.text.localeCompare(b.text))
    return newData
}

export function getPMethodsIcons(pMethodName) {
    let propName = pMethodName.replaceAll(' ', '')
    return pMethodsIconOptions[propName]
}

export function getFolderTabs(folders, services) {
    let folderTabs = []
    folders.forEach(folder => {
        let folderServices = []
        services.forEach(service => {
            if (folder.id === service.folder) {
                folderServices.push(service)
            }
        })
        if (folderServices.length > 0)
            folderTabs.push({
                name: folder.name,
                services: folderServices
            })
    })
    folderTabs.push({
        name: 'allServices',
        services: services
    })
    return folderTabs
}

export function validateBusinessObject(data) {
    return (data.name.length > 0 &&
        data.email.length > 0 &&
        data.contact >= 200000000 &&
        ((data.categories.length > 0 &&
            data.categoriesNames.length) || data.isRestaurant) &&
        data.city.length > 0)
}

export function validateAccountCreateObject(data) {
    return (data.firstName.length > 0 &&
        data.lastName.length > 0 &&
        data.email.length > 0 &&
        data.password.length > 0 &&
        data.contact >= 200000000 &&
        data.dayOfBirth.length > 0 &&
        data.city.length > 0 &&
        data.address.length > 0 &&
        data.postalCode.length > 0)
}

export function validateAccountUpdateObject(data) {
    return (data.firstName.length > 0 &&
        data.lastName.length > 0 &&
        data.contact >= 200000000 &&
        data.dayOfBirth.length > 0 &&
        data.city.length > 0 &&
        data.address.length > 0 &&
        data.postalCode.length > 0)
}

//TIME AND DATE FORMATS
export function formatTime(time) {
    const content = translateContent(localStorage.getItem('Language'))
    let splitTime = time.split(':')
    let hours = Number(splitTime[0])
    let mins = Number(splitTime[1])
    let answer = ''
    if (hours > 0) {
        let hourText = hours === 1 ? content.hour : content.hours
        answer += `${hours} ${hourText}${mins > 0 ? ',' : ''}`
    }

    if (mins > 0) {
        let minsText = mins === 1 ? content.minute : content.minutes
        answer += answer.length > 0 ? ` ${mins} ${minsText}` : `${mins} ${minsText}`
    }

    if (answer.length === 0)
        answer = ' - '

    return answer
}

export function isDate1Greater(dateString1, dateString2) {
    const date1 = new Date(dateString1)
    const date2 = new Date(dateString2)

    return date1 > date2
}

export function isDate1GreaterOrEqual(dateString1, dateString2) {
    const date1 = new Date(dateString1)
    const date2 = new Date(dateString2)

    return date1 >= date2
}

export function formatDate(date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
}

export function getCurrTime() {
    const now = new Date()
    const hours = now.getHours().toString().padStart(2, '0')
    const minutes = now.getMinutes().toString().padStart(2, '0')
    return `${hours}:${minutes}`
}

export function getCurrentDate() {
    const date = new Date()
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
}

export function getCurrentDateTime() {
    const date = new Date()
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hours = date.getHours().toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    const seconds = date.getSeconds().toString().padStart(2, '0')
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

export function addDateDays(dateString, days) {
    const date = new Date(dateString)
    date.setDate(date.getDate() + days)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
}

export function takeDateDays(dateString, days) {
    const date = new Date(dateString)
    date.setDate(date.getDate() - days)
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
}

export function getCurrentWeekDays() {
    const today = new Date()
    const dayOfWeek = today.getDay()
    const diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)
    const monday = new Date(today.setDate(diff))
    const currentWeekDays = []
    for (let i = 0; i < 7; i++) {
        const day = new Date(monday)
        day.setDate(monday.getDate() + i)
        currentWeekDays.push(formatDate(day))
    }
    return currentWeekDays
}

export function getRegisterMinAge(type) {
    let age = type === 'client' ? 16 : 18
    let currentDate = new Date()
    currentDate.setFullYear(currentDate.getFullYear() - age)
    return currentDate.toISOString().slice(0, 10)
}

export function changeHoursFromDate(dateStr, hours) {
    let dateObj = new Date(dateStr)

    dateObj.setHours(dateObj.getHours() + hours)

    let year = dateObj.getFullYear()
    let month = (dateObj.getMonth() + 1).toString().padStart(2, '0')
    let day = dateObj.getDate().toString().padStart(2, '0')
    let hoursStr = dateObj.getHours().toString().padStart(2, '0')
    let minutes = dateObj.getMinutes().toString().padStart(2, '0')

    return `${year}-${month}-${day} ${hoursStr}:${minutes}`
}


export function actionWithDelay(path, delay = 5000) {
    setTimeout(() => {
        window.location.pathname = path
    }, delay)
}

//DATA SECURITY
const randomStrings = [
    ['jnOJAPgFHk', '94PbytHXWV'],
    ['ZBYMtZBJKu', 'qPndY454W9'],
    ['51AkRdiyVW', '82HvEvgTzd'],
    ['MhD8Rmu5MP', 'VvkmOU6Vqx'],
    ['aGZJAjku3O', 'N1iNqa7Ysx'],
    ['66xisBr9Vv', 'BefJ22OcuT'],
    ['cSkmjxxS2f', 'oapOqwToUE'],
    ['vYxJYDkK3k', 'gcfmzLD8mN'],
    ['Mi49YOz2Cr', 'cc8Tei4ujv'],
    ['YD3OvqGfB9', 'CzXAV8JFxO'],
    ['cffZrbCgrk', 'cyQ7wWnM4Y'],
    ['GKlMnv1rex', 'V8fHPZkGfN'],
    ['F7yQ58WJrR', 'ksrSJ5CWCR'],
    ['xrNt8LCImL', 'GMKnPfpgpC'],
    ['g55Mepfdue', 'eRlarhwhm0'],
    ['mSoQ0qOS3A', 'Mpo0LqlGmX'],
    ['Mi49YOz2Cr', 'rMdcUl029t'],
    ['YD3OvqGfB9', 'S6IlZUErzJ'],
    ['cffZrbCgrk', 'cyQ7wWnM4Y'],
    ['GKlMnv1rex', 'JCRu9ZeXqR'],
    ['cc8Tei4ujv', '4fJ9BZjGYV'],
    ['V8fHPZkGfN', 'jnOJAPgFHk'],
    ['eRlarhwhm0', 'ZBYMtZBJKu'],
    ['Mpo0LqlGmX', '51AkRdiyVW']
]

export function encryptString(text) {
    const newText = Buffer.from(text).toString('base64')
    const index = Math.floor(Math.random() * randomStrings.length)
    const indexString = String(index)
    return `${randomStrings[index][0]}${newText}${randomStrings[index][1]}${index}${indexString.length}`
}

export function decrytptString(text) {
    try {
        const indexLength = parseInt(text.slice(-1))
        let newText = text.substring(0, text.length - 1)
        const indexString = newText.slice(-indexLength)
        newText = newText.substring(0, newText.length - indexLength)
        const index = parseInt(indexString)
        newText = newText.replace(`${randomStrings[index][0]}`, '')
        newText = newText.replace(`${randomStrings[index][1]}`, '')
        return Buffer.from(newText, 'base64').toString('ascii')
    }
    catch (error) {
        LogErrors(error)
        destroyStorage()
    }
}

//LOCAL STORAGE
export function saveValueInLocalStorage(varName, object) {
    const myObjectString = JSON.stringify(object)
    localStorage.setItem(varName, myObjectString)
}

export function readValueInLocalStorage(varName, key) {
    const retrievedObjectString = localStorage.getItem(varName)
    const retrievedObject = JSON.parse(retrievedObjectString)
    return retrievedObject[key];
}

export function readLocalStorageUserValue(key) {
    if (localStorage.getItem('UserInfo')) {
        const retrievedObjectString = localStorage.getItem('UserInfo')
        const retrievedObject = JSON.parse(retrievedObjectString)
        return retrievedObject.user[key]
    }
    else
        return null
}

export function readLocalStorageTokenValue() {
    if (localStorage.getItem('UserInfo')) {
        const retrievedObjectString = localStorage.getItem('UserInfo')
        const retrievedObject = JSON.parse(retrievedObjectString)
        return retrievedObject.user.token
    }
    else
        return null
}

export function readValueInObjectInLocalStorage(varName, key) {
    if (localStorage.getItem(varName)) {
        const retrievedObjectString = localStorage.getItem(varName)
        const retrievedObject = JSON.parse(retrievedObjectString)
        return retrievedObject[key]
    }
    else
        return null
}

export function readObjectInLocalStorage(varName) {
    if (localStorage.getItem(varName)) {
        const retrievedObjectString = localStorage.getItem(varName)
        const retrievedObject = JSON.parse(retrievedObjectString)
        return retrievedObject
    }
    else
        return null
}

//STRING MANIPULATION
export function capitalize(str) {
    if (str.includes(' ')) {
        let newString = ''
        const words = str.split(' ')
        words.forEach((word, index) => {
            if (word.length > 2) {
                if (index === 0)
                    newString += capitalize(word)
                else
                    newString += ` ${capitalize(word)}`
            }
            else {
                if (index === 0)
                    newString += word
                else
                    newString += ` ${word}`
            }

        })
        return newString
    }
    else
        return capitalizeWord(str)
}

export function capitalizeWord(str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

export function handleReload(millis = 0) {
    const timeoutId = setTimeout(() => {
        window.location.reload()
    }, millis)

    return () => {
        clearTimeout(timeoutId)
    }
}

export function convertStringArrayIntoNumberArray(stringArray) {
    return stringArray.map((str) => String(str))
}

//AGENDA COLOR FUNCTIONS
export function getRandomColor(minBrightness = 128) {
    // Generate a random color with a minimum brightness value
    const randomColor = () => Math.floor(Math.random() * (256 - minBrightness) + minBrightness).toString(16).padStart(2, '0')

    // Generate each color channel separately
    const r = randomColor()
    const g = randomColor()
    const b = randomColor()

    // Concatenate the color channels into a single hex color value
    const hexColor = `#${r}${g}${b}`

    return hexColor
}

//ICON, COLOR and PMETHOD NAMES

export const pMethodsOptions = [
    'Money',
    'PayPal',
    'MBWay',
    'Credit Card'
]

export const pMethodsIconOptions = {
    Money: 'money bill alternate outline',
    PayPal: 'paypal',
    MBWay: 'mobile alternate',
    CreditCard: 'credit card outline'
}

export const colorOptions = [
    'red',
    'orange',
    'yellow',
    'olive',
    'green',
    'teal',
    'blue',
    'violet',
    'purple',
    'pink',
    'brown',
    'grey',
    'black'
]

export const iconOptions = [
    'universal access',
    'arrow alternate circle right',
    'caret right',
    'caret square right',
    'chevron right',
    'hand point right',
    'long arrow alternate right',
    'location arrow',
    'fast forward',
    'forward',
    'music',
    'play',
    'play circle',
    'podcast',
    'microphone',
    'circle',
    'video',
    'stop',
    'phone volume',
    'pause',
    'headphones',
    'address book',
    'address card',
    'archive',
    'building',
    'balance scale',
    'book',
    'briefcase',
    'chart line',
    'compass',
    'pencil alternate',
    'paperclip',
    'compass',
    'compass outline',
    'calendar alternate',
    'bullseye',
    'save',
    'tasks',
    'tag',
    'chess',
    'chess knight',
    'chess pawn',
    'chess queen',
    'chess king',
    'user secret',
    'coffee',
    'comment alternate',
    'mobile alternate',
    'mobile',
    'paper plane',
    'at',
    'comments',
    'envelope',
    'desktop',
    'laptop',
    'dollar sign',
    'euro sign',
    'money bill alternate',
    'hourglass',
    'stopwatch',
    'hourglass end',
    'eye',
    'cut',
    'crosshairs',
    'tint',
    'paint brush',
    'handshake',
    'thumbs up',
    'ambulance',
    'heart',
    'heartbeat',
    'medkit',
    'medkit',
    'user md',
    'plus square',
    'stethoscope',
    'bolt',
    'camera',
    'camera retro',
    'eye dropper',
    'image',
    'check',
    'check circle',
    'beer',
    'search',
    'star',
    'user',
    'remove circle',
    'remove',
    'food'
]

export function formatCities() {
    let list = []
    cities.forEach(city => {
        list.push({
            id: city,
            name: city
        })
    })
    return list
}

const cities = [
    "Abrantes",
    "Agualva-Cacém",
    "Águeda",
    "Albergaria-a-Velha",
    "Albufeira",
    "Alcácer do Sal",
    "Alcobaça",
    "Alfena",
    "Almada",
    "Almeirim",
    "Alverca do Ribatejo",
    "Amadora",
    "Amarante",
    "Amora",
    "Anadia",
    "Aveiro",
    "Barcelos",
    "Barreiro",
    "Beja",
    "Borba",
    "Braga",
    "Bragança",
    "Caldas da Rainha",
    "Cantanhede",
    "Cartaxo",
    "Castelo Branco",
    "Chaves",
    "Coimbra",
    "Costa da Caparica",
    "Covilhã",
    "Elvas",
    "Entroncamento",
    "Ermesinde",
    "Esmoriz",
    "Espinho",
    "Esposende",
    "Estarreja",
    "Estremoz",
    "Évora",
    "Fafe",
    "Faro",
    "Fátima",
    "Felgueiras",
    "Figueira da Foz",
    "Fiães",
    "Freamunde",
    "Fundão",
    "Gafanha da Nazaré",
    "Gandra",
    "Gondomar",
    "Gouveia",
    "Guarda",
    "Guimarães",
    "Ílhavo",
    "Lagoa",
    "Lagos",
    "Lamego",
    "Leiria",
    "Lisboa",
    "Lixa",
    "Lordelo",
    "Loulé",
    "Loures",
    "Lourosa",
    "Macedo de Cavaleiros",
    "Maia",
    "Mangualde",
    "Marco de Canaveses",
    "Marinha Grande",
    "Matosinhos",
    "Mealhada",
    "Mêda",
    "Miranda do Douro",
    "Mirandela",
    "Montemor-o-Novo",
    "Montijo",
    "Moura",
    "Odivelas",
    "Olhão",
    "Oliveira de Azeméis",
    "Oliveira do Bairro",
    "Oliveira do Hospital",
    "Ourém",
    "Ovar",
    "Paços de Ferreira",
    "Paredes",
    "Penafiel",
    "Peniche",
    "Peso da Régua",
    "Pinhel",
    "Pombal",
    "Ponte de Sor",
    "Portalegre",
    "Portimão",
    "Porto",
    "Póvoa de Santa Iria",
    "Póvoa de Varzim",
    "Quarteira",
    "Queluz",
    "Rebordosa",
    "Reguengos de Monsaraz",
    "Rio Maior",
    "Rio Tinto",
    "Sabugal",
    "Sacavém",
    "Samora Correia",
    "Santa Comba Dão",
    "Santa Maria da Feira",
    "Santarém",
    "Santiago do Cacém",
    "Santo Tirso",
    "São João da Madeira",
    "São Mamede de Infesta",
    "São Pedro do Sul",
    "Seia",
    "Seixal",
    "Senhora da Hora",
    "Serpa",
    "Setúbal",
    "Silves",
    "Sines",
    "Tarouca",
    "Tavira",
    "Tomar",
    "Tondela",
    "Torres Novas",
    "Torres Vedras",
    "Trancoso",
    "Trofa",
    "Valbom",
    "Vale de Cambra",
    "Valença",
    "Valongo",
    "Valpaços",
    "Vendas Novas",
    "Viana do Castelo",
    "Vila do Conde",
    "Vila Franca de Xira",
    "Vila Nova de Famalicão",
    "Vila Nova de Foz Côa",
    "Vila Nova de Gaia",
    "Vila Nova de Santo André",
    "Vila Real",
    "Vila Real de Santo António",
    "Viseu",
    "Vizela"
]

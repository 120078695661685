import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Icon, Table } from 'semantic-ui-react';
import { getFolderEmptyObject, notifyError, notifySuccess, translateContent, translateErrorMessages, translateSuccessMessages } from '../../../resources/lib';
import AppLoader from '../../Global/AppLoader';
import ConfirmModal from '../Modals/ConfirmModal'
import { deleteDocById } from '../../../resources/firebaseLib';
import BusinessFoldersModal from '../Modals/BusinessFoldersModal';

const ServiceFolders = (props) => {
    const content = translateContent(localStorage.getItem('Language'))
    const errorMessages = translateErrorMessages(localStorage.getItem('Language'))
    const successMessages = translateSuccessMessages(localStorage.getItem('Language'))

    async function handleDelete(item) {
        const result = await deleteDocById(props.isRestaurant ? 'business_menus_folders' : 'business_folders', item.id)
        if (result) {
            props.getFolders(props.businessId)
            notifySuccess(props.isRestaurant ? successMessages.menu_group_deleted : successMessages.group_deleted)
        }
        else {
            notifyError(errorMessages.contact_support)
        }
    }

    async function handleClick(type, item) {
        if (type)
            await handleDelete(item)
    }

    function handleModal(message) {
        props.getFolders(props.businessId)
        notifySuccess(message)
    }

    const tableHeaders = content.folders.table_headers_text
    const tableFields = content.folders.table_headers_values

    if (!props.data)
        return <AppLoader />
    return (
        <Container>
            <Table id='folders-table' celled stackable selectable textAlign='center' className='no-border shadow-text'>
                <Table.Header>
                    <Table.Row className='table-header-bg'>
                        {
                            tableHeaders.map((item) => (
                                <Table.HeaderCell key={item}>{item}</Table.HeaderCell>
                            ))
                        }
                    </Table.Row>
                </Table.Header>
                <Table.Body className='table-body-bg'>
                    {props.data.length === 0 &&
                        <Table.Row>
                            <Table.Cell colSpan='3'>{content.nothing_to_show}</Table.Cell>
                        </Table.Row>
                    }
                    {props.data.map((item, index) => (
                        <Table.Row key={index}>
                            {tableFields.map((field) => (
                                <Table.Cell key={field}>{item[field]}</Table.Cell>
                            ))
                            }
                            <Table.Cell>
                                <BusinessFoldersModal handleModal={handleModal} item={item} isRestaurant={props.isRestaurant} component={<Icon title={content.edit} size='large' color='yellow' name='edit' />} />
                                <ConfirmModal item={item} handleClick={handleClick} action={`${content.folders.delete_action} ${item.name}?`}
                                    component={<Icon title={content.delete} size='large' color='red' name='delete' />} />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            <BusinessFoldersModal handleModal={handleModal} new={true} item={getFolderEmptyObject(props.businessId)} data={props.data} isRestaurant={props.isRestaurant} component={<Button id='add-folder-btn' circular size='big' as={Link} className='primary-color-btn'><Icon name='add' /> {props.isRestaurant ? content.folders.button_menus : content.folders.button_services}</Button>} />
        </Container>
    )
}

export default ServiceFolders
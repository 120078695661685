import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Grid, Header, Segment } from 'semantic-ui-react'
import { handleReload, translateContent } from '../../../resources/lib'

const TypeChoice = () => {
    const currentURL = window.location.href
    const content = translateContent(localStorage.getItem('Language'))

    function handleClick(){
        handleReload()
    } 

    return (
        <Container fluid className='space-segment' id='container-no-margin'>
            <Container>
                <Grid stackable columns='equal'>
                    <Grid.Row className='middle aligned'>
                        <Grid.Column>
                            <Header as='h2' className='primary-text-color massive-text shadow-text'>{currentURL.includes("business") ? content.typeChoice.client : content.typeChoice.business}</Header>
                        </Grid.Column>
                        <Grid.Column>
                            <Segment id={currentURL.includes("business") ? 'choice-client' : 'choice-business'} onClick={handleClick} circular size='massive' as={Link} to={currentURL.includes("business") ? '/' : '/home/business'} className='third-color-btn bigger-text'>{currentURL.includes("business") ? content.typeChoice.yesClient : content.typeChoice.yesBusiness}</Segment>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </Container>
    )

}

export default TypeChoice
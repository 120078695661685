import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Container, Divider, Form, Header, Segment } from 'semantic-ui-react';
import { notifyError, getNamesFromIds, readLocalStorageUserValue, decrytptString, getCurrentDateTime, translateContent, translateErrorMessages, supportEmail, translateEmailTitles, translateEmailTextNewBusiness, validateBusinessObject, formatCities, LogErrors, actionWithDelay, notifySuccess, translateSuccessMessages } from '../../../resources/lib';
import { getAllDocuments, addDataToCollection, createEmail, getDataBy } from '../../../resources/firebaseLib';
import { Link, Navigate } from 'react-router-dom';
import AppLoader from '../../Global/AppLoader';
import CustomMultiDropdown from '../../Global/Dropdown/MultiDropdown';
import Footer from '../../Global/Footer';
import CustomDropdown from '../../Global/Dropdown/Dropdown';

const NewBusiness = () => {
    const [categoryData, setCategoryData] = useState(null)
    const [checked, setChecked] = useState(false)
    const [formData, setFormData] = useState(
        {
            ownerUid: decrytptString(readLocalStorageUserValue('token')),
            name: '',
            email: '',
            contact: '',
            address: '',
            city: '',
            postalCode: '',
            categories: '',
            categoriesNames: '',
            isRestaurant: false,
            website: ''
        })

    const content = translateContent(localStorage.getItem('Language'))
    const errorMessages = translateErrorMessages(localStorage.getItem('Language'))
    const successMessages = translateSuccessMessages(localStorage.getItem('Language'))

    useEffect(() => {
        async function fetchData() {
            if (localStorage.getItem('UserInfo')) {
                const data = await getAllDocuments('category')
                setCategoryData(data)
            }
            else
                setCategoryData([])
        }
        fetchData()
    }, [])

    async function populateCollection(data) {
        try {
            const addData = {
                ownerUid: data.ownerUid,
                name: data.name.trim(),
                email: data.email.trim(),
                contact: data.contact,
                address: data.address.trim(),
                city: data.city,
                postalCode: data.postalCode,
                categories: data.categories,
                categoriesNames: data.categoriesNames,
                isRestaurant: data.isRestaurant,
                website: data.website.trim(),
                isActive: 1,
                isDelete: 0,
                creationDate: getCurrentDateTime()
            }

            let result = await addDataToCollection('business', addData)
            if (!result)
                return false

            result = await createEmail(supportEmail, translateEmailTitles('businessCreated'), translateEmailTextNewBusiness(
                {
                    businessName: formData.name,
                    firstName: readLocalStorageUserValue('firstName'),
                    lastName: readLocalStorageUserValue('lastName'),
                    email: formData.email
                }
            ))

            return result
        } catch (error) {
            LogErrors(error)
            return false
        }
    }

    async function handleSubmit(event) {
        event.preventDefault()
        let result = await getDataBy('business', 'name', formData.name)
        if (result.length > 0) {
            notifyError(errorMessages.business_error)
        }
        else {
            result = await populateCollection(formData)
            if (result) {
                notifySuccess(successMessages.created)
                actionWithDelay("/business")
            }
            else {
                notifyError(errorMessages.contact_support)
            }
        }
    }

    function handleInputChange(event) {
        const { name, value } = event.target
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))
    }

    function handleMultiDropdown(categories) {
        setFormData((prevFormData) => ({ ...prevFormData, categories: categories.sort().join() }))
        setFormData((prevFormData) => ({ ...prevFormData, categoriesNames: getNamesFromIds(categories.sort(), categoryData) }))
    }

    function handleDropdown(id) {
        setFormData((prevFormData) => ({ ...prevFormData, city: id }))
    }

    const handleCheckboxChange = () => {
        setChecked(!checked)
        setFormData((prevFormData) => ({ ...prevFormData, isRestaurant: !checked, categories: '', categoriesNames: '' }))
    }

    if (!localStorage.getItem('UserInfo'))
        return <Navigate to='/' />
    if (!categoryData)
        return <AppLoader />
    return (
        <Container fluid>
            <Segment basic className='space-segment'>
                <Header id='page-title' inverted className='huge-text shadow-text' as='h1'>{content.new_business.title_new}</Header>
                <Divider />
            </Segment>
            <Container fluid>
                <Container>
                    <div className='forms-small-medium-box'>
                        <Segment basic>
                            <Form size='big' onSubmit={e => handleSubmit(e)}>
                                <Form.Group widths='equal'>
                                    <Form.Field required>
                                        <label className='big-text shadow-text brown-text'>{content.new_business.name_label}</label>
                                        <input placeholder={content.new_business.name_placeholder} name='name' value={formData.name} onChange={handleInputChange}
                                            maxLength='50'
                                            required />
                                    </Form.Field>
                                    <Form.Field required>
                                        <label className='big-text shadow-text brown-text'>{content.modals.business.categories_label}</label>
                                        {!checked &&
                                            <CustomMultiDropdown isCategory={true} data={categoryData} text={content.modals.business.categories_drop_default} handleDropdown={handleMultiDropdown}
                                                default={formData.categories ? formData.categories.split(',') : null} />
                                        }
                                        <Checkbox
                                            className='medium-text shadow-text brown-text'
                                            label='A sua empresa pertence à Categoria da Restauração?'
                                            checked={checked}
                                            onChange={handleCheckboxChange}
                                        />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Group widths='equal'>
                                    <Form.Field required>
                                        <label className='big-text shadow-text brown-text'>{content.new_business.email_label}</label>
                                        <input type='email' placeholder={content.new_business.email_placeholder} name="email" maxLength='100' value={formData.email} onChange={handleInputChange} required />
                                    </Form.Field>
                                    <Form.Field required>
                                        <label className='big-text shadow-text brown-text'>{content.new_business.contact_label}</label>
                                        <input placeholder={content.new_business.contact_placeholder} name='contact' value={formData.contact} onChange={handleInputChange}
                                            maxLength='9'
                                            pattern='[0-9]{9}'
                                            title='ex: 961234567 or 221123456'
                                            required />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Field>
                                    <label className='big-text shadow-text brown-text'>{content.new_business.address_label}</label>
                                    <input placeholder={content.new_business.address_placeholder} name='address' value={formData.address} onChange={handleInputChange}
                                        maxLength='70' />
                                </Form.Field>
                                <Form.Group widths='equal'>
                                    <Form.Field required>
                                        <label className='big-text shadow-text brown-text'>{content.new_business.city_label}</label>
                                        <CustomDropdown data={formatCities()} text={content.new_business.city_label} handleDropdown={handleDropdown} />
                                    </Form.Field>
                                    <Form.Field>
                                        <label className='big-text shadow-text brown-text'>{content.new_business.postal_label}</label>
                                        <input placeholder={content.new_business.postal_placeholder} name='postalCode' value={formData.postalCode} onChange={handleInputChange}
                                            maxLength='8'
                                            pattern='[0-9]{4}-[0-9]{3}'
                                            title='ex: 8700-000' />
                                    </Form.Field>
                                </Form.Group>
                                <Form.Field>
                                    <label className='big-text shadow-text brown-text'>{content.new_business.website_label}</label>
                                    <input title='ex: http://www.google.com/ ou https://www.google.com/' type='url' placeholder={content.new_business.website_placeholder} name='website' value={formData.website} onChange={handleInputChange}
                                        maxLength='80' />
                                </Form.Field>
                                <Button id='cancel-btn' circular size='big' as={Link} to={`/business`} className='third-color-active-btn'>{content.new_business.cancel}</Button>
                                {(validateBusinessObject(formData)) &&
                                    <Button circular size='big' className='primary-color-active-btn' type='submit'>{content.new_business.save}</Button>
                                }
                                {!(validateBusinessObject(formData)) &&
                                    <Button circular size='big' className='primary-color-active-btn' type='submit' disabled>{content.new_business.save}</Button>
                                }
                            </Form>
                        </Segment>
                    </div>
                </Container>
            </Container>
            <Footer />
        </Container>
    )
}

export default NewBusiness
import React from 'react'
import { Container, Header, Segment } from 'semantic-ui-react'
import Footer from '../Footer'
import { decrytptString, readValueInObjectInLocalStorage } from '../../../resources/lib'
import { Link, Navigate } from 'react-router-dom'

const Guide = () => {
    if (!localStorage.getItem('UserInfo'))
        return <Navigate to='/' />

    const type = decrytptString(readValueInObjectInLocalStorage('Type', 'type'))
    if (type === 'business')
        return (
            <Container fluid>
                <Segment basic className='space-segment'>
                    <Header inverted className='huge-text shadow-text' as='h1'>Guide</Header>
                </Segment>
                <Container fluid>
                    <Container className='forms-small-box brown-text' textAlign='justified'>
                        <Header>1. Introduction</Header>

                        <p>Welcome to <b>Reservado</b>. <b>Reservado</b> is the complete solution for managing online reservations in your business.</p>

                        <Header>2. <Link className='copy-link' to='/business'>Business</Link> Management</Header>
                        <ol type="1">
                            <li><b>Preferences:</b> Set up schedules, social networks, payment methods, and service groups.</li>
                            <li><b>Services:</b> Create and manage the services offered by your company. They must be active to be bookable.</li>
                            <li><b>Reservations:</b> Here you can view, confirm, edit, or cancel the reservations received.</li>
                            <li><b>Schedule:</b> Access the schedule to get an overview of the reservations and appointments scheduled.</li>
                        </ol>

                        <Header>3. <Link className='copy-link' to='/account/basic'>User Account</Link> Management</Header>
                        <ol type="1">
                            <li><b>Basic Information:</b> Here you can check and edit your account information, such as name, contact, and address.</li>
                            <li><b>Delete Account:</b> If necessary, you can permanently delete your business account.</li>
                            <li><b>Password Reset:</b> Keep your account secure by regularly updating your password.</li>
                        </ol>

                        <Header>4. Packages</Header>
                        <ol type="1">
                            <li><b>Basic:</b> Access to reservation management, schedule, and basic company settings.</li>
                            <li><b>Premium:</b> In addition to the Basic Package features, Premium accounts include the ability to accept online bookings and receive email alerts for reservation reminders.</li>
                        </ol>

                        <Header>5. Access to the <Link className='copy-link' to='/business'>Business</Link> Web Page</Header>

                        <p>After account activation and service configuration, your company's webpage will be available, allowing you to share information with customers.</p>

                        <Header>6. Contact and Technical Support</Header>
                        <p>For assistance or questions related to the application, please contact us through the "Our Support" button.</p>
                    </Container>
                </Container>
                <Footer />
            </Container>
        )
    else
        return (
            <Container fluid>
                <Segment basic className='space-segment'>
                    <Header inverted className='huge-text shadow-text' as='h1'>Guide</Header>
                </Segment>
                <Container fluid>
                    <Container className='forms-small-box brown-text' textAlign='justified'>
                        <Header>1. Introduction</Header>

                        <p>Welcome to <b>Reservado</b>. <b>Reservado</b> is the complete solution for managing online reservations in your business.</p>

                        <Header>2. <Link className='copy-link' to='/bookings/today'>Bookings</Link> Management</Header>
                        <ol type="1">
                            <li>
                                <p>On the Reservations page, you can manage all your current reservations.</p>
                            </li>
                            <li>
                                <p>Here you have the option to view details, edit, accept, or cancel your reservations as needed.</p>
                            </li>
                        </ol>

                        <Header>3. Using the <Link className='copy-link' to='/agenda'>Agenda</Link></Header>
                        <ol type="1">
                            <li>
                                <p>The schedule offers an overview of all your scheduled reservations for the selected day.</p>
                            </li>
                            <li>
                                <p>Use this feature to better plan and organize your day.</p>
                            </li>
                        </ol>

                        <Header>4. <Link className='copy-link' to='/account/basic'>User Account</Link> Management</Header>
                        <ol type="1">
                            <li><b>Basic Information:</b> Here you can check and edit your account information, such as name, contact, and address.</li>
                            <li><b>Delete Account:</b> If necessary, you can permanently delete your business account.</li>
                            <li><b>Password Reset:</b> Keep your account secure by regularly updating your password.</li>
                        </ol>

                        <Header>5. Contact and Technical Support</Header>
                        <p>For assistance or questions related to the application, please contact us through the "Our Support" button.</p>
                    </Container>
                </Container>
                <Footer />
            </Container>
        )
}

export default Guide
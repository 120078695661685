import React from 'react'
import { Breadcrumb } from 'semantic-ui-react'
import { formatNTranslateBreadcrumbUrlText } from '../../resources/lib'

const Breadcrumbs = (props) => {
    function getBreadcrumbs(list) {
        let sections = []
        let counter = 1
        list.forEach(element => {
            if (counter === list.length)
                sections.push(
                    {
                        key: element,
                        content: element,
                        active: true
                    }
                )
            else
                sections.push(
                    {
                        key: element,
                        content: element,
                        href: `/${formatNTranslateBreadcrumbUrlText(element.toLowerCase())}`
                    }
                )
            counter++
        })
        return sections
    }

    return (
        <div className='breadcrumbs-div'>
            <Breadcrumb className='bread-color' icon='right angle' sections={getBreadcrumbs(props.list)} />
        </div>
    )
}

export default Breadcrumbs
import React, { useEffect, useState } from 'react';
import { signOut } from 'firebase/auth'
import { auth } from '../../firebase';
import { Link } from 'react-router-dom'
import { Dropdown, Icon, Image, Menu, Sticky } from 'semantic-ui-react'
import { decrytptString, destroyStorage, handleReload, readLocalStorageUserValue, readValueInObjectInLocalStorage, translateContent } from '../../resources/lib';
import BusinessNavBar from './NavBars/BusinessNavbar';
import ClientNavBar from './NavBars/ClientNavbar';
import logo from '../../resources/logo.png'
import LanguagesDrop from './NavBars/LanguagesDrop';

const NavBar = () => {
  const currentURL = window.location.href
  const width = localStorage.getItem('UserInfo') ? decrytptString(readValueInObjectInLocalStorage('Type', 'type')) === 'client' ? 1245 : 1157 : 1157
  const [menu, setActiveItem] = useState({ activeItem: 'home' })
  const [isHidden, setIsHidden] = useState(window.innerWidth > width)

  const content = translateContent(localStorage.getItem('Language'))

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > width) {
        setIsHidden(true)
      } else {
        setIsHidden(false)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [width])

  function handleItemClick(event, { name }) {
    setActiveItem((prevFormData) => ({ ...prevFormData, activeItem: name }))
  }

  function handleLogout() {
    const isClient = decrytptString(readValueInObjectInLocalStorage('Type', 'type')) === 'client'
    window.location.pathname = isClient ? '/' : '/home/business'
    destroyStorage()
    signOut(auth)
    handleReload()
  }

  function handleSessionTime() {
    if (localStorage.getItem('SessionTime')) {
      const endDate = localStorage.getItem('SessionTime')
      const formattedDate = new Date(endDate.replaceAll('"', ''))
      if (new Date() > formattedDate) {
        handleLogout()
      }
    }
  }

  handleSessionTime()
  return (
    <Sticky>
      <Menu inverted stackable size='huge' className='menu-bg square-corners'>
        {localStorage.getItem('UserInfo') ? (
          decrytptString(readValueInObjectInLocalStorage('Type', 'type')) === 'client' ? (
            <ClientNavBar />
          ) : (
            <BusinessNavBar />
          )
        ) : (
          <Menu.Menu position='left'>
            <Menu.Item
              id='home'
              name='home'
              active={menu.activeItem === 'home'}
              onClick={handleItemClick}
              as={Link}
              to='/'
            >
              <Image src={logo} size='medium' />
            </Menu.Item>
            <Menu.Item
              id='terms'
              name='terms'
              active={menu.activeItem === 'terms'}
              onClick={handleItemClick}
              as={Link}
              to='/terms'
            >
              <Icon name='file' />
              {content.menu.terms}
            </Menu.Item>
          </Menu.Menu>
        )
        }

        {isHidden &&
          <Menu.Menu position='right'>
            <LanguagesDrop />
            {localStorage.getItem('UserInfo') ? (
              <Dropdown id='account-drop' item text={`${readLocalStorageUserValue('firstName')} ${readLocalStorageUserValue('lastName')}`}>
                <Dropdown.Menu>
                  <Dropdown.Item id='my-account-btn' as={Link} to="/account/basic"><Icon name='user' />{content.menu.drop.my_account}</Dropdown.Item>
                  <Dropdown.Item id='logout-btn' onClick={handleLogout}><Icon name='log out' />{content.menu.drop.logout}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <Dropdown id='login-drop' item text={content.menu.login}>
                <Dropdown.Menu>
                  <Dropdown.Item id='login-btn' as={Link} to={`/login/${currentURL.includes("business") ? "business" : "client"}`}><Icon name='sign in' />{content.menu.drop.login}</Dropdown.Item>
                  <Dropdown.Item id='register-btn' as={Link} to={`/register/${currentURL.includes("business") ? "business" : "client"}`}><Icon name='signup' />{content.menu.drop.create}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )
            }
          </Menu.Menu>
        }
      </Menu>
      {!isHidden &&
        <Menu inverted stackable size='huge' className='menu-bg square-corners second-nav-margin'>
          <Menu.Menu position='right'>
            <LanguagesDrop />
            {localStorage.getItem('UserInfo') ? (
              <Dropdown id='account-drop' item text={`${readLocalStorageUserValue('firstName')} ${readLocalStorageUserValue('lastName')}`}>
                <Dropdown.Menu>
                  <Dropdown.Item id='my-account-btn' as={Link} to="/account/basic"><Icon name='user' />{content.menu.drop.my_account}</Dropdown.Item>
                  <Dropdown.Item id='logout-btn' onClick={handleLogout}><Icon name='log out' />{content.menu.drop.logout}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <Dropdown id='login-drop' item text={content.menu.login}>
                <Dropdown.Menu>
                  <Dropdown.Item id='login-btn' as={Link} to={`/login/${currentURL.includes("business") ? "business" : "client"}`}><Icon name='sign in' />{content.menu.drop.login}</Dropdown.Item>
                  <Dropdown.Item id='register-btn' as={Link} to={`/register/${currentURL.includes("business") ? "business" : "client"}`}><Icon name='signup' />{content.menu.drop.create}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )
            }
          </Menu.Menu>
        </Menu>
      }
    </Sticky>
  )
}

export default NavBar
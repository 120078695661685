import React, { useCallback, useEffect, useState } from 'react';
import { Button, Divider, Form, Header, Icon, Modal, Segment } from 'semantic-ui-react';
import { LogErrors, colorOptions, getDropdownOptions, iconOptions, notifyError, removeId, translateContent, translateErrorMessages, translateSuccessMessages } from '../../../resources/lib';
import CustomDropdown from '../../Global/Dropdown/Dropdown';
import { addDataToCollection, updateDataByDocId } from '../../../resources/firebaseLib';
import ImageInputGrid from '../../Global/Images/ImageInputGrid';
import { deleteFiles, getAllFilesFromFolder, uploadFiles } from '../../../resources/storageLib';
import { v4 } from 'uuid';
import PriceInput from './Extensions/PriceInput';

const BusinessServiceModal = (props) => {
    const [open, setOpen] = useState(false)
    const [formData, setFormData] = useState({ ...props.item })
    const [selectedFiles, setSelectedFiles] = useState(null)
    const [selectedFilesChanged, setSelectedFilesChanged] = useState(false)
    const [remoteFilesToRemove, setRemoteFilesToRemove] = useState([])

    const content = translateContent(localStorage.getItem('Language'))
    const errorMessages = translateErrorMessages(localStorage.getItem('Language'))
    const successMessages = translateSuccessMessages(localStorage.getItem('Language'))

    const restoreValues = useCallback(async () => {
        setFormData(props.item)
        setRemoteFilesToRemove([])
        setSelectedFilesChanged(false)
        await getFolderFiles(formData.imagePath)
    }, [props.item, formData.imagePath])

    useEffect(() => {
        async function fetchData() {
            await getFolderFiles(props.item.imagePath)
        }

        if (!open)
            restoreValues()

        fetchData()
    }, [props.item, restoreValues, open])

    async function getFolderFiles(path) {
        if (path !== '') {
            try {
                const files = await getAllFilesFromFolder(path)
                files.push(null)
                setSelectedFiles(files)
            } catch (error) {
                LogErrors('Error fetching files:', error)
                setSelectedFiles([null])
            }
        }
    }

    async function populateCollection(data) {
        try {
            const addData = {
                businessId: data.businessId,
                color: data.color,
                icon: data.icon,
                name: data.name.trim(),
                price: data.price,
                priceTo: data.priceTo,
                time: data.time,
                imagePath: data.imagePath,
                description: data.description,
                isActive: 0,
                isDelete: 0,
                folder: data.folder === 'empty' ? '' : data.folder,
                rangeType: data.rangeType
            }

            return await addDataToCollection('business_services', addData)
        } catch (error) {
            LogErrors(error)
            return false
        }
    }

    async function handleSubmit(event) {
        event.preventDefault()
        try {
            if (props.new) {
                formData.imagePath = selectedFilesChanged ? `${formData.businessId}/${v4()}` : ''
                let result = await populateCollection(formData)
                if (result) {
                    result = selectedFilesChanged ? await uploadFiles(selectedFiles, formData.imagePath) : true
                    if (result) {
                        setOpen(false)
                        props.handleModal(successMessages.created)
                    }
                    else {
                        notifyError(errorMessages.service_images_creation_failed)
                    }
                }
                else {
                    notifyError(errorMessages.service_creation_failed)
                }
            }
            else {
                formData.imagePath = formData.imagePath === '' ? selectedFilesChanged ? `${formData.businessId}/${v4()}` : formData.imagePath : formData.imagePath
                let result = await updateDataByDocId('business_services', formData.id, removeId(formData))
                if (result) {
                    if (remoteFilesToRemove.length > 0) {
                        result = await deleteFiles(remoteFilesToRemove, props.item.imagePath)
                        if (result) {
                            result = selectedFilesChanged ? await uploadFiles(selectedFiles, formData.imagePath) : true
                            if (result) {
                                setOpen(false)
                                props.handleModal(successMessages.updated)
                            }
                            else {
                                notifyError(errorMessages.service_images_creation_failed)
                            }
                        }
                        else {
                            notifyError(errorMessages.service_images_delete_failed)
                        }
                    }
                    else {
                        result = selectedFilesChanged ? await uploadFiles(selectedFiles, formData.imagePath) : true
                        if (result) {
                            props.handleModal(successMessages.updated)
                        }
                        else {
                            notifyError(errorMessages.service_images_creation_failed)
                        }
                    }
                }
                else {
                    notifyError(errorMessages.contact_support)
                }
            }
        }
        catch (error) {
            LogErrors(error)
            notifyError(errorMessages.contact_support)
        }
    }

    function handleInputChange(event) {
        const { name, value } = event.target
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))
    }

    function handleFolderDropdown(id) {
        setFormData((prevFormData) => ({ ...prevFormData, folder: id }))
    }

    function handleIconDropdown(id) {
        setFormData((prevFormData) => ({ ...prevFormData, icon: id }))
    }

    function handleColorDropdown(id) {
        setFormData((prevFormData) => ({ ...prevFormData, color: id }))
    }

    function handleRangeTypeDropdown(type) {
        setFormData((prevFormData) => ({ ...prevFormData, rangeType: type }))
    }

    function handleFiles(files) {
        setSelectedFiles(files)
        setSelectedFilesChanged(true)
    }

    function handleRemoteFilesToDelete(file) {
        if (file != null)
            if (file.isStorage) {
                const removedFiles = [...remoteFilesToRemove]
                removedFiles.push(file)
                setRemoteFilesToRemove(removedFiles)
            }
    }

    function getAddNoFolderToFolderOptions(folders) {
        let oldFolders = [...folders]
        oldFolders.push({
            id: 'empty',
            name: content.modals.services.folder_empty_text
        })
        oldFolders.sort((a, b) => a.name.localeCompare(b.text))
        return oldFolders
    }

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={props.component}
        >
            {open &&
                <>
                    <Modal.Header id='modal-title' className='black-back-color huge-text shadow-text'>{props.new ? content.modals.services.title_new : content.modals.services.title_update}</Modal.Header>
                    <Modal.Content scrolling className='forms-box secondary-back-color'>
                        <Form onSubmit={handleSubmit}>
                            <Header id='basic-info-subtitle' inverted className='bigger-text shadow-text'>{content.modals.services.baisc_info_subtitle}</Header>
                            <Form.Field required>
                                <label className='big-text shadow-text white-text'>{content.modals.services.name_label}</label>
                                <input placeholder={content.modals.services.name_placeholder} maxLength='150' name="name" value={formData.name} onChange={handleInputChange} required />
                            </Form.Field>
                            <Form.Group widths='equal'>
                                <PriceInput formData={formData} handleInputChange={handleInputChange} handleRangeTypeDropdown={handleRangeTypeDropdown} />
                                <Form.Field required>
                                    <label className='big-text shadow-text white-text'>{content.modals.services.duration_label}</label>
                                    <input type='time' name="time" value={formData.time} onChange={handleInputChange} required />
                                </Form.Field>
                                {props.folders.length > 0 &&
                                    <Form.Field required>
                                        <label className='big-text shadow-text white-text'>{content.modals.services.folder_label}</label>
                                        <CustomDropdown data={getAddNoFolderToFolderOptions(props.folders)} hasOptions={false} text={content.modals.services.folder_label} handleDropdown={handleFolderDropdown} default={formData.folder === '' ? 'empty' : formData.folder} />
                                    </Form.Field>
                                }
                            </Form.Group>
                            <Form.Field>
                                <label className='big-text shadow-text white-text'>{content.modals.services.description_label}</label>
                                <Form.TextArea
                                    name="description"
                                    value={formData.description}
                                    onChange={handleInputChange}
                                    maxLength={500}
                                    style={{ minHeight: '200px' }}
                                    placeholder={content.modals.services.description_placeholder}
                                />
                                <div style={{ textAlign: 'right' }}>{formData.description ? formData.description.length : 0} / 500</div>
                            </Form.Field>
                            <Header id='images-subtitle' inverted className='bigger-text shadow-text'>{content.modals.services.images_subtitle}</Header>
                            <Form.Field>
                                <ImageInputGrid inputTotal={6} handleFiles={handleFiles} handleRemoteFilesToRemove={handleRemoteFilesToDelete} data={selectedFiles} />
                            </Form.Field>
                            <Header id='icon-info-subtitle' inverted className='bigger-text shadow-text'>{content.modals.services.icon_subtitle}</Header>
                            <Form.Group widths='equal'>
                                <Form.Field required>
                                    <label className='big-text shadow-text white-text'>{content.modals.services.icon_label}</label>
                                    <CustomDropdown data={getDropdownOptions(iconOptions, 'icon', true)} hasOptions={true} text={content.modals.services.icon_label} handleDropdown={handleIconDropdown} default={formData.icon} />
                                </Form.Field>
                                <Form.Field required>
                                    <label className='big-text shadow-text white-text'>{content.modals.services.color_label}</label>
                                    <CustomDropdown data={getDropdownOptions(colorOptions, 'color')} hasOptions={true} text={content.modals.services.color_label} handleDropdown={handleColorDropdown} default={formData.color} />
                                </Form.Field>
                            </Form.Group>
                            <Segment basic textAlign='center'>
                                <Header as='h3' inverted className='big-text shadow-text'>{content.modals.services.icon_preview_text}</Header>
                                <Divider />
                                <Icon size='massive' name={formData.icon} color={formData.color} />
                                <br />
                                <br />
                            </Segment>
                            <Button
                                id='modal-cancel-btn'
                                type='button'
                                circular
                                className='third-color-active-btn'
                                content={content.modals.cancel}
                                onClick={() => setOpen(false)}
                            />
                            {formData.name.length > 0 &&
                                <Button
                                    id='modal-accept-btn'
                                    circular
                                    type='submit'
                                    content={content.modals.save}
                                    labelPosition='right'
                                    icon='checkmark'
                                    className='primary-color-active-btn'
                                />
                            }
                            {!formData.name.length > 0 &&
                                <Button
                                    id='modal-accept-btn'
                                    circular
                                    type='submit'
                                    content={content.modals.save}
                                    labelPosition='right'
                                    icon='checkmark'
                                    className='primary-color-active-btn'
                                    disabled
                                />
                            }
                        </Form>
                    </Modal.Content>
                </>
            }
        </Modal>
    )
}

export default BusinessServiceModal
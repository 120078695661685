import React, { useState } from 'react'
import { Button, Grid, Header, Icon, Segment } from 'semantic-ui-react'
import { formatTime, translateContent, translateNFormatServicePrice } from '../../../resources/lib';
import AppLoader from '../../Global/AppLoader';
import BusinessServicePreviewModal from '../Modals/BusinessServicePreviewModal';
import { Link } from 'react-router-dom';

const ClientBusinessServicePreview = (props) => {
    const { data, isRestaurant } = props
    const [isVisible, setIsVisible] = useState(getAllFolders(data))

    const content = translateContent(localStorage.getItem('Language'))
    const tableFields = isRestaurant ? ['icon', 'name', 'price'] : ['icon', 'name', 'price', 'time']

    function getAllFolders(data) {
        let visibleFolders = []
        Object.keys(data).forEach(() => {
            visibleFolders.push(true)
        })
        return visibleFolders
    }

    function toggleFolder(event, index) {
        event.preventDefault()
        let tempVisibility = { ...isVisible }
        tempVisibility[index] = !tempVisibility[index]
        setIsVisible(tempVisibility)
    }

    if (!props.data)
        return <AppLoader />
    return (
        <div className='forms-medium-box'>
            <Segment basic>
                <Grid id='bussines-services-grid' columns={isRestaurant ? 3 : 4} padded>
                    {Object.keys(data).map(counter => {
                        return (
                            <React.Fragment key={counter}>
                                <Grid.Row>
                                    <Button fluid toggle icon labelPosition='right' className='third-color-active-btn' onClick={e => toggleFolder(e, counter)}>
                                        {data[counter].name === 'allServices' ? isRestaurant ? content.clientBusinessServicePreview.allMenus_folder : content.clientBusinessServicePreview.allServices_folder : data[counter].name}
                                        <Icon name='arrow alternate circle down' />
                                    </Button>
                                </Grid.Row>
                                {isVisible[counter] &&
                                    (data[counter].services.map((item, index) => (
                                        <Grid.Row key={index}>
                                            {tableFields.map((field, fieldIndex) => {
                                                if (field === 'name') {
                                                    return (
                                                        <Grid.Column mobile={16} computer={isRestaurant ? 6 : 4} key={fieldIndex}>
                                                            <BusinessServicePreviewModal
                                                                component={<Header><Link className='table-link'>{item[field]}</Link></Header>}
                                                                data={item}
                                                                isRestaurant={isRestaurant}
                                                            />
                                                        </Grid.Column>
                                                    )
                                                } else if (field === 'icon') {
                                                    return (
                                                        <Grid.Column mobile={16} computer={isRestaurant ? 4 : 2} key={fieldIndex}>
                                                            <Header><Icon name={item[field]} color={item.color} /></Header>
                                                        </Grid.Column>
                                                    )
                                                } else if (field === 'time') {
                                                    return (
                                                        <Grid.Column mobile={16} computer={6} key={fieldIndex}>
                                                            <Header inverted className='shadow-text'>{formatTime(item[field])}</Header>
                                                        </Grid.Column>
                                                    )
                                                } else {
                                                    return (
                                                        <Grid.Column mobile={16} computer={isRestaurant ? 6 : 4} key={fieldIndex}>
                                                            <Header inverted className='shadow-text'>{item[field] > 0 ? `${translateNFormatServicePrice(item)}€` : content.clientBusinessServicePreview.free_text}</Header>
                                                        </Grid.Column>
                                                    )
                                                }
                                            })}
                                        </Grid.Row>
                                    ))
                                    )
                                }
                            </React.Fragment>
                        )
                    })}
                </Grid>
            </Segment>
        </div>
    )
}

export default ClientBusinessServicePreview
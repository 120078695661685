import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Icon, Table } from 'semantic-ui-react';
import { getPMethodEmptyObject, notifyError, notifySuccess, translateContent, translateErrorMessages, translatePMethods, translateSuccessMessages } from '../../../resources/lib';
import AppLoader from '../../Global/AppLoader';
import ConfirmModal from '../Modals/ConfirmModal'
import { deleteDocById } from '../../../resources/firebaseLib';
import BusinessPMethodsModal from '../Modals/BusinessPMethodsModal';

const PaymentMethods = (props) => {
    const content = translateContent(localStorage.getItem('Language'))
    const errorMessages = translateErrorMessages(localStorage.getItem('Language'))
    const successMessages = translateSuccessMessages(localStorage.getItem('Language'))

    async function handleDelete(item) {
        const result = await deleteDocById('business_payment_methods', item.id)
        if (result) {
            notifySuccess(successMessages.pMethod_deleted)
            props.getPMethods(props.businessId)
        }
        else {
            notifyError(errorMessages.contact_support)
        }
    }

    async function handleClick(type, item) {
        if (type)
            await handleDelete(item)
    }

    function handleModal(message) {
        props.getPMethods(props.businessId)
        notifySuccess(message)
    }

    const tableHeaders = content.pMethods.table_headers_text
    const tableFields = content.pMethods.table_headers_values

    if (!props.data || !props.names)
        return <AppLoader />
    return (
        <Container>
            <Table id='pmethods-table' celled stackable selectable textAlign='center' className='no-border shadow-text'>
                <Table.Header>
                    <Table.Row className='table-header-bg'>
                        {
                            tableHeaders.map((item) => (
                                <Table.HeaderCell key={item}>{item}</Table.HeaderCell>
                            ))
                        }
                    </Table.Row>
                </Table.Header>
                <Table.Body className='table-body-bg'>
                    {props.data.length === 0 &&
                        <Table.Row>
                            <Table.Cell colSpan='3'>{content.nothing_to_show}</Table.Cell>
                        </Table.Row>
                    }
                    {props.data.map((item, index) => (
                        <Table.Row key={index}>
                            {tableFields.map((field) => (
                                <Table.Cell key={field}>{translatePMethods(item[field])}</Table.Cell>
                            ))
                            }
                            <Table.Cell>
                                <ConfirmModal item={item} handleClick={handleClick} action={`${content.pMethods.delete_action} ${translatePMethods(item.name)}?`}
                                    component={<Icon title={content.delete} size='large' color='red' name='delete' />} />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            {props.data.length !== props.names.length &&
                <BusinessPMethodsModal handleModal={handleModal} item={getPMethodEmptyObject(props.businessId)} data={props.data} names={props.names} component={<Button id='add-pmethods-btn' circular size='big' as={Link} className='primary-color-btn'><Icon name='add' /> {content.pMethods.button}</Button>} />
            }
        </Container>
    )
}

export default PaymentMethods
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import { LogErrors, notifyError, removeId, translateContent, translateErrorMessages, translateSuccessMessages } from '../../../resources/lib';
import { addDataToCollection, updateDataByDocId } from '../../../resources/firebaseLib';

const BusinessFoldersModal = (props) => {
    const [open, setOpen] = useState(false)
    const [formData, setFormData] = useState({ ...props.item })

    const content = translateContent(localStorage.getItem('Language'))
    const errorMessages = translateErrorMessages(localStorage.getItem('Language'))
    const successMessages = translateSuccessMessages(localStorage.getItem('Language'))

    const restoreValues = useCallback(async () => {
        setFormData(props.item)
    }, [props.item])

    useEffect(() => {
        if (!open)
            restoreValues()
    }, [restoreValues, open])

    async function populateCollection(data) {
        try {
            const addData = {
                businessId: data.businessId,
                name: data.name.trim()
            }

            return await addDataToCollection(props.isRestaurant ? 'business_menus_folders' : 'business_folders', addData)
        } catch (error) {
            LogErrors(error)
            return false
        }
    }

    async function handleSubmit(event) {
        event.preventDefault()
        if (props.new) {
            const result = await populateCollection(formData)
            if (result) {
                setOpen(false)
                props.handleModal(successMessages.created)
            }
            else {
                notifyError(errorMessages.contact_support)
            }
        }
        else {
            const result = await updateDataByDocId(props.isRestaurant ? 'business_menus_folders' : 'business_folders', formData.id, removeId(formData))
            if (result) {
                setOpen(false)
                props.handleModal(successMessages.updated)
            }
            else {
                notifyError(errorMessages.contact_support)
            }
        }
    }

    function handleInputChange(event) {
        const { name, value } = event.target
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))
    }

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={props.component}
        >
            {open &&
                <>
                    <Modal.Header id='modal-title' className='black-back-color bigger-text shadow-text'>{
                        props.isRestaurant ? (props.new ? content.modals.folders.title_new_menus : content.modals.folders.title_update_menus) :
                            (props.new ? content.modals.folders.title_new_services : content.modals.folders.title_update_services)
                    }</Modal.Header>
                    <Modal.Content className='forms-box secondary-back-color'>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group widths='equal'>
                                <Form.Field required>
                                    <label className='big-text shadow-text brown-text'>{content.modals.folders.name_label}</label>
                                    <input placeholder={content.modals.folders.name_label} name="name" maxLength='50' value={formData.name} onChange={handleInputChange} required />
                                </Form.Field>
                            </Form.Group>
                            <Button
                                id='modal-cancel-btn'
                                type='button'
                                circular
                                className='third-color-active-btn'
                                onClick={() => setOpen(false)}>
                                {content.modals.cancel}
                            </Button>
                            {formData.name === '' &&
                                <Button
                                    id='modal-accept-btn'
                                    circular
                                    type='submit'
                                    content={content.modals.save}
                                    labelPosition='right'
                                    icon='checkmark'
                                    className='primary-color-active-btn'
                                    disabled
                                />
                            }
                            {formData.name !== '' &&
                                <Button
                                    id='modal-accept-btn'
                                    circular
                                    type='submit'
                                    content="Gravar"
                                    labelPosition='right'
                                    icon='checkmark'
                                    className='primary-color-active-btn'
                                />
                            }
                        </Form>
                    </Modal.Content>
                </>
            }
        </Modal>
    )
}

export default BusinessFoldersModal
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'semantic-ui-react';
import { LogErrors, capitalizeWord, notifyError, removeId, translateContent, translateErrorMessages, translateSuccessMessages } from '../../../resources/lib';
import CustomDropdown from '../../Global/Dropdown/Dropdown';
import { addDataToCollection, updateDataByDocId } from '../../../resources/firebaseLib';

const BusinessSocialsModal = (props) => {
    const [open, setOpen] = useState(false)
    const [formData, setFormData] = useState({ ...props.item })

    const content = translateContent(localStorage.getItem('Language'))
    const errorMessages = translateErrorMessages(localStorage.getItem('Language'))
    const successMessages = translateSuccessMessages(localStorage.getItem('Language'))

    const restoreValues = useCallback(async () => {
        setFormData(props.item)
    }, [props.item])

    useEffect(() => {
        if (!open)
            restoreValues()
    }, [restoreValues, open])

    async function populateCollection(data) {
        try {
            return await addDataToCollection('business_socials', data)
        } catch (error) {
            LogErrors(error)
            return false
        }
    }

    async function handleSubmit(event) {
        event.preventDefault()
        if (props.new) {
            const result = await populateCollection(formData)
            if (result) {
                setOpen(false)
                props.handleModal(successMessages.created)
            }
            else {
                notifyError(errorMessages.contact_support)
            }
        }
        else {
            const result = await updateDataByDocId('business_socials', formData.id, removeId(formData))
            if (result) {
                setOpen(false)
                props.handleModal(successMessages.updated)
            }
            else {
                notifyError(errorMessages.contact_support)
            }
        }
    }

    function handleDropdown(id) {
        const foundObject = props.names.find(item => item.id === id);
        const item = foundObject ? foundObject.name : null;
        setFormData((prevFormData) => ({ ...prevFormData, name: item }))
    }

    function handleInputChange(event) {
        const { name, value } = event.target
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value.trim() }))
    }

    function getDropdownValues(dataList, namesList) {
        let newNamesList = [...namesList]
        dataList.forEach(element => {
            const data = namesList.find(item => item.name === element.name)
            if (data !== null)
                newNamesList = newNamesList.filter(item => item.name !== data.name)
        })
        return newNamesList
    }

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={props.component}
        >
            {open &&
                <>
                    <Modal.Header id='modal-title' className='black-back-color bigger-text shadow-text'>{props.new ? content.modals.socials.title_new : content.modals.socials.title_update}</Modal.Header>
                    <Modal.Content className='forms-box secondary-back-color'>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group widths='equal'>
                                {props.new &&
                                    <Form.Field required>
                                        <label className='big-text shadow-text brown-text'>{content.modals.socials.social_label}</label>
                                        <CustomDropdown data={getDropdownValues(props.data, props.names)} text={content.modals.socials.social_drop_default} handleDropdown={handleDropdown} required />
                                    </Form.Field>
                                }
                                {!props.new &&
                                    <Form.Field required>
                                        <label className='big-text shadow-text brown-text'>{content.modals.socials.social_label}</label>
                                        <label id='social-name' className='big-text shadow-text white-text'>{capitalizeWord(formData.name)}</label>
                                    </Form.Field>
                                }
                                <Form.Field required>
                                    <label className='big-text shadow-text brown-text'>{content.modals.socials.link_label}</label>
                                    <input type='url' placeholder={content.modals.socials.link_placeholder} name="url" title={content.modals.socials.link_placeholder} value={formData.url} onChange={handleInputChange} required />
                                </Form.Field>
                            </Form.Group>
                            <Button
                                id='modal-cancel-btn'
                                type='button'
                                circular
                                className='third-color-active-btn'
                                onClick={() => setOpen(false)}>
                                {content.modals.cancel}
                            </Button>
                            {formData.name === '' &&
                                <Button
                                    id='modal-accept-btn'
                                    circular
                                    type='submit'
                                    content={content.modals.save}
                                    labelPosition='right'
                                    icon='checkmark'
                                    className='primary-color-active-btn'
                                    disabled
                                />
                            }
                            {formData.name !== '' &&
                                <Button
                                    id='modal-accept-btn'
                                    circular
                                    type='submit'
                                    content="Gravar"
                                    labelPosition='right'
                                    icon='checkmark'
                                    className='primary-color-active-btn'
                                />
                            }
                        </Form>
                    </Modal.Content>
                </>
            }
        </Modal>
    )
}

export default BusinessSocialsModal
import { useEffect, useState } from 'react';
import { Button, Container, Grid, Header, Icon, Image, Segment } from 'semantic-ui-react';

const ImageCarousel = (props) => {
  const width = 768
  const [activeIndex, setActiveIndex] = useState(0)
  const [isHidden, setIsHidden] = useState(window.innerWidth > width)

  useEffect(() => {
    const interval = setInterval(() => {
      if (activeIndex !== props.carouselItems.length - 1)
        setActiveIndex((activeIndex + 1) % props.carouselItems.length)
      else
        setActiveIndex(0)

    }, 8000)

    return () => clearInterval(interval)
  }, [activeIndex, props])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > width) {
        setIsHidden(true)
      } else {
        setIsHidden(false)
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [width])

  const handleNext = () => {
    setActiveIndex((activeIndex + 1) % props.carouselItems.length)
  }

  const handlePrev = () => {
    setActiveIndex(activeIndex === 0 ? props.carouselItems.length - 1 : activeIndex - 1)
  }

  if (!isHidden)
    return (
      <Container id='container-no-margin' className='divider-container container-no-padding' fluid>
        <Grid id='container-no-margin' columns='three'>
          <Grid.Row>
            <Grid.Column width={2}>
            </Grid.Column>
            <Grid.Column width={12}>
              <Image size='big' src={props.carouselItems[activeIndex].src} rounded style={{ margin: 'auto' }} />
            </Grid.Column>
            <Grid.Column width={2}>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={3} onClick={handlePrev}>
              <Button circular secondary icon="angle left" />
            </Grid.Column>
            <Grid.Column width={10}>
              <Header as='h2' className='primary-text-color shadow-text'>{props.carouselData.headers[activeIndex]}</Header>
              <Header inverted as='h3' className='shadow-text'>{props.carouselData.descriptions[activeIndex]}</Header>
            </Grid.Column>
            <Grid.Column width={3} onClick={handleNext}>
              <Button circular secondary icon="angle right" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br />
      </Container>
    )

  return (
    <Container fluid style={{ backgroundImage: `linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.5)), url(${props.carouselItems[activeIndex].src})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '65vh' }}>
      <Grid textAlign='center' verticalAlign='middle' style={{ height: '100%' }}>
        <Grid.Row>
          <Grid.Column width={2}>
            <Icon name='angle left' size='big' link onClick={handlePrev} />
          </Grid.Column>
          <Grid.Column width={12}>
            <Segment basic compact style={{ margin: 'auto' }}>
              <Header as='h2' inverted className='carousel-title'>
                {props.carouselData.headers[activeIndex]}
              </Header>
              <Header as='h3' inverted className='carousel-description'>
                {props.carouselData.descriptions[activeIndex]}
              </Header>
            </Segment>
          </Grid.Column>
          <Grid.Column width={2}>
            <Icon name='angle right' size='big' link onClick={handleNext} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  )
}

export default ImageCarousel

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Image, Menu } from 'semantic-ui-react';
import logo from '../../../resources/logo.png';
import { translateContent } from '../../../resources/lib';

const BusinessNavBar = () => {
    const [menu, setActiveItem] = useState({ activeItem: '' })

    const content = translateContent(localStorage.getItem('Language'))

    function handleItemClick(event, { name }) {
        setActiveItem((prevFormData) => ({ ...prevFormData, activeItem: name }))
    }

    return (
        <Menu.Menu position='left'>
            <Menu.Item
                id='home'
                name='home'
                active={menu.activeItem === 'home'}
                onClick={handleItemClick}
                as={Link}
                to='/'
            >
                <Image src={logo} size='medium' />
            </Menu.Item>

            <Menu.Item
                id='terms'
                name='terms'
                active={menu.activeItem === 'terms'}
                onClick={handleItemClick}
                as={Link}
                to='/terms'
            >
                <Icon name='file' />
                {content.menu.terms}
            </Menu.Item>

            <Menu.Item
                id='guide'
                name='guide'
                active={menu.activeItem === 'guide'}
                onClick={handleItemClick}
                as={Link}
                to='/guide'
            >
                <Icon name='find' />
                {content.menu.guide}
            </Menu.Item>

            <Menu.Item
                id='business'
                name='business'
                active={menu.activeItem === 'business'}
                onClick={handleItemClick}
                as={Link}
                to='/business'
            >
                <Icon name='warehouse' />
                {content.menu.businesses}
            </Menu.Item>
        </Menu.Menu>
    )
}

export default BusinessNavBar
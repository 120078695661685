import React, { useState } from 'react'
import { Button, Container, Grid, Header, Modal } from 'semantic-ui-react'
import { formatCategoriesNames, translateCategoriesString, translateContent } from '../../../resources/lib'
import CopyLink from '../../Global/CopyLink'

const BusinessPreviewModal = (props) => {
    const { data } = props
    const [open, setOpen] = useState(false)

    const content = translateContent(localStorage.getItem('Language'))

    function handleClick(event) {
        setOpen(false)
    }

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={props.component}
        >
            {open &&
                <>
                    <Modal.Header id='modal-title' className='black-back-color bigger-text shadow-text'>{content.modals.business_preview.title} - {data.name}</Modal.Header>
                    <Modal.Content scrolling className='forms-box secondary-back-color'>
                        <Container text fluid>
                            <Grid padded>
                                <Grid.Row>
                                    <Grid.Column mobile={16} computer={8}>
                                        <Header className='bigger-text shadow-text brown-text'>{content.modals.business.name_label}</Header>
                                        <p className='big-text shadow-text white-text'>{data.name}</p>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} computer={8}>
                                        <Header className='bigger-text shadow-text brown-text'>{content.modals.business.categories_label}</Header>
                                        <p className='big-text shadow-text white-text'>{translateCategoriesString(localStorage.getItem('Language'), formatCategoriesNames(data.categoriesNames))}</p>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column mobile={16} computer={8}>
                                        <Header className='bigger-text shadow-text brown-text'>{content.modals.business.email_label}</Header>
                                        <p className='big-text shadow-text white-text'>{data.email}</p>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} computer={8}>
                                        <Header className='bigger-text shadow-text brown-text'>{content.modals.business.contact_label}</Header>
                                        <p className='big-text shadow-text white-text'>{data.contact}</p>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column mobile={16} computer={16}>
                                        <Header className='bigger-text shadow-text brown-text'>{content.modals.business.address_label}</Header>
                                        <p className='big-text shadow-text white-text'>{data.address}</p>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column mobile={16} computer={8}>
                                        <Header className='bigger-text shadow-text brown-text'>{content.modals.business.city_label}</Header>
                                        <p className='big-text shadow-text white-text'>{data.city}</p>
                                    </Grid.Column>
                                    <Grid.Column mobile={16} computer={8}>
                                        <Header className='bigger-text shadow-text brown-text'>{content.modals.business.postal_label}</Header>
                                        <p className='big-text shadow-text white-text'>{data.postalCode}</p>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column mobile={16} computer={16}>
                                        <Header className='bigger-text shadow-text brown-text'>{content.modals.business.website_label}</Header>
                                        {data.website !== '' ?
                                            <CopyLink
                                                link={data.website}
                                                text={data.website}
                                                isDark={true} /> :
                                            <p className='big-text shadow-text white-text'>{content.modals.business_preview.without_website_text}</p>
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Container>
                    </Modal.Content>
                    <Modal.Actions className='secondary-back-color'>
                        <Button circular
                            content={content.modals.business_preview.button}
                            labelPosition='right'
                            icon='checkmark'
                            onClick={(e) => handleClick(e)}
                            className='third-color-active-btn'
                        />
                    </Modal.Actions>
                </>
            }
        </Modal>
    )
}

export default BusinessPreviewModal
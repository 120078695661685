import React from 'react';
import { signOut } from 'firebase/auth'
import { auth } from '../../../firebase';
import { Button, Container, Form, Header, Segment } from 'semantic-ui-react';
import { handleReload, notifyError, decrytptString, readValueInObjectInLocalStorage, destroyStorage, readLocalStorageUserValue, translateErrorMessages, translateContent } from '../../../resources/lib';
import { deleteAccount } from '../../../resources/firebaseLib';
import Breadcrumbs from '../../Global/Breadcrumbs';

const DeleteAccount = () => {
    const content = translateContent(localStorage.getItem('Language'))
    const errorMessages = translateErrorMessages(localStorage.getItem('Language'))

    async function handleSubmit(event) {
        event.preventDefault()
        const type = decrytptString(readValueInObjectInLocalStorage('Type', 'type')) === 'client' ? 'user' : 'business'
        const result = await deleteAccount(decrytptString(readLocalStorageUserValue('token')), type)
        if (result) {
            const isClient = type === 'client'
            destroyStorage()
            signOut(auth)
            window.location.pathname = isClient ? '/' : '/home/business'
            handleReload()
        }
        else {
            notifyError(errorMessages.contact_support)
        }
    }

    return (
        <Container fluid>
            <br />
            <Breadcrumbs list={[content.my_account.title, content.my_account.delete_account.title]} />
            <Container>
                <div className='my-acc-header'>
                    <Segment basic>
                        <Header inverted className='huge-text shadow-text' as='h1'>{content.my_account.delete_account.title}</Header>
                    </Segment>
                </div>
                <div className='forms-tiny-box'>
                    <Segment basic>
                        <Form size='big'>
                            <Button circular size='large' className='secondary-color-btn' onClick={handleSubmit}>{content.my_account.delete_account.button}</Button>
                        </Form>
                    </Segment>
                </div>
            </Container>
        </Container>
    )
}

export default DeleteAccount
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Form, Modal } from 'semantic-ui-react';
import { LogErrors, formatCities, getCurrentDateTime, getNamesFromIds, notifyError, readLocalStorageUserValue, removeId, supportEmail, translateContent, translateEmailTextNewBusiness, translateEmailTitles, translateErrorMessages, translateSuccessMessages, validateBusinessObject } from '../../../resources/lib';
import CustomMultiDropdown from '../../Global/Dropdown/MultiDropdown';
import { addDataToCollection, createEmail, getAllDocuments, getDataBy, updateDataByDocId } from '../../../resources/firebaseLib';
import CustomDropdown from '../../Global/Dropdown/Dropdown';

const BusinessModal = (props) => {
    const [open, setOpen] = useState(false)
    const [formData, setFormData] = useState({ ...props.item })
    const [categoryData, setCategoryData] = useState(null)
    const [checked, setChecked] = useState(false)

    const content = translateContent(localStorage.getItem('Language'))
    const errorMessages = translateErrorMessages(localStorage.getItem('Language'))
    const successMessages = translateSuccessMessages(localStorage.getItem('Language'))

    const restoreValues = useCallback(async () => {
        setFormData(props.item)
    }, [props.item])

    useEffect(() => {
        async function fetchData() {
            if (localStorage.getItem('UserInfo')) {
                const data = await getAllDocuments('category')
                setCategoryData(data)
            }
            else
                setCategoryData([])
        }

        if (!open)
            restoreValues()

        fetchData()
    }, [restoreValues, open])

    async function populateCollection(data) {
        try {
            const addData = {
                ownerUid: data.ownerUid,
                name: data.name.trim(),
                email: data.email.trim(),
                contact: data.contact,
                address: data.address.trim(),
                city: data.city,
                postalCode: data.postalCode,
                categories: data.categories,
                categoriesNames: data.categoriesNames,
                isRestaurant: data.isRestaurant,
                website: data.website.trim(),
                isActive: 1,
                isDelete: 0,
                creationDate: getCurrentDateTime()
            }

            return await addDataToCollection('business', addData)
        } catch (error) {
            LogErrors(error)
            return false
        }
    }

    async function handleSubmit(event) {
        event.preventDefault()
        if (props.new) {
            let result = await getDataBy('business', 'name', formData.name)
            if (result.length > 0) {
                notifyError(errorMessages.business_error)
            }
            else {
                result = await populateCollection(formData)
                if (result) {
                    result = result = await createEmail(supportEmail, translateEmailTitles('businessCreated'), translateEmailTextNewBusiness(
                        {
                            businessName: formData.name,
                            firstName: readLocalStorageUserValue('firstName'),
                            lastName: readLocalStorageUserValue('lastName'),
                            email: formData.email
                        }
                    ))
                    if (result) {
                        setOpen(false)
                        props.handleModal(successMessages.created)
                    }
                    else {
                        notifyError(errorMessages.contact_support)
                    }
                }
                else {
                    notifyError(errorMessages.contact_support)
                }
            }
        }
        else {
            const result = await updateDataByDocId('business', formData.id, removeId(formData))
            if (result) {
                setOpen(false)
                props.handleModal(successMessages.updated)
            }
            else {
                notifyError(errorMessages.contact_support)
            }
        }
    }

    function handleInputChange(event) {
        const { name, value } = event.target
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))
    }

    function handleMultiDropdown(categories) {
        setFormData((prevFormData) => ({ ...prevFormData, categories: categories.sort().join() }))
        setFormData((prevFormData) => ({ ...prevFormData, categoriesNames: getNamesFromIds(categories.sort(), categoryData) }))
    }

    function handleDropdown(id) {
        setFormData((prevFormData) => ({ ...prevFormData, city: id }))
    }

    const handleCheckboxChange = () => {
        setChecked(!checked)
        setFormData((prevFormData) => ({ ...prevFormData, isRestaurant: !checked, categories: '', categoriesNames: '' }))
    }

    if (!categoryData)
        return <></>

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={props.component}
        >

            {open &&
                <>
                    <Modal.Header id='modal-title' className='black-back-color bigger-text shadow-text'>{props.new ? content.modals.business.title_new : content.modals.business.title_update}</Modal.Header>
                    <Modal.Content scrolling className='forms-box secondary-back-color'>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group widths='equal'>
                                <Form.Field required>
                                    <label className='big-text shadow-text brown-text'>{content.modals.business.name_label}</label>
                                    <input placeholder={content.modals.business.name_placeholder} name="name" maxLength='50' value={formData.name} onChange={handleInputChange} required />
                                </Form.Field>
                                <Form.Field required>
                                    <label className='big-text shadow-text brown-text'>{content.modals.business.categories_label}</label>
                                    {!checked &&
                                        <CustomMultiDropdown isCategory={true} data={categoryData} text={content.modals.business.categories_drop_default} handleDropdown={handleMultiDropdown}
                                            default={formData.categories ? formData.categories.split(',') : null} />
                                    }
                                    <Checkbox
                                        className='medium-text shadow-text brown-text'
                                        label='A sua empresa pertence à Categoria da Restauração?'
                                        checked={checked}
                                        onChange={handleCheckboxChange}
                                    />
                                </Form.Field>
                            </Form.Group>
                            <Form.Group widths='equal'>
                                <Form.Field required>
                                    <label className='big-text shadow-text brown-text'>{content.modals.business.email_label}</label>
                                    <input type='email' placeholder={content.modals.business.email_placeholder} name="email" maxLength='100' value={formData.email} onChange={handleInputChange} required />
                                </Form.Field>
                                <Form.Field required>
                                    <label className='big-text shadow-text brown-text'>{content.modals.business.contact_label}</label>
                                    <input title='ex: 961234567 or 221123456' placeholder={content.modals.business.contact_placeholder} name="contact" maxLength='9' pattern='[0-9]{9}' value={formData.contact} onChange={handleInputChange} required />
                                </Form.Field>
                            </Form.Group>
                            <Form.Field>
                                <label className='big-text shadow-text brown-text'>{content.modals.business.address_label}</label>
                                <input placeholder={content.modals.business.address_placeholder} name='address' maxLength='70' value={formData.address} onChange={handleInputChange} />
                            </Form.Field>
                            <Form.Group widths='equal'>
                                <Form.Field required>
                                    <label className='big-text shadow-text brown-text'>{content.modals.business.city_label}</label>
                                    <CustomDropdown data={formatCities()} text={content.modals.business.city_label} handleDropdown={handleDropdown} default={formData.city} />
                                </Form.Field>
                                <Form.Field>
                                    <label className='big-text shadow-text brown-text'>{content.modals.business.postal_label}</label>
                                    <input title='ex: 8700-000' placeholder={content.modals.business.postal_placeholder} name="postalCode" maxLength='8' pattern='[0-9]{4}-[0-9]{3}' value={formData.postalCode} onChange={handleInputChange} />
                                </Form.Field>
                            </Form.Group>
                            <Form.Field>
                                <label className='big-text shadow-text brown-text'>{content.modals.business.website_label}</label>
                                <input title='ex: http://www.google.com/ ou https://www.google.com/' type='url' placeholder={content.modals.business.website_placeholder} name="website" maxLength='80' value={formData.website} onChange={handleInputChange} />
                            </Form.Field>
                            <Button
                                id='modal-cancel-btn'
                                type='button'
                                circular
                                className='third-color-active-btn'
                                onClick={() => setOpen(false)}>
                                {content.modals.cancel}
                            </Button>
                            {(validateBusinessObject(formData)) &&
                                <Button circular
                                    id='modal-accept-btn'
                                    type='submit'
                                    content={content.modals.save}
                                    labelPosition='right'
                                    icon='checkmark'
                                    className='primary-color-active-btn'
                                />
                            }
                            {!(validateBusinessObject(formData)) &&
                                <Button circular
                                    id='modal-accept-btn'
                                    type='submit'
                                    content={content.modals.save}
                                    labelPosition='right'
                                    icon='checkmark'
                                    className='primary-color-active-btn'
                                    disabled
                                />
                            }
                        </Form>
                    </Modal.Content>
                </>
            }
        </Modal>
    )
}

export default BusinessModal
import React from 'react'
import { Container, Dimmer, Loader, Segment } from 'semantic-ui-react'

const AppLoader = () => {
    return (
        <Container className='full-view'>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Segment basic className='loader-margin'>
                <Dimmer active className='loader-dimmer-transp'>
                    <Loader indeterminate size='massive'>Loading</Loader>
                </Dimmer>
            </Segment>
        </Container>
    )
}

export default AppLoader
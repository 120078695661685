import React from 'react';
import { Icon } from 'semantic-ui-react';
import { translateContent } from '../../../../resources/lib';

const StarRatingWithText = (props) => {
    const content = translateContent(localStorage.getItem('Language'))

    function formatValues(lang, value) {
        switch (lang) {
            case 'EN': return value
            default: return String(value).replace('.', ',')
        }
    }

    return (
        <div style={{ position: 'relative', display: 'inline-block', fontSize: '24px' }}>
            <Icon className='third-color-icon-star shadow-text' name='star' size='huge' title={content.start_component.title} />
            <span style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                color: 'white',
                fontWeight: 'bold'
            }}>
                {formatValues(localStorage.getItem('Language'), props.rating)}
            </span>
        </div>
    )
}

export default StarRatingWithText
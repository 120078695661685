import React, { useEffect, useState } from 'react';
import { Button, Container, Header, Icon, Segment, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { formatCategoriesNames, getBusinessEmptyObject, notifyError, notifySuccess, removeId, translateCategoriesString, translateContent, translateErrorMessages, translateSuccessMessages } from '../../../resources/lib';
import BusinessModal from '../Modals/BusinessModal';
import ConfirmModal from '../Modals/ConfirmModal';
import CopyLink from '../../Global/CopyLink';
import { updateDataByDocId } from '../../../resources/firebaseLib';
import BusinessPreviewModal from '../Modals/BusinessPreviewModal';

const Business = (props) => {
    const width = 768
    const [isHidden, setIsHidden] = useState(window.innerWidth > width)
    const content = translateContent(localStorage.getItem('Language'))
    const errorMessages = translateErrorMessages(localStorage.getItem('Language'))
    const successMessages = translateSuccessMessages(localStorage.getItem('Language'))

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > width) {
                setIsHidden(true)
            } else {
                setIsHidden(false)
            }
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [width])

    function handleStatus(status) {
        if (Number(status) === 0)
            return <Icon size='big' color='red' name='remove circle' />
        else
            return <Icon size='big' color='green' name='check circle' />
    }

    async function handleDelete(item) {
        item.isDelete = 1
        const result = await updateDataByDocId('business', item.id, removeId(item))
        if (result) {
            props.getBusiness()
            notifySuccess(successMessages.business_deleted)
        }
        else {
            notifyError(errorMessages.contact_support)
        }
    }

    async function handleClick(type, item) {
        if (type)
            await handleDelete(item)
    }

    function getBaseUrl() {
        const url = window.location.href
        const parts = url.split("/");
        return `${parts[0]}//${parts[2]}`
    }

    function formatName(name) {
        const formatName = name.replaceAll(" ", '-')
        return formatName
    }

    function handleModal(message) {
        props.getBusiness(props.data[0].id)
        notifySuccess(message)
    }

    let tableHeaders = isHidden ? content.businesses.table_headers_text : content.businesses.table_short_headers_text
    let tableFields = isHidden ? content.businesses.table_headers_values : content.businesses.table_short_headers_values
    if (props.isPreview) {
        tableHeaders = isHidden ? content.businesses.table_headers_preview_text : content.businesses.table_short_headers_preview_text
        tableFields = isHidden ? content.businesses.table_headers_preview_values : content.businesses.table_short_headers_preview_values
    }

    if (!props.data)
        return <></>

    if (props.data.length > 0)
        return (
            <div className='tables-box-large'>
                <div className='scrollable-table'>
                    <Table id='business-table' className='text-big no-border shadow-text' celled stackable selectable textAlign='center'>
                        <Table.Header>
                            <Table.Row className='table-header-bg'>
                                {
                                    tableHeaders.map((item) => (
                                        <Table.HeaderCell key={item}>{item}</Table.HeaderCell>
                                    ))
                                }
                            </Table.Row>
                        </Table.Header>
                        <Table.Body className='table-body-bg'>
                            {props.data.length === 0 &&
                                <Table.Row>
                                    <Table.Cell colSpan='10'>{content.nothing_to_show}</Table.Cell>
                                </Table.Row>
                            }
                            {props.data.map((item, index) => (
                                <Table.Row key={index}>
                                    {tableFields.map((field) => (
                                        field === 'name' ? (
                                            props.isPreview ? (
                                                <Table.Cell key={field}>{item[field]}</Table.Cell>
                                            ) : !item.isActive ? (
                                                <Table.Cell key={field}>{item[field]}</Table.Cell>
                                            ) : (
                                                <Table.Cell key={field}><Link className='table-link' to={`/business/prefs/${item.id}`}>{item[field]}</Link></Table.Cell>
                                            )
                                        ) : (
                                            field === 'isActive' ? (
                                                <Table.Cell key={field}>{handleStatus(item[field])}</Table.Cell>
                                            ) : (
                                                field === 'categories' ? (
                                                    <Table.Cell key={field}>{translateCategoriesString(localStorage.getItem('Language'), formatCategoriesNames(item['categoriesNames']), item['isRestaurant'])}</Table.Cell>
                                                ) : (
                                                    field === 'link' ? (
                                                        (props.hasTimes && props.hasServices) ? (
                                                            <Table.Cell key={field}>
                                                                <CopyLink
                                                                    link={`${getBaseUrl()}/${formatName(item.name)}`}
                                                                    text={content.businesses.link_text} />
                                                            </Table.Cell>
                                                        ) : (
                                                            <Table.Cell key={field}>{item['isRestaurant'] ? content.businesses.link_warning_menus : content.businesses.link_warning_services}</Table.Cell>
                                                        )
                                                    ) : (
                                                        field === 'website' ? (
                                                            (item[field] !== '') ? (
                                                                <Table.Cell key={field}>
                                                                    <CopyLink
                                                                        link={item[field]}
                                                                        text={content.businesses.link_text} />
                                                                </Table.Cell>
                                                            ) : (
                                                                <Table.Cell key={field}>-</Table.Cell>
                                                            )
                                                        ) : (
                                                            item[field] === '' ? (
                                                                <Table.Cell key={field}>-</Table.Cell>
                                                            ) : (
                                                                <Table.Cell key={field}>{item[field]}</Table.Cell>
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    ))
                                    }
                                    <Table.Cell>
                                        <div className='icon-grid' >
                                            {!isHidden &&
                                                <div>
                                                    <BusinessPreviewModal
                                                        component={<Icon title={content.view} size='large' color='blue' name='eye' />}
                                                        data={item}
                                                    />
                                                </div>
                                            }
                                            <div>
                                                <BusinessModal handleModal={handleModal} item={item} component={<Icon title={content.edit} size='large' color='yellow' name='edit' />} />
                                            </div>
                                            {!props.isPreview &&
                                                <div>
                                                    <ConfirmModal item={item} handleClick={handleClick} action={`${content.businesses.delete_action} '${item.name}'?`}
                                                        component={<Icon title={content.delete} size='large' color='red' name='delete' />} />
                                                </div>
                                            }
                                        </div>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
                <br />
                {!props.isPreview &&
                    <BusinessModal handleModal={handleModal} new={true} item={getBusinessEmptyObject()} component={
                        <Button id='add-business-btn' circular size='big' as={Link} className='primary-color-btn'><Icon name='add' /> {content.businesses.button}</Button>
                    } />
                }
            </div>
        )
    else
        return (
            <Container>
                <Segment basic textAlign='center'>
                    <Segment inverted className='third-back-color'>
                        <Container className="section-box">
                            <div className="notice-box">
                                <Header id='message-header' className='huge-text shadow-text' as='h1' inverted>{content.businesses.create_first_business_title}</Header>
                            </div>
                            <div className="notice-inside-box secondary-back-color">
                                <Button id='add-business-btn' circular size='big' as={Link} to={`/new/business`} className='primary-color-btn'><Icon name='plus' />{content.businesses.button}</Button>
                                <Header id='message' inverted as='h2'>{content.businesses.create_first_business_info}</Header>
                            </div>
                        </Container>
                    </Segment>
                </Segment>
            </Container>
        )
}

export default Business
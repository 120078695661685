import React, { useState } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { translateContent } from '../../../resources/lib'

const ConfirmModal = (props) => {
    const [open, setOpen] = useState(false)

    const content = translateContent(localStorage.getItem('Language'))

    function handleClick(event, type) {
        setOpen(false)
        props.handleClick(type, props.item)
    }

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={props.component}
        >
            {open &&
                <>
                    <Modal.Header className='black-back-color bigger-text white-text shadow-text'>{props.action}</Modal.Header>
                    <Modal.Actions className='secondary-back-color'>
                        <Button
                            circular
                            id='modal-cancel-btn'
                            className='third-color-active-btn'
                            onClick={(e) => handleClick(e, false)}>
                            {content.modals.confirm.no}
                        </Button>
                        <Button
                            circular
                            id='modal-accept-btn'
                            content={content.modals.confirm.yes}
                            labelPosition='right'
                            icon='checkmark'
                            onClick={(e) => handleClick(e, true)}
                            className='primary-color-active-btn'
                        />
                    </Modal.Actions>
                </>
            }
        </Modal>
    )
}

export default ConfirmModal
import React, { useState } from 'react';
import { confirmPasswordReset, getAuth, verifyPasswordResetCode } from 'firebase/auth'
import { Button, Container, Divider, Form, Header, Input, Segment } from 'semantic-ui-react';
import { notifyError, translateContent, translateErrorMessages, notifySuccess, translateSuccessMessages, LogErrors } from '../../../resources/lib';
import { Navigate, useLocation } from 'react-router-dom';
import Footer from '../../Global/Footer';

const ResetPassword = () => {
    const location = useLocation()
    const [formData, setFormData] = useState('')
    const [showPWord, setShowPWord] = useState(false)
    const queryParams = new URLSearchParams(location.search)
    const token = queryParams.get('oobCode')

    const content = translateContent(localStorage.getItem('Language'))
    const errorMessages = translateErrorMessages(localStorage.getItem('Language'))
    const successMessages = translateSuccessMessages(localStorage.getItem('Language'))

    async function handlePasswordReset(event) {
        event.preventDefault()
        const auth = getAuth()
        try {
            await verifyPasswordResetCode(auth, token)
            await confirmPasswordReset(auth, token, formData)
            notifySuccess(successMessages.password_reset_finished)
        } catch (error) {
            LogErrors(error)
            notifyError(errorMessages.contact_support)
        }
    }

    function handleInputChange(event) {
        const { value } = event.target
        setFormData(value)
    }


    if (localStorage.getItem('UserInfo'))
        return <Navigate to='/' />

    return (
        <Container fluid>
            <Segment basic className='space-segment'>
                <Header id='page-title' inverted className='huge-text shadow-text' as='h1'>{content.reset_password.title}</Header>
                <Divider />
            </Segment>
            <Container fluid>
                <Container className='half-view'>
                    <Segment basic>
                        <div className='forms-small-box'>
                            <Form size='big' onSubmit={handlePasswordReset}>
                                {!showPWord &&
                                    <Form.Field required>
                                        <label className='big-text shadow-text brown-text'>{content.reset_password.password_label}</label>
                                        <Input icon='lock' iconPosition='left' action={
                                            <Button id='pword-btn' circular className='third-back-color' title={content.login.password_button_message_show} icon='eye' onClick={() => setShowPWord(!showPWord)}></Button>
                                        }
                                            type='password' minLength="8" maxLength="20" placeholder={content.reset_password.password_placeholder} name="password" value={formData} onChange={handleInputChange} required />
                                    </Form.Field>
                                }
                                {showPWord &&
                                    <Form.Field required>
                                        <label className='big-text shadow-text brown-text'>{content.reset_password.password_label}</label>
                                        <Input icon='lock' iconPosition='left' action={
                                            <Button id='pword-btn' circular className='third-back-color' title={content.login.password_button_message_hide} icon='eye slash' onClick={() => setShowPWord(!showPWord)}></Button>
                                        }
                                            type='text' minLength="8" maxLength="20" placeholder={content.reset_password.password_placeholder} name="password" value={formData} onChange={handleInputChange} required />
                                    </Form.Field>
                                }
                                <Button id='login-btn' circular size='big' className='secondary-color-btn' type='submit'>{content.reset_password.button}</Button>
                            </Form>
                        </div>
                    </Segment>
                </Container>
            </Container>
            <Footer />
        </Container>
    )
}

export default ResetPassword
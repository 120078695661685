import React, { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';

const StarRating = (props) => {
    const maxRating = 5
    const width = 768
    const [isHidden, setIsHidden] = useState(window.innerWidth > width)
    const [rating, setRating] = useState(0)
    const [hoverRating, setHoverRating] = useState(0)

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > width) {
                setIsHidden(true)
            } else {
                setIsHidden(false)
            }
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [width])

    const handleMouseEnter = (value) => {
        setHoverRating(value)
    }

    const handleMouseLeave = () => {
        setHoverRating(0)
    }

    const handleClick = (value) => {
        setRating(value)
        props.getRating(value)
    }

    const renderStarIcon = (starIndex) => {
        if (hoverRating >= starIndex) {
            return 'star'
        } else if (!hoverRating && rating >= starIndex) {
            return 'star'
        }
        return 'star outline'
    }

    const renderStars = () => {
        return Array.from({ length: maxRating }, (_, index) => (
            <Icon
                key={index}
                size={isHidden ? 'huge' : 'big'}
                className="star-icon"
                name={renderStarIcon(index + 1)}
                onMouseEnter={() => handleMouseEnter(index + 1)}
                onMouseLeave={handleMouseLeave}
                onClick={() => handleClick(index + 1)}
            />
        ))
    }

    return <div>{renderStars()}</div>
}

export default StarRating
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
//import { getAnalytics } from "firebase/analytics";

//PROD
const firebaseConfig = {
    apiKey: "AIzaSyBBHWITVv08mdsALsia5mVjK1XUBy5ZxVk",
    authDomain: "reservado-app.firebaseapp.com",
    projectId: "reservado-app",
    storageBucket: "reservado-app.appspot.com",
    messagingSenderId: "775213707332",
    appId: "1:775213707332:web:72c0d0e5585b6d656d8c11",
    measurementId: "G-HB7W8CL7FQ"
}

//TEST
const firebaseConfigTest = {
    apiKey: "AIzaSyBe36ivSmbNs79DONigQpRsTstWduuvHPs",
    authDomain: "reservado-app-testing.firebaseapp.com",
    projectId: "reservado-app-testing",
    storageBucket: "reservado-app-testing.appspot.com",
    messagingSenderId: "168866840358",
    appId: "1:168866840358:web:bf7866a18b0bebbfef8be7",
    measurementId: "G-J6SJXLYFKW"
}

const app = initializeApp(process.env.REACT_APP_ENV !== 'dev' ? firebaseConfig : firebaseConfigTest)
//const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
//const analytics = getAnalytics(app);
import React, { useEffect, useState } from 'react'
import { Container, Divider, Grid, Header, Icon, Segment } from 'semantic-ui-react'
import { translateContent } from '../../../resources/lib'

const iconList = [
    'calendar',
    'rocket',
    'clock',
    'bell',
    'settings'
]

const OurFeatures = () => {
    const width = 768
    const [isHidden, setIsHidden] = useState(window.innerWidth > width)

    const content = translateContent(localStorage.getItem('Language'))

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > width) {
                setIsHidden(true)
            } else {
                setIsHidden(false)
            }
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [width])


    if (!isHidden)
        return (
            <Container fluid id='container-no-margin'>
                <Segment basic className='space-segment divider-container'>
                    <Segment basic className='segment-divider'>
                        <Header as='h2' className='brown-text huge-text shadow-text'>{content.features.title}</Header>
                        <Divider />
                    </Segment>
                    <Grid stackable columns={content.features.names.length} textAlign='center'>
                        {content.features.names.map((item, index) => (
                            <Grid.Column key={index}>
                                <Icon name={iconList[index]} size='huge' className='secondary-color-icon' />
                                <Header as='h2' className='secondary-text-color big-text shadow-text'>{item}</Header>
                            </Grid.Column>
                        ))}
                    </Grid>
                </Segment>
            </Container>
        )


    return (
        <Container fluid id='container-no-margin'>
            <Segment basic className='space-segment divider-container'>
                <Segment basic className='segment-divider'>
                    <Header as='h2' className='brown-text huge-text shadow-text'>{content.features.title}</Header>
                    <Divider />
                </Segment>
                <br />
                <Grid stackable columns={content.features.names.length} textAlign='center'>
                    {iconList.map((item, index) => (
                        <Grid.Column key={index}>
                            <Icon name={item} size='huge' className='secondary-color-icon' />
                        </Grid.Column>
                    ))}
                </Grid>
                <Grid stackable columns={content.features.names.length} textAlign='center'>
                    {content.features.names.map((item, index) => (
                        <Grid.Column key={index}>
                            <Header as='h2' className='secondary-text-color big-text shadow-text'>{item}</Header>
                        </Grid.Column>
                    ))}
                </Grid>
            </Segment>
        </Container>
    )
}

export default OurFeatures
import React from 'react'
import { Button, Container, Header, Icon } from 'semantic-ui-react'
import { translateContent } from '../../resources/lib'

const Footer = () => {
    const content = translateContent(localStorage.getItem('Language'))

    function openEmail() {
        const email = 'suporte.reservado@gmail.com'
        window.open(`mailto:${email}`)
    }

    return (
        <Container fluid className='space-segment'>
            <div className="notice-box shadow-text">
                <Header className='huge-text shadow-text' as='h1' inverted>{content.footer.title}</Header>
                <br />
            </div>
            <div>
                <p>
                    <a className='black-link shadow-text' href='https://www.facebook.com/profile.php?id=61554846553994' target='_blank' rel='noopener noreferrer'>
                        <Icon name='facebook' size='huge' />
                    </a>
                    <a className='black-link shadow-text' href='https://www.instagram.com/reservado.official/' target='_blank' rel='noopener noreferrer'>
                        <Icon name='instagram' size='huge' />
                    </a>
                </p>
                <Button size='big' className='third-color-active-btn' onClick={openEmail}>{content.footer.support_button}</Button>
            </div>
            <p>&copy; 2023 Reservado</p>
        </Container>
    )
}

export default Footer
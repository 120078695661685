import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Header, Icon, Segment } from 'semantic-ui-react';
import Footer from '../../Global/Footer';

const NoLoginError = () => {

    function handleClick() {
        localStorage.setItem('BookingUrl', window.location.href)
    }

    return (
        <Container fluid className='full-view'>
            <Container>
                <Segment compact basic textAlign='center'>
                    <Segment inverted className='third-back-color'>
                        <Container className="section-box">
                            <div className="notice-box">
                                <Header className='huge-text shadow-text' as='h1' inverted>Precisas de entrar na tua conta antes de reservar</Header>
                            </div>
                            <div className="notice-inside-box secondary-back-color">
                                <Icon className='third-color-icon' size='massive' name='hand paper' />
                                <Segment basic>
                                    <Button circular size='big' as={Link} to={`/login/client`} onClick={handleClick} className='primary-color-btn btn-pad'><Icon name='sign in' />Entrar</Button>
                                    <Button circular size='big' as={Link} to={`/register/client`} className='primary-color-btn btn-pad'><Icon name='signup' />Criar Conta</Button>
                                </Segment>
                            </div>
                        </Container>
                    </Segment>
                </Segment>
            </Container>
            <Footer />
        </Container>
    )
}

export default NoLoginError
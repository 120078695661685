import './App.css'
import { useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AppHeader from './components/Global/Header'
import Register from './components/Others/LoginNRegister/Register'
import Login from './components/Others/LoginNRegister/Login'
import BusinessList from './components/Others/Business/BusinessList'
import BusinessPreview from './components/Others/Business/BusinessPreview'
import HomeBusiness from './components/Others/Home/HomeBusiness'
import UserBookings from './components/Others/Client/ClientBookings'
import CreateBook from './components/Others/Client/CreateBooking'
import MyProfile from './components/Others/MyAccount/MyProfile'
import ClientBusinessPreview from './components/Others/Client/ClientBusinessPreview'
import ClientAgenda from './components/Others/Client/ClientAgenda'
import NewBusiness from './components/Others/Business/NewBusiness'
import RegisterSuccess from './components/Others/LoginNRegister/RegisterSuccess'
import TermsNConditionsPT from './components/Global/Terms/TermsNConditionsPT'
import TermsNConditionsEN from './components/Global/Terms/TermsNConditionsEN'
import GuidePT from './components/Global/Guide/GuidePT'
import GuideEN from './components/Global/Guide/GuideEN'
import { setLanguage } from './resources/lib'
import ClientFeedbackForm from './components/Others/Client/ClientFeedbackForm'
import TermsNConditionsModalPT from './components/Others/Modals/Terms/TermsNConditionsModalPT'
import TermsNConditionsModalEN from './components/Others/Modals/Terms/TermsNConditionsModalEN'
import RecoverPassword from './components/Others/LoginNRegister/RecoverPassword'
import ResetPassword from './components/Others/LoginNRegister/ResetPassword'
import HomeClient from './components/Others/Home/HomeClient'

function App() {
  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload()
    }, (90000 * 4)) // 60 minutes in milliseconds

    return () => clearInterval(interval)
  }, [])


  function translateTermsModal() {
    const lang = localStorage.getItem('Language')
    switch (lang) {
      case 'EN':
        return <TermsNConditionsModalEN />
      default:
        return <TermsNConditionsModalPT />
    }
  }

  function translateTerms() {
    const lang = localStorage.getItem('Language')
    switch (lang) {
      case 'EN':
        return <TermsNConditionsEN />
      default:
        return <TermsNConditionsPT />
    }
  }

  function translateGuide() {
    const lang = localStorage.getItem('Language')
    switch (lang) {
      case 'EN':
        return <GuideEN />
      default:
        return <GuidePT />
    }
  }

  setLanguage()

  return (
    <BrowserRouter>
      <div className="body primary-degra-color">
        <AppHeader />
        <ToastContainer />
        <Routes>
          <Route path='*' element={<HomeBusiness />} />
          <Route exact path='/' element={<HomeClient />} />
          <Route exact path='/home/business' element={<HomeBusiness />} />
          <Route exact path='/account/:page' element={<MyProfile />} />
          <Route exact path='/login/:type' element={<Login />} />
          <Route exact path='/register/:type' element={<Register />} />
          <Route exact path='/business' element={<BusinessList />} />
          <Route exact path='/business/:page/:id' element={<BusinessPreview />} />
          <Route exact path='/business/:page/:id/:bookingType' element={<BusinessPreview />} />
          <Route exact path='/bookings/:page' element={<UserBookings />} />
          <Route path='/booking/business/:name/:id' element={<CreateBook />} />
          <Route path='/:name' element={<ClientBusinessPreview />} />
          <Route exact path='/agenda' element={<ClientAgenda />} />
          <Route exact path='/new/business' element={<NewBusiness />} />
          <Route exact path='/register/success' element={<RegisterSuccess />} />
          <Route exact path='/terms' element={translateTerms()} />
          <Route path='/feedback/:businessId/:feedbackToken' element={<ClientFeedbackForm />} />
          <Route exact path='/guide' element={translateGuide()} />
          <Route exact path='/recover' element={<RecoverPassword />} />
          <Route path='/reset/auth/action' element={<ResetPassword />} />
        </Routes>
        {!localStorage.getItem('AcceptTerms') &&
          translateTermsModal()
        }
      </div>
    </BrowserRouter>
  )
}

export default App

import React from 'react'
import { Container, Grid, Header, Image, Segment } from 'semantic-ui-react'
import appImage from '../../../resources/Reservado.png'
import { translateContent } from '../../../resources/lib'

const OurBrand = () => {
    const content = translateContent(localStorage.getItem('Language'))

    return (
        <Container fluid>
            <Segment basic className='space-segment'>
                <Grid stackable columns='equal' textAlign='center'>
                    <Grid.Row className='center-contents'>
                        <Grid.Column>
                            <Segment basic>
                                <Header as='h2' className='secondary-text-color bigger-text shadow-text'>{content.brand.meaning_title}</Header>
                                <p className='large-text shadow-text brown-text'><b>"Reservado"</b> {content.brand.meaning_text_1} <b>"Reservado"</b> {content.brand.meaning_text_2}</p>
                            </Segment>
                            <Segment basic>
                                <Header as='h2' className='secondary-text-color bigger-text shadow-text'>{content.brand.logo_title}</Header>
                                <p className='large-text shadow-text brown-text'>{content.brand.logo_text}</p>
                            </Segment>
                        </Grid.Column>
                        <Grid.Column>
                            <Image src={appImage} centered rounded />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </Container>
    )

}

export default OurBrand
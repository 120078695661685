import React from 'react'
import { Container, Header, Icon, Segment } from 'semantic-ui-react'
import { translateContent } from '../../../resources/lib'

const OurMission = () => {
    const content = translateContent(localStorage.getItem('Language'))

    return (
        <Container fluid className='space-segment'>
            <Segment basic>
                <Icon name='rocket' size='massive' className='primary-color-icon' />
                <Header as='h2' className='primary-text-color bigger-text shadow-text'>{content.mission.title}</Header>
                <p className='large-text shadow-text brown-text'>{content.mission.text}</p>
            </Segment>
        </Container>
    )

}

export default OurMission
import React from 'react'
import { Dropdown } from 'semantic-ui-react'
import { translateCategories, translateContent } from '../../../resources/lib'

const CustomMultiDropdown = (props) => {
    const content = translateContent(localStorage.getItem('Language'))

    function formatData(data) {
        if (!props.hasOptions) {
            let newData = []

            data.forEach((item, index) => {
                newData.push({
                    key: index,
                    text: props.isCategory ? translateCategories(localStorage.getItem('Language'), item.name) : item.name,
                    value: item.id
                })
            })
            return newData
        }
        return data
    }

    function handleChange(event, data) {
        props.handleDropdown(data.value)
    }

    return (
        <Dropdown
            id={props.text.toLowerCase().replaceAll(' ', '_')}
            placeholder={props.text}
            search
            selection
            multiple
            defaultValue={props.default}
            options={formatData(props.data)}
            onChange={handleChange}
            noResultsMessage={content.multi_drop.results_text}
            fluid
        />
    )
}

export default CustomMultiDropdown
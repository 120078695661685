import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { handleReload } from '../../../resources/lib';
import Flag from 'react-country-flag';

export const languageOptions = [
    {
        key: 'EN',
        value: 'EN',
        text: (
            <span>
                <Flag countryCode="GB" svg /> English
            </span>
        ),
    },
    {
        key: 'PT',
        value: 'PT',
        text: (
            <span>
                <Flag countryCode="PT" svg /> Português
            </span>
        ),
    },
]

const LanguagesDrop = () => {
    function handleLanguageSelect(language) {
        localStorage.setItem('Language', language)
        handleReload()
    }

    function verifyLang() {
        const lang = localStorage.getItem('Language')
        if (lang === 'PT')
            return (<span>
                <Flag countryCode="PT" svg /> Português
            </span>)
        else
            return (<span>
                <Flag countryCode="GB" svg /> English
            </span>)
    }

    return (
        <Dropdown id='lang-drop' item text={verifyLang()}>
            <Dropdown.Menu>
                {languageOptions.map((option) => (
                    <Dropdown.Item key={option.key} {...option} onClick={() => handleLanguageSelect(option.value)} />
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default LanguagesDrop
import React, { useEffect, useState } from 'react';
import { LogErrors, getFolderTabs, notifyError, translateContent } from '../../../resources/lib';
import { Navigate, useParams } from 'react-router-dom';
import { Container, Divider, Header, Segment } from 'semantic-ui-react';
import AppLoader from '../../Global/AppLoader';
import ClientBusiness from './ClientBusiness';
import ClientBusinessServicePreview from './ClientBusinessServicesPreview';
import ClientWortTimes from './ClientWortTimes';
import { getDataBy, getDataBy3Wheres } from '../../../resources/firebaseLib';

const ClientBusinessPreview = () => {
    const { name } = useParams()
    const [businessData, setBusinessData] = useState(null)
    const [servicesData, setServicesData] = useState(null)
    const [menusData, setMenusData] = useState(null)
    const [ratingData, setRatingsData] = useState(null)
    const [worktimesData, setWorktimesData] = useState(null)
    const [socialsData, setSocialData] = useState(null)
    const [pMethodsData, setPMethodsData] = useState(null)
    const [licenseData, setLicenseData] = useState(null)

    const content = translateContent(localStorage.getItem('Language'))

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [businessResponse] = await Promise.all([
                    getDataBy('business', 'name', formatName(name)),
                ])
                const businessData = businessResponse[0]
                const id = businessData.id
                setBusinessData(businessData)
                const [servicesResponse, menusResponse, ratingResponse, worktimesResponse, socialsResponse, pMethodsResponse, foldersResponse, menusFoldersResponse] = await Promise.all([
                    getDataBy3Wheres('business_services', 'businessId', 'isActive', 'isDelete', id, 1, 0),
                    getDataBy3Wheres('business_menus', 'businessId', 'isActive', 'isDelete', id, 1, 0),
                    getDataBy('business_rating', 'businessId', id),
                    getDataBy('business_work_times', 'businessId', id),
                    getDataBy('business_socials', 'businessId', id),
                    getDataBy('business_payment_methods', 'businessId', id),
                    getDataBy('business_folders', 'businessId', id),
                    getDataBy('business_menus_folders', 'businessId', id)
                ])
                setServicesData(getFolderTabs(foldersResponse, servicesResponse))
                setMenusData(getFolderTabs(menusFoldersResponse, menusResponse))
                setRatingsData(ratingResponse)
                setWorktimesData(worktimesResponse)
                setSocialData(socialsResponse)
                setPMethodsData(pMethodsResponse)
                const [licensesResponse] = await Promise.all([
                    getDataBy('licenses', 'uid', businessData.ownerUid)
                ])
                setLicenseData(licensesResponse[0])

            } catch (error) {
                LogErrors(error)
                notifyError(error)
            }
        }

        fetchData()
    }, [name])

    function formatName(name) {
        const formatName = name.replaceAll("-", ' ')
        return formatName
    }

    if (!businessData || !servicesData || !worktimesData)
        return <AppLoader />
    if (businessData.length === 0 || servicesData.length === 0 || worktimesData.length === 0)
        return <Navigate to='/' />
    return (
        <Container fluid className='business-container'>
            <Segment basic>
                <ClientBusiness data={businessData} id={businessData.id} socials={socialsData} pMethods={pMethodsData} license={licenseData} rating={ratingData} isPreview={true} hasTimes={worktimesData.length > 0} hasServices={servicesData.length > 0} isRestaurant={businessData.isRestaurant} />
                <Divider />
                {worktimesData.length > 0 &&
                    <Segment basic>
                        <Header id='business-worktimes-subtitle' inverted className='huge-text shadow-text' as='h1'>{content.client_business_preview.work_times.title}</Header>
                    </Segment>
                }
                <ClientWortTimes data={worktimesData} businessId={businessData.id} />
                <Divider />
                {servicesData.length > 0 &&
                    <Segment basic>
                        <Header id='business-services-subtitle' inverted className='huge-text shadow-text' as='h1'>{businessData.isRestaurant ? content.client_business_preview.menus.title : content.client_business_preview.services.title}</Header>
                    </Segment>
                }
                <ClientBusinessServicePreview data={businessData.isRestaurant ? menusData : servicesData} isRestaurant={businessData.isRestaurant} />
            </Segment>
        </Container>
    )
}

export default ClientBusinessPreview
import React, { useEffect, useState } from 'react'
import { Button, Card, Container, Grid, Header, Image, Segment } from 'semantic-ui-react'

//Image
import barbImage from '../../../resources/other/categories/barb.png'
import lawImage from '../../../resources/other/categories/law.png'
import mecImage from '../../../resources/other/categories/mec.png'
import nutrImage from '../../../resources/other/categories/nutr.png'
import psyImage from '../../../resources/other/categories/psy.png'
import eleImage from '../../../resources/other/categories/ele.png'
import carpImage from '../../../resources/other/categories/carp.png'
import plumImage from '../../../resources/other/categories/plum.png'
import handyImage from '../../../resources/other/categories/handy.png'
import musImage from '../../../resources/other/categories/mus.png'
import ptImage from '../../../resources/other/categories/pt.png'
import fotoImage from '../../../resources/other/categories/foto.png'
import { translateContent } from '../../../resources/lib'

const categoryImages = [
    barbImage,
    lawImage,
    mecImage,
    nutrImage,
    psyImage,
    eleImage,
    carpImage,
    plumImage,
    handyImage,
    musImage,
    ptImage,
    fotoImage
]

const OurClients = () => {
    const [activeIndex, setActiveIndex] = useState(0)

    const content = translateContent(localStorage.getItem('Language'))

    useEffect(() => {
        const interval = setInterval(() => {
            if (activeIndex !== categoryImages.length - 1)
                setActiveIndex((activeIndex + 1) % categoryImages.length)
            else
                setActiveIndex(0)

        }, 8000)

        return () => clearInterval(interval)
    }, [activeIndex])

    const handleNext = () => {
        setActiveIndex((activeIndex + 1) % categoryImages.length)
    }

    const handlePrev = () => {
        setActiveIndex(activeIndex === 0 ? categoryImages.length - 1 : activeIndex - 1)
    }

    return (
        <Container fluid>
            <Segment basic>
                <Header className='secondary-text-color huge-text shadow-text' as='h1'>{content.target_clients.title1}</Header>
                <Header className='secondary-text-color huge-text shadow-text' as='h1'>{content.target_clients.title2}</Header>
            </Segment>
            <Segment basic compact style={{ margin: 'auto' }}>
                <Grid columns='three' textAlign='center' verticalAlign='middle'>
                    <Grid.Row>
                        <Grid.Column width={2} onClick={handlePrev}>
                            <Button circular secondary icon="angle left" />
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Card centered className='trasparent-back'>
                                <Image className='trasparent-back' src={categoryImages[activeIndex]} wrapped ui={false} />
                                <Card.Content>
                                    <Card.Header className='shadow-text brown-text'>{content.target_clients.names[activeIndex]}</Card.Header>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column width={2} onClick={handleNext}>
                            <Button circular secondary icon="angle right" />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <br />
            </Segment>
            <Segment basic>
                <Header className='secondary-text-color huge-text shadow-text' as='h1'>{content.target_clients.more_text}</Header>
            </Segment>
        </Container>
    )
}

export default OurClients
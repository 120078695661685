import React from 'react';
import { Button, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import AppLoader from '../../Global/AppLoader';
import { Link } from 'react-router-dom';
import { capitalizeWord, encryptString, getCurrentDateTime, getPMethodsIcons, translateCategoriesString, translateContent, translatePMethods, validateLicense } from '../../../resources/lib';
import StarRatingWithText from './Feedback/StartRatingDisplay';

const ClientBusiness = (props) => {
    const content = translateContent(localStorage.getItem('Language'))

    function formatCategoriesNames(names) {
        return names.replaceAll(';', ', ')
    }

    function getBaseUrl() {
        const url = window.location.href
        const parts = url.split("/");
        return `${parts[0]}//${parts[2]}`
    }

    function formatName(name) {
        const formatName = name.replaceAll(" ", '-')
        return formatName
    }

    if (!props.data || !props.license)
        return <AppLoader />
    else {
        const { data, id, license } = props
        return (
            <div className='tables-box-large'>
                <Segment basic>
                    <Grid columns={1} padded>
                        <Grid.Row>
                            <Grid.Column id='business-name'><Header inverted as='h1' className='massive-text shadow-text'>{data.name}</Header></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column id='business-categories'><Header className='shadow-text primary-text-color' as='h1'>{translateCategoriesString(localStorage.getItem('Language'), formatCategoriesNames(data.categoriesNames), props.isRestaurant)}</Header></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column id='business-contact'><Header inverted className='shadow-text'><Icon className='third-color-icon' name='phone' />{data.contact}</Header></Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column id='business-address'>
                                <Header inverted className='shadow-text'><Icon className='third-color-icon' name='marker' />
                                    {(data.address.length > 0 ? `${data.address}, ${data.postalCode}, ${data.city}` : data.city)}
                                </Header>
                            </Grid.Column>
                        </Grid.Row>
                        {data.website !== '' &&
                            <Grid.Row>
                                <Grid.Column id='business-website'><Header color='blue' as={Link} to={data.website} target="_blank"><Icon name='globe' />{data.website}</Header></Grid.Column>
                            </Grid.Row>
                        }
                        <Grid.Row>
                            <Grid.Column>
                                {props.socials.length > 0 &&
                                    <Header inverted as='h2' className='shadow-text primary-text-color'>{content.client_business_preview.client_business.socials_title}</Header>
                                }
                                {props.socials.map((social, index) => (
                                    <a key={index} id={`business-social-${social.name}`} className='shadow-text' href={social.url} target='_blank' rel='noopener noreferrer'>
                                        <Icon name={social.name} size='huge' title={`${capitalizeWord(social.name)}`} />
                                    </a>
                                ))}
                            </Grid.Column>
                            <Grid.Column className="payment-methods">
                                <br />
                                {props.pMethods.length > 0 &&
                                    <Header inverted as='h2' className='shadow-text primary-text-color'>{content.client_business_preview.client_business.pMethods_tile}</Header>
                                }
                                {props.pMethods.map((pMethod, index) => (
                                    <Icon key={index} name={`${getPMethodsIcons(pMethod.name)}`} size="huge" title={`${translatePMethods(pMethod.name)}`} />
                                ))}
                            </Grid.Column>
                        </Grid.Row>
                        {props.rating.length > 0 &&
                            <Grid.Row>
                                <Grid.Column id='book-btn'><StarRatingWithText rating={props.rating[0].rating} /></Grid.Column>
                            </Grid.Row>
                        }
                        {(props.hasTimes && validateLicense(license)) &&
                            <Grid.Row>
                                <Grid.Column id='book-btn'><Button circular size='massive' className='primary-color-btn' as={Link} to={`${getBaseUrl()}/booking/business/${formatName(data.name)}/${id}?vtoken=${encryptString(getCurrentDateTime())}`}>{content.client_business_preview.client_business.button}</Button></Grid.Column>
                            </Grid.Row>
                        }
                    </Grid>
                </Segment>
            </div>
        )
    }

}

export default ClientBusiness
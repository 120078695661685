import React, { useEffect, useState } from 'react';
import { decrytptString, readLocalStorageUserValue, readValueInLocalStorage, sortArrayByDateAsc, translateContent } from '../../../resources/lib';
import { getDataBy2Wheres } from '../../../resources/firebaseLib';
import Business from './Business';
import { Container, Divider, Header, Segment } from 'semantic-ui-react';
import { Navigate } from 'react-router-dom';
import AppLoader from '../../Global/AppLoader';
import Footer from '../../Global/Footer';

const BusinessList = () => {
    const [data, setData] = useState(null)

    const content = translateContent(localStorage.getItem('Language'))

    useEffect(() => {
        getBusinesses()
    }, [])

    async function getBusinesses() {
        const data = await getDataBy2Wheres('business', 'ownerUid', 'isDelete', decrytptString(readLocalStorageUserValue('token')), 0)
        setData(sortArrayByDateAsc(data))
    }

    if (!localStorage.getItem('UserInfo'))
        return <Navigate to='/' />
    else {
        const accType = decrytptString(readValueInLocalStorage('Type', 'type'))
        if (accType !== 'business')
            return <Navigate to='/' />

        if (!data)
            return <AppLoader />
        return (
            <Container fluid>
                <Segment basic className='space-segment'>
                    <Header id='page-title' inverted className='huge-text shadow-text' as='h1'>{content.businesses.title}</Header>
                    <Divider />
                </Segment>
                <Container fluid>
                    <Container className='short-half-view' fluid>
                        {
                            <Business data={data} getBusiness={getBusinesses} />
                        }
                    </Container>
                </Container>
                <Footer />
            </Container>
        )
    }


}

export default BusinessList
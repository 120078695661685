import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Icon, Table } from 'semantic-ui-react';
import { notifyError, getBusinessMenuEmptyObject, formatTime, notifySuccess, translateContent, translateErrorMessages, translateSuccessMessages, removeId, translateNFormatServicePrice } from '../../../resources/lib';
import AppLoader from '../../Global/AppLoader';
import ConfirmModal from '../Modals/ConfirmModal';
import { updateDataByDocId } from '../../../resources/firebaseLib';
import BusinessMenusModal from '../Modals/BusinessMenusModal';

const BusinessMenus = (props) => {
    const content = translateContent(localStorage.getItem('Language'))
    const errorMessages = translateErrorMessages(localStorage.getItem('Language'))
    const successMessages = translateSuccessMessages(localStorage.getItem('Language'))

    function handleStatus(status) {
        if (Number(status) === 0)
            return <Icon size='big' color='red' name='remove circle' />
        else
            return <Icon size='big' color='green' name='check circle' />
    }

    async function handleAccept(event, item) {
        event.preventDefault()
        const result = await updateDataByDocId('business_menus', item.id, { isActive: item.isActive === 1 ? 0 : 1 })
        if (result) {
            props.refreshServices(props.businessId)
            notifySuccess(successMessages.status_updated)
        }
        else {
            notifyError(errorMessages.contact_support)
        }
    }

    function handleModal(message) {
        props.refreshServices(props.businessId)
        notifySuccess(message)
    }

    async function handleDelete(item) {
        item.isDelete = 1
        let result = await updateDataByDocId('business_menus', item.id, removeId(item))
        if (result) {
            props.refreshServices(props.businessId)
            notifySuccess(successMessages.menu_deleted)
        }
        else {
            notifyError(errorMessages.contact_support)
        }
    }

    async function handleClick(type, item) {
        if (type)
            await handleDelete(item)
    }

    const tableHeaders = content.menus.table_headers_text
    const tableFields = content.menus.table_headers_values
    if (!props.data)
        return <AppLoader />
    return (
        <div className='tables-box'>
            <div className='scrollable-table'>
                <Table id='services-table' className='text-big no-border shadow-text' celled stackable selectable textAlign='center'>
                    <Table.Header>
                        <Table.Row className='table-header-bg'>
                            {
                                tableHeaders.map((item) => (
                                    <Table.HeaderCell key={item}>{item}</Table.HeaderCell>
                                ))
                            }
                        </Table.Row>
                    </Table.Header>
                    <Table.Body className='table-body-bg'>
                        {props.data.length === 0 &&
                            <Table.Row>
                                <Table.Cell colSpan={tableHeaders.length}>{content.nothing_to_show}</Table.Cell>
                            </Table.Row>
                        }
                        {props.data.map(item => (
                            <Table.Row key={item.id}>
                                {tableFields.map((field) => (
                                    field === 'name' ? (
                                        <Table.Cell key={field}>{item[field]}</Table.Cell>
                                    ) : (
                                        field === 'isActive' ? (
                                            <Table.Cell key={field}>{handleStatus(item[field])}</Table.Cell>
                                        ) : (
                                            field === 'icon' ? (
                                                <Table.Cell key={field}><Icon size='big' name={item[field]} color={item.color} /></Table.Cell>
                                            ) : (
                                                field === 'time' ? (
                                                    <Table.Cell key={field}>{formatTime(item[field])}</Table.Cell>
                                                ) : (
                                                    <Table.Cell key={field}>{translateNFormatServicePrice(item)}€</Table.Cell>
                                                )
                                            )
                                        )
                                    )
                                ))
                                }
                                <Table.Cell>
                                    <div className={!item.isActive ? "services-icon-grid" : ""}>
                                        <div>
                                            <Icon title={item.isActive ? content.menus.switch_off : content.menus.switch_on} size='large' color='green' name='exchange' onClick={e => handleAccept(e, item)} />
                                        </div>
                                        {!item.isActive &&
                                            <BusinessMenusModal handleModal={handleModal} item={item} folders={props.folders} component={<Icon title={content.edit} size='large' color='yellow' name='edit' />} />
                                        }
                                        {!item.isActive &&
                                            <ConfirmModal item={item} handleClick={handleClick} action={`${content.menus.delete_action} '${item.name}'?`}
                                                component={<Icon title={content.delete} size='large' color='red' name='delete' />} />
                                        }
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
            <br />
            <BusinessMenusModal handleModal={handleModal} new={true} item={getBusinessMenuEmptyObject(props.businessId)} folders={props.folders} component={
                <Button id='add-service-btn' circular size='big' className='primary-color-btn' as={Link}><Icon name='add' /> {content.menus.button}</Button>
            } />
        </div>
    )
}

export default BusinessMenus
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Icon, Table } from 'semantic-ui-react';
import { getWorktimesEmptyObject, notifyError, notifySuccess, translateContent, translateErrorMessages, translateSuccessMessages } from '../../../resources/lib';
import AppLoader from '../../Global/AppLoader';
import BusinessWorkTimesModal from '../Modals/BusinessWorkTimesModal';
import ConfirmModal from '../Modals/ConfirmModal'
import { deleteDocById } from '../../../resources/firebaseLib';

const WorkTimes = (props) => {
    const content = translateContent(localStorage.getItem('Language'))
    const errorMessages = translateErrorMessages(localStorage.getItem('Language'))
    const successMessages = translateSuccessMessages(localStorage.getItem('Language'))

    async function handleDelete(item) {
        const result = await deleteDocById('business_work_times', item.id)
        if (result) {
            props.getWorktimes(item.businessId)
            notifySuccess(successMessages.work_time_deleted)
        }
        else {
            notifyError(errorMessages.contact_support)
        }
    }

    async function handleClick(type, item) {
        if (type)
            await handleDelete(item)
    }

    function handleModal(message) {
        props.getWorktimes(props.businessId)
        notifySuccess(message)
    }

    const tableHeaders = content.work_times.table_headers_text
    const tableFields = content.work_times.table_headers_values
    if (!props.data)
        return <AppLoader />
    return (
        <Container>
            <Table id='worktimes-table' celled stackable selectable textAlign='center' className='no-border shadow-text'>
                <Table.Header>
                    <Table.Row className='table-header-bg'>
                        {
                            tableHeaders.map((item) => (
                                <Table.HeaderCell key={item}>{item}</Table.HeaderCell>
                            ))
                        }
                    </Table.Row>
                </Table.Header>
                <Table.Body className='table-body-bg'>
                    {props.data.length === 0 &&
                        <Table.Row>
                            <Table.Cell colSpan='8'>{content.nothing_to_show}</Table.Cell>
                        </Table.Row>
                    }
                    {props.data.map((item, index) => (
                        <Table.Row key={index}>
                            {tableFields.map((field) => (
                                <Table.Cell key={field}>{item[field] === '' ? content.work_times.closed : item[field]}</Table.Cell>
                            ))
                            }
                            <Table.Cell>
                                <BusinessWorkTimesModal handleModal={handleModal} item={item}
                                    component={<Icon title={content.edit} size='large' color='yellow' name='edit' />} />
                                <ConfirmModal item={item} handleClick={handleClick} action={content.work_times.delete_action}
                                    component={<Icon title={content.delete} size='large' color='red' name='delete' />} />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            {props.data.length === 0 &&
                <BusinessWorkTimesModal handleModal={handleModal} new={true} item={getWorktimesEmptyObject(props.businessId)} component={<Button id='add-worktimes-btn' circular size='big' as={Link} className='primary-color-btn'><Icon name='add' /> {content.work_times.button}</Button>} />
            }
        </Container>
    )
}

export default WorkTimes
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, Header, Icon, Segment, Table } from 'semantic-ui-react';
import { Link, Navigate, useParams } from 'react-router-dom';
import AppLoader from '../../Global/AppLoader';
import { LogErrors, capitalize, decrytptString, formatTime, getCurrentDate, isDate1Greater, isDate1GreaterOrEqual, notifyError, notifySuccess, readLocalStorageUserValue, readValueInLocalStorage, removeId, sortArrayByJoinDateDesc, translateContent, translateEmailTextAcceptBooking, translateEmailTextDeleteBooking, translateEmailTitles, translateErrorMessages, translateSuccessMessages } from '../../../resources/lib';
import BookingModal from '../Modals/BookingModal';
import ConfirmModal from '../Modals/ConfirmModal';
import Footer from '../../Global/Footer';
import BookingPreviewModal from '../Modals/BookingPreviewModal';
import { createEmail, deleteDocById, getAllBookingsClient, getAllBookingsFromTodayClient, updateDataByDocId } from '../../../resources/firebaseLib';

const UserBookings = (props) => {
    const width = 768
    const { page } = useParams()
    const [bookingsData, setBookingsData] = useState(null)
    const [lastDoc, setLastDoc] = useState(1)
    const [onLoad, setOnLoad] = useState(false)
    const [isHidden, setIsHidden] = useState(window.innerWidth > width)

    const content = translateContent(localStorage.getItem('Language'))
    const errorMessages = translateErrorMessages(localStorage.getItem('Language'))
    const successMessages = translateSuccessMessages(localStorage.getItem('Language'))

    function handleStatus(status) {
        if (Number(status) === 0)
            return <Icon size='big' color='red' name='remove circle' />
        else
            return <Icon size='big' color='green' name='check circle' />
    }

    function handleValue(field, item) {
        if (field.includes('.')) {
            const split = field.split('.')
            return item[split[0]][split[1]]
        }
        else
            return item[field]
    }

    const handleGetData = useCallback(async (type, finishNumber) => {
        setOnLoad(true)
        try {
            const data = type === 'all' ?
                await getAllBookingsClient(decrytptString(readLocalStorageUserValue('token')), finishNumber) :
                await getAllBookingsFromTodayClient(decrytptString(readLocalStorageUserValue('token')), finishNumber)
            setBookingsData(sortArrayByJoinDateDesc(data.dataList))
            setLastDoc(data.finishNumber)
        }
        catch (err) {
            LogErrors(err)
            notifyError(errorMessages.contact_support)
            setBookingsData([])
        }
        setOnLoad(false)
    }, [errorMessages])

    useEffect(() => {
        handleGetData(page, 1)
        const handleResize = () => {
            if (window.innerWidth > width) {
                setIsHidden(true)
            } else {
                setIsHidden(false)
            }
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [width, page, handleGetData])

    async function handleDelete(item) {
        let result = await deleteDocById('booking', item.id)
        if (result) {
            result = await deleteDocById('feedback_token', item.bookingEmailId)
            if (result) {
                result = await deleteDocById('emails', item.bookingEmailId)
                if (result) {
                    result = await createEmail(item.business.email, translateEmailTitles('bookingDeleted'), translateEmailTextDeleteBooking(
                        {
                            businessName: item.business.name,
                            firstName: readLocalStorageUserValue('firstName'),
                            lastName: readLocalStorageUserValue('lastName')
                        }
                    ))
                    if (result) {
                        await handleGetData(page, lastDoc)
                        notifySuccess(successMessages.booking_deleted)
                    }
                    else {
                        notifyError(errorMessages.failed_to_create_booking_delete_email)
                    }
                }
                else {
                    notifyError(errorMessages.failed_to_delete_booking_emails)
                }
            }
            else {
                notifyError(errorMessages.failed_to_delete_feedback_token)
            }
        }
        else {
            notifyError(errorMessages.failed_to_delete_booking)
        }
    }

    async function handleClick(type, item) {
        if (type)
            await handleDelete(item)
    }

    function showAcceptBookingButton(item) {
        if (!item.isActive && !item.isUserActive) {
            return <Icon title={content.accept} size='large' color='green' name='check' onClick={e => handleAccept(e, item)} />
        }
    }

    async function handleAccept(event, item) {
        event.preventDefault()
        if (onLoad) return
        setOnLoad(true)
        item.isUserActive = item.isUserActive === 1 ? 0 : 1
        let result = await updateDataByDocId('booking', item.id, removeId(item))
        if (result) {
            result = await createEmail(item.business.email, translateEmailTitles('bookingAccepted'), translateEmailTextAcceptBooking(
                {
                    businessName: item.business.name,
                    firstName: readLocalStorageUserValue('firstName'),
                    lastName: readLocalStorageUserValue('lastName')
                }
            ))
            if (result) {
                await handleGetData(page, lastDoc)
                notifySuccess(successMessages.updated)
            }
            else {
                notifyError(errorMessages.failed_to_create_booking_confirm_email)
            }
        }
        else {
            notifyError(errorMessages.failed_to_update_booking_status)
        }
        setOnLoad(false)
    }

    function formatName(name) {
        const formatName = name.replaceAll(" ", '')
        return formatName
    }

    function formatServiceNames(names) {
        if (names.length === 0)
            return ' - '

        return names.replaceAll(';', '; ')
    }

    function formatAmount(amount) {
        if (amount === 0 || amount == null || amount === '')
            return ' - '

        return amount
    }

    async function handleTypeChange(event, type) {
        setOnLoad(true)
        await handleGetData(type)
        setOnLoad(false)
    }

    async function handleShowMoreBtn() {
        handleGetData(page, lastDoc)
    }

    function selectedAccountType(type, value, name) {
        const realName = value
        if (type === realName) {
            return (
                <Button circular size='big' as={Link} to={`/bookings/${realName}`} onClick={e => handleTypeChange(e, realName)} className='primary-color-active-btn btn-pad'>{capitalize(name)}</Button>
            )
        }
        else {
            return (
                <Button circular size='big' as={Link} to={`/bookings/${realName}`} className='primary-color-btn btn-pad' name={realName} onClick={e => handleTypeChange(e, realName)}>{capitalize(name)}</Button>
            )
        }
    }

    async function handleModal(message) {
        await handleGetData(page, lastDoc)
        notifySuccess(message)
    }

    const tableHeaders = isHidden ? content.bookings.client_table_headers_text : content.bookings.client_short_table_headers_text
    const tableFields = isHidden ? content.bookings.client_table_headers_values : content.bookings.client_short_table_headers_values

    if (!localStorage.getItem('UserInfo'))
        return <Navigate to='/' />
    else {
        const accType = decrytptString(readValueInLocalStorage('Type', 'type'))
        if (accType !== 'client')
            return <Navigate to='/' />
        if (!bookingsData)
            return <AppLoader />
        if (onLoad)
            return <AppLoader />
        const currentDate = getCurrentDate()
        return (
            <Container fluid>
                <Segment basic className='space-segment'>
                    <Header id='page-title' inverted className='huge-text shadow-text' as='h1'>{content.business_preview.sidebar.bookings}</Header>
                </Segment>
                <Container className='half-view tables-box' fluid>
                    <Segment basic>
                        {selectedAccountType(page, 'today', content.bookings.current_button)}
                        {selectedAccountType(page, 'all', content.bookings.all_button)}
                    </Segment>
                    <div className='scrollable-table'>
                        <Table id='bookings-table' className='text-big no-border shadow-text' celled stackable selectable textAlign='center'>
                            <Table.Header>
                                <Table.Row className='table-header-bg'>
                                    {
                                        tableHeaders.map((item) => (
                                            <Table.HeaderCell key={item}>{item}</Table.HeaderCell>
                                        ))
                                    }
                                </Table.Row>
                            </Table.Header>
                            <Table.Body className='table-body-bg'>
                                {bookingsData.length === 0 &&
                                    <Table.Row>
                                        <Table.Cell colSpan={tableHeaders.length}>{content.nothing_to_show}</Table.Cell>
                                    </Table.Row>
                                }
                                {bookingsData.map((item, index) => (
                                    <Table.Row key={index}>
                                        {tableFields.map((field) => (
                                            field === 'name' ? (
                                                props.isPreview ? (
                                                    <Table.Cell key={field}>{item[field]}</Table.Cell>
                                                ) : (
                                                    <Table.Cell key={field}><Link to={`/business/${item.id}`}>{handleValue(field, item)}</Link></Table.Cell>
                                                )
                                            ) : (
                                                (field === 'isActive' || field === 'isUserActive') ? (
                                                    <Table.Cell key={field}>{handleStatus(handleValue(field, item))}</Table.Cell>
                                                ) : (
                                                    field === 'business.name' ? (
                                                        <Table.Cell key={field}><Link className='table-link' to={`/business/preview/${formatName(item.business.name)}/${item.businessId}`}>{handleValue(field, item)}</Link></Table.Cell>
                                                    ) : (
                                                        field === 'servicesNames' ? (
                                                            <Table.Cell key={field}>{formatServiceNames(handleValue(field, item))}</Table.Cell>
                                                        ) : (
                                                            field === 'duration' ? (
                                                                <Table.Cell key={field}>{formatTime(handleValue(field, item))}</Table.Cell>
                                                            ) : field === 'amount' ? (
                                                                <Table.Cell key={field}>{formatAmount(item[field])}</Table.Cell>
                                                            ) : (
                                                                <Table.Cell key={field}>{handleValue(field, item)}</Table.Cell>
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        ))
                                        }
                                        {item.isActive === 1 &&
                                            <Table.Cell>
                                                <div className="icon-grid">
                                                    <BookingPreviewModal isClient={true} item={item} component={<Icon title='Ver' size='large' color='blue' name='eye' />} />
                                                    {isDate1Greater(item.date, currentDate) &&
                                                        <ConfirmModal item={item} handleClick={handleClick}
                                                            action={`${content.bookings.delete_action} '${item.date} ${item.time}';${item.business.name}; '${formatServiceNames(item.servicesNames)}'; '${formatTime(item.duration)}'?`}
                                                            component={<Icon title={content.delete} size='large' color='red' name='delete' />} />
                                                    }
                                                </div>
                                            </Table.Cell>
                                        }
                                        {item.isActive === 0 &&
                                            <Table.Cell>
                                                <div className="icon-grid">
                                                    {isDate1Greater(item.date, currentDate) &&
                                                        showAcceptBookingButton(item)
                                                    }
                                                    <BookingPreviewModal isClient={true} item={item} component={<Icon title={content.view} size='large' color='blue' name='eye' />} />
                                                    {isDate1GreaterOrEqual(item.date, currentDate) &&
                                                        <BookingModal handleModal={handleModal} item={item} isClient={true} component={<Icon title={content.edit} size='large' color='yellow' name='edit' />} />
                                                    }
                                                    {isDate1GreaterOrEqual(item.date, currentDate) &&
                                                        <ConfirmModal item={item} handleClick={handleClick}
                                                            action={`${content.bookings.delete_action} '${item.date} ${item.time}';${item.business.name}; '${formatServiceNames(item.servicesNames)}'; '${formatTime(item.duration)}'; '${item.amount}'?`}
                                                            component={<Icon title={content.delete} size='large' color='red' name='delete' />} />
                                                    }
                                                </div>
                                            </Table.Cell>
                                        }
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </div>
                    {bookingsData.length === 50 &&
                        <Button id='show-bookings-btn' circular className='primary-color-btn' onClick={handleShowMoreBtn}><Icon name='undo' />Mostrar Mais</Button>
                    }
                </Container>
                <Footer />
            </Container>
        )
    }
}

export default UserBookings
import React from 'react'
import { Segment } from 'semantic-ui-react'
import ImageCarousel from './ImageCarousel'
import Footer from '../../Global/Footer'
import OurBrand from './OurBrand'
import LastestUpdates from './LastUpdates'
import { translateContent } from '../../../resources/lib'
import image1 from '../../../resources/carousel/test1.png'
import image2 from '../../../resources/carousel/test2.png'
import image3 from '../../../resources/carousel/test3.png'
import image4 from '../../../resources/carousel/test4.png'
import image5 from '../../../resources/carousel/test5.png'
import image6 from '../../../resources/carousel/test6.png'
import TypeChoice from './TypeChoice'
import LastBusinesses from './LastBusinesses'

const carouselItems = [
    {
        id: 0,
        src: image1,
    },
    {
        id: 1,
        src: image2,
    },
    {
        id: 2,
        src: image3,
    },
    {
        id: 3,
        src: image4,
    },
    {
        id: 4,
        src: image5,
    },
    {
        id: 5,
        src: image6,
    }
]

const HomeClient = () => {
    const content = translateContent(localStorage.getItem('Language'))

    return (
        <>
            <ImageCarousel carouselItems={carouselItems} carouselData={content.carouselClient} />
            <TypeChoice />
            <LastBusinesses />
            <Segment id='container-no-margin' basic style={{ position: 'relative', overflow: 'hidden', height: '200px' }}>
                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'transparent', zIndex: 1, clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)' }}></div>
                <div className='divider-container' style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 2, clipPath: 'polygon(0 0, 100% 0, 0% 100%)' }}></div>
            </Segment>
            {localStorage.getItem('UserInfo') &&
                <LastestUpdates />
            }
            {!localStorage.getItem('UserInfo') &&
                <OurBrand />
            }
            <Footer />
        </>
    )
}

export default HomeClient
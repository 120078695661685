export const emailTitlesPT = {
  accountCreated: `Reservado - Nova Conta Criada`,
  businessCreated: `Reservado - Nova Empresa Criada`,
  bookingCreated: `Reservado - Reserva Criada`,
  bookingConfirmed: `Reservado - Reserva Confirmada`,
  bookingWarning: `Reservado - Lembrete de Reserva`,
  bookingFeedback: `Reservado - Avaliação`,
  bookingAccepted: `Reservado - Reserva Aceite pelo Cliente`,
  bookingUpdated: `Reservado - Reserva Atualizada`,
  accountDeleted: `Reservado - Conta eliminada`,
  businessDeleted: `Reservado - Empresa eliminada`,
  bookingDeleted: `Reservado - Reserva Cancelada`
}

export const emailTextPT = {
  accountCreated: 'Esta conta de accType foi criada - fullAccName(accEmail).',
  businessCreated: 'Esta empresa foi criada - fullAccName, a empresa(businessName)(accEmail).',
  bookingCreated: 'A reserva em nome de fullAccName, na empresa(businessName) está criada e espera confirmação!',
  bookingConfirmed: 'A reserva em nome de fullAccName, na empresa(businessName) está confirmada!',
  bookingWarning: 'Não se esqueça da sua reserva para bookingDate, na empresa(businessName).',
  bookingFeedback: 'Clique no link abaixo para partilhar connosco a sua experiência.; A sua opinião é essencial para continuarmos a aprimorar o nosso serviço.;feedback/businessId/guid',
  bookingAccepted: 'A reserva em nome de fullAccName, na empresa(businessName) foi aceite pelo cliente e está pronta para ser revista!',
  bookingUpdated: 'A reserva em nome de fullAccName, na empresa(businessName) foi atualizada!',
  accountDelete: 'Esta conta de accType está a ser eliminada! - fullAccName; Em caso de problema ou erro contacta o suporte.',
  businessDeleted: 'fullAccName, a tua empresa(businessName) está a ser eliminada!; Em caso de problema ou erro contacta o suporte.',
  bookingDeleted: 'A reserva em nome de fullAccName, na empresa(businessName) foi cancelada!'
}

export const errorMessagesPT = {
  contact_support: 'Ocorreu um erro, por favor, contacte o suporte.',
  login_contact_support: 'Ocorreu um erro na validação dos dados, contacte com o suporte.',
  generic_error: 'Ocorreu um erro.',
  account_not_active: 'A sua conta ainda não está ativa.',
  account_doesnt_exist: 'A sua conta não existe.',
  verify_robot: 'Por favor, confirme que não é um robô.',
  booking_doesnt_exist: 'A reserva não existe.',
  change_password: 'Erro ao enviar o email para alterar a senha.',
  booking_creation_failed: 'Falha ao criar a reserva, contacte com o suporte.',
  service_creation_failed: 'Falha ao criar o serviço, contacte com o suporte.',
  service_images_creation_failed: 'Falha ao criar as imagens do serviço, contacte com o suporte.',
  service_images_delete_failed: 'Falha ao apagar as imagens do serviço, contacte com o suporte.',
  login_invalid_email: 'Endereço de e-mail inválido.',
  login_user_disabled: 'Esta conta foi desativada.',
  login_user_not_found: 'Não existe conta para este e-mail.',
  login_wrong_pword: 'Senha incorreta.',
  login_wrong_cred: 'Email ou Senha incorretos.',
  login_too_many: 'O acesso a esta conta foi temporariamente desativado devido a muitas tentativas de início de sessão falhadas.',
  failed_to_create_booking_confirm_email: 'Ocorreu um erro, na criação do email de confirmação da reserva.',
  failed_to_create_booking_warning_email: 'Ocorreu um erro, na criação do email de aviso da reserva.',
  failed_to_create_booking_delete_email: 'Ocorreu um erro, na criação do email de eliminação da reserva.',
  failed_to_create_feedback_token: 'Ocorreu um erro, na criação do token de feedback.',
  failed_to_create_feedback_email: 'Ocorreu um erro, na criação do email de feedback da reserva.',
  failed_to_update_booking_status: 'Falha ao atualizar o estado da reserva.',
  failed_to_delete_feedback_token: 'Ocorreu um erro, na eliminação do token de feedback.',
  failed_to_delete_booking_emails: 'Ocorreu um erro, na eliminação todos os emails desta reserva.',
  failed_to_delete_booking: 'Falha a apagar a reserva.',
  failed_to_delete_rating: 'Falha a apagar a rating.',
  failed_to_create_rating: 'Falha a criar a rating.',
  register: 'Falha a criar a conta.',
  register_same_email: 'Se quiser usar o mesmo e-mail de outra conta acountType, precisará de usar a mesma senha.',
  register_email_creation: 'Ocorreu um erro, na criação do email de criação de conta.',
  register_license_creation: 'Ocorreu um erro, na criação da licença de conta.',
  register_account_creation: 'Ocorreu um erro, na criação de conta.',
  worktimes_error: 'A hora de fecho não pode ser menor que a hora de abertura.',
  business_error: 'Já existe uma Empresa com este nome criada.',
}

export const otherErrorMessagesPT = {

}

export const successMessagesPT = {
  business_deleted: 'Empresa eliminada.',
  updated: 'Atualizado com sucesso!',
  booking_deleted: 'Reserva eliminada.',
  status_updated: 'Estado atualizado.',
  service_deleted: 'Serviço eliminado.',
  menu_deleted: 'Prato eliminado.',
  social_deleted: 'Rede social eliminada.',
  pMethod_deleted: 'Método de pagamento eliminado.',
  group_deleted: 'Grupo de serviços eliminado.',
  menu_group_deleted: 'Menu eliminado.',
  work_time_deleted: 'Horário eliminado.',
  booking_confirmed_by_business: 'A reserva foi confirmada pela empresa.',
  password_reset_email_sent: 'Será enviado um email para que possa alterar a sua senha.',
  password_reset_finished: 'A sua senha foi reposta com sucesso.',
  created: 'Criado com sucesso!'
}

export function supportAccountCreationEmailPT(accountType, firstName, lastName, email) {
  const type = accountType === 'client' ? 'Utilizador' : 'Empresa'
  return {
    title: 'Reservado - Reserva Criada',
    body: `Esta conta de ${type} foi criada - ${firstName} ${lastName}(${email}).`
  }
}

export function supportBusinessCreationEmailPT(ownerFirstName, ownerLastName, businessEmail, businessName) {
  return {
    title: `Reservado - Empresa Criada`,
    body: `Esta empresa foi criada - ${ownerFirstName} ${ownerLastName}(${businessEmail}), a empresa(${businessName}).`
  }
}

export function accountUpdateStatusEmailPT(status, firstName, lastName, email) {
  return {
    title: `Reservado - Conta ${(status ? "Ativa" : "Inativa")}`,
    body: `${firstName} ${lastName}(${email}), a tua conta está ${(status ? "ativa" : "inativa")}!; ${(status ? "Podes começar a utilizar o Reservado." : "Em caso de problema ou error contacta o suporte.")}`
  }
}

export function accountDeleteEmailPT(accountType, firstName, lastName, email) {
  const type = accountType === 'client' ? 'Utilizador' : 'Empresa'
  return {
    title: `Reservado - Conta eliminada`,
    body: `Esta conta de ${type} foi eliminada - ${firstName} ${lastName}(${email}).`
  }
}

export function businessUpdateStatusEmailPT(status, ownerFirstName, ownerLastName, businessEmail, businessName) {
  return {
    title: `Reservado - Empresa ${(status ? "Ativa" : "Inativa")}`,
    body: `${ownerFirstName} ${ownerLastName}(${businessEmail}), a tua empresa(${businessName}) está ${(status ? "ativa" : "inativa")}!; ${(status ? "Podes começar a geri-la no Reservado." : "Em caso de problema ou error contacta o suporte.")}`
  }
}

export function businessDeleteEmailPT(ownerFirstName, ownerLastName, businessEmail, businessName) {
  return {
    title: `Reservado - Empresa eliminada`,
    body: `${ownerFirstName} ${ownerLastName}(${businessEmail}), a tua empresa(${businessName}) está a ser eliminada!; Em caso de problema ou erro contacta o suporte.`
  }
}

export function bookingCreationEmailPT(clientFistName, clientLastName, clientEmail, businessEmail, businessName) {
  return {
    title: `Reservado - Reserva Criada`,
    body: `A reserva em nome de ${clientFistName} ${clientLastName}(${clientEmail}), na empresa(${businessName}(${businessEmail})) está criada e espera confirmação!`
  }
}

export function bookingUpdateStatusEmailPT(status, clientFirstname, clientLastName, clientEmail, businessEmail, businessName) {
  return {
    title: `Reservado - Reserva ${(status ? "Confirmada" : "Cancelada")}`,
    body: `A reserva em nome de ${clientFirstname} ${clientLastName}(${clientEmail}), na empresa(${businessName}(${businessEmail})) está ${(status ? "confirmada" : "cancelada")}!`
  }
}

export function bookingUpdateEmailPT(clientFirstname, clientLastName, clientEmail, businessEmail, businessName, isService = false) {
  return {
    title: `Reservado - Reserva Atualizada`,
    body: `A reserva em nome de ${clientFirstname} ${clientLastName}(${clientEmail}), na empresa(${businessName}(${businessEmail})) foi atualizada!
    ${(isService ? "Um dos serviços já não está disponível." : "")}`
  }
}

export function bookingDeleteEmailPT(clientFirstname, clientLastname, clientEmail, businessEmail, businessName, isService = false) {
  return {
    title: `Reservado - Reserva Cancelada`,
    body: `A reserva em nome de ${clientFirstname} ${clientLastname}(${clientEmail}), na empresa(${businessName}(${businessEmail})) foi cancelada!
    ${(isService ? "Este serviço já não está disponível." : "")}`
  }
}

export function bookingUserAccpetEmailPT(clientFirstname, clientLastname, clientEmail, businessEmail, businessName) {
  return {
    title: `Reservado - Reserva Aceite pelo Cliente`,
    body: `A reserva em nome de ${clientFirstname} ${clientLastname}(${clientEmail}), na empresa(${businessName}(${businessEmail})) foi aceite pelo cliente e está pronta para ser revista!`
  }
}

export function bookingRememberEmailPT(bookingDate, bookingTime, businessEmail, businessName) {
  return {
    title: `Reservado - Lembrete de Reserva - ${bookingDate} ${bookingTime}`,
    body: `Não se esqueça da sua reserva para ${bookingDate} ${bookingTime}, na empresa(${businessName}(${businessEmail})).`
  }
}

export const pMethodsOptionsPT = [
  'Dinheiro',
  'PayPal',
  'MBWay',
  'Cartão de Crédito'
]

export const colorOptionsPT = [
  'vermelho',
  'laranja',
  'amarelo',
  'verde-oliva',
  'verde',
  'verde-azulado',
  'azul',
  'violeta',
  'roxo',
  'rosa',
  'castanho',
  'cinzento',
  'preto'
]

export const iconOptionsPT = [
  'acesso universal',
  'seta circular alternada para direita',
  'caret para direita',
  'caret quadrado para direita',
  'chevron para direita',
  'mão apontando para direita',
  'seta longa alternada para direita',
  'seta de localização',
  'avançar rápido',
  'avançar',
  'música',
  'reproduzir',
  'círculo de reprodução',
  'podcast',
  'microfone',
  'círculo',
  'vídeo',
  'parar',
  'volume do telefone',
  'pausar',
  'fones de ouvido',
  'agenda de endereços',
  'cartão de endereço',
  'arquivo',
  'prédio',
  'balança de justiça',
  'livro',
  'maleta',
  'gráfico de linha',
  'bússola',
  'lápis alternado',
  'clip de papel',
  'bússola',
  'bússola sem preenchimento',
  'calendário alternado',
  'alvo',
  'salvar',
  'tarefas',
  'etiqueta',
  'xadrez',
  'cavalo de xadrez',
  'peão de xadrez',
  'rainha de xadrez',
  'rei de xadrez',
  'utilizador secreto',
  'café',
  'comentário alternado',
  'telefone móvel alternado',
  'telefone móvel',
  'avião de papel',
  'arroba',
  'comentários',
  'envelope',
  'desktop',
  'laptop',
  'símbolo do dólar',
  'símbolo do euro',
  'cédula de dinheiro alternada',
  'ampulheta',
  'cronômetro',
  'ampulheta final',
  'olho',
  'cortar',
  'mira',
  'tonalidade',
  'pincel',
  'aperto de mão',
  'polegar para cima',
  'ambulância',
  'coração',
  'batimento cardíaco',
  'kit médico',
  'kit de primeiros socorros',
  'médico',
  'quadrado com sinal de mais',
  'estetoscópio',
  'raio',
  'câmera',
  'câmera retro',
  'conta-gotas',
  'imagem',
  'marca de seleção',
  'círculo com marca de seleção',
  'cerveja',
  'pesquisar',
  'estrela',
  'utilizador',
  'remover círculo',
  'remover',
  'comida'
]

export const categoriesPT = [
  { nameEN: 'Lawyer', namePT: 'Advogado/a' },
  { nameEN: 'Psychologist', namePT: 'Psicólogo/a' },
  { nameEN: 'Musician', namePT: 'Músico/a' },
  { nameEN: 'Personal Trainer', namePT: 'Personal Trainer' },
  { nameEN: 'Veterinarian', namePT: 'Veterinário/a' },
  { nameEN: 'Plumber', namePT: 'Canalizador' },
  { nameEN: 'Dentist', namePT: 'Dentista' },
  { nameEN: 'Automobile Workshop', namePT: 'Oficina de automóveis' },
  { nameEN: 'Beauty Salon', namePT: 'Salão de Beleza' },
  { nameEN: 'Car Wash', namePT: 'Lavagem de Carros' },
  { nameEN: 'Furniture assembly', namePT: 'Montagem de Móveis' },
  { nameEN: 'Carpenter', namePT: 'Carpinteiro' },
  { nameEN: 'Spa', namePT: 'Spa' },
  { nameEN: 'Barbershop', namePT: 'Barbeiro' },
  { nameEN: 'Photographer', namePT: 'Fotógrafo/a' },
  { nameEN: 'Electrician', namePT: 'Eletricista' },
  { nameEN: 'Tattoo Studio', namePT: 'Estúdio de Tatuagens' },
  { nameEN: 'Beautician', namePT: 'Estéticista' },
  { nameEN: 'Nutricionist', namePT: 'Nutricionista' },
  { nameEN: 'Coaching', namePT: 'Coaching' },
  { nameEN: 'Pet Sitter', namePT: 'Pet Sitter' },
  { nameEN: 'Boat Tours', namePT: 'Passeios de barco' },
  { nameEN: 'Architect', namePT: 'Arquiteto/a' },
  { nameEN: 'Cleaning', namePT: 'Limpezas' },
]

export const breadcrumbsPT = [
  { nameEN: 'Business', namePT: 'Empresa' },
  { nameEN: 'Bookings', namePT: 'Reservas' },
  { nameEN: 'Agenda', namePT: 'Agenda' },
  { nameEN: 'Preferences', namePT: 'Preferências' },
  { nameEN: 'My Account', namePT: 'Minha Conta' },
  { nameEN: 'Delete Account', namePT: 'Apagar Conta' },
  { nameEN: 'My Account', namePT: 'Minha Conta' },
  { nameEN: 'My Account', namePT: 'Minha Conta' },
]

export const contentPT = {
  menu: {
    terms: 'Termos e Condições',
    businesses: 'Empresas',
    guide: 'Guia',
    agenda: 'Agenda',
    bookings: 'Reservas',
    login: 'Entrar/Criar Conta',
    lang: 'Idioma',
    drop: {
      login: 'Entrar',
      create: 'Criar Conta',
      my_account: 'Minha Conta',
      logout: 'Sair'
    }
  },
  home: {
    title: 'Bem-vindo ao Reservado, onde podes tratar as tuas reservas por tu!'
  },
  select_account: {
    title: 'Tipo de Conta',
    client_title: 'Cliente',
    client_text: 'Tipo de conta que vai poder criar reservas nas empresas disponíveis na aplicação.',
    bussiness_title: 'Empresa',
    business_text: 'Tipo de conta que vai possibilitar gerir reservas feitas pelos clientes nas suas empresas.',
    button: 'Escolher'
  },
  login: {
    title: 'Entrar',
    email_label: 'Email',
    email_placeholder: 'exmeplo@exemplo.com',
    password_label: 'Senha',
    password_placeholder: '*****',
    password_button_message_show: 'Mostrar Senha',
    password_button_message_hide: 'Esconder Senha',
    button: 'Entrar'
  },
  reset_password: {
    title: 'Repôr Senha',
    email_label: 'Email',
    email_placeholder: 'example@example.com',
    password_label: 'Senha',
    password_placeholder: '*****',
    button: 'Repôr'
  },
  register: {
    title: 'Criar Conta',
    first_name_label: 'Nome',
    first_name_placeholder: 'Nome',
    last_name_label: 'Apelido',
    last_name_placeholder: 'Apelido',
    birth_day_label: 'Data de Nascimento',
    email_label: 'Email',
    email_placeholder: 'exmeplo@exemplo.com',
    password_label: 'Senha',
    password_placeholder: '*****',
    password_button_message_show: 'Mostrar Senha',
    password_button_message_hide: 'Esconder Senha',
    address_label: 'Morada',
    address_placeholder: 'Morada',
    city_label: 'Cidade',
    city_placeholder: 'Cidade',
    postal_label: 'Código Postal',
    postal_placeholder: '****-***',
    contact_label: 'Contato',
    contact_placeholder: '9********',
    button: 'Criar'
  },
  register_success: {
    title: 'Criar Conta',
    subtitle: 'A tua conta foi criada com sucesso e está a ser verificada.',
    message: 'Quando receberes um email a dizer que a tua conta está ativa vais poder entrar!'
  },
  businesses: {
    title: 'Empresas',
    table_headers_text: ['Nome', 'Email', 'Contato', 'Morada', 'Cidade', 'Cód.Postal', 'Categorias', 'Website', 'Estado', 'Ações'],
    table_headers_values: ['name', 'email', 'contact', 'address', 'city', 'postalCode', 'categories', 'website', 'isActive'],
    table_short_headers_text: ['Nome', 'Contato', 'Cidade', 'Cód.Postal', 'Estado', 'Ações'],
    table_short_headers_values: ['name', 'contact', 'city', 'postalCode', 'isActive'],
    table_headers_preview_text: ['Nome', 'Email', 'Contato', 'Morada', 'Cidade', 'Cód.Postal', 'Categorias', 'Website', 'Link para Reservas', 'Ações'],
    table_headers_preview_values: ['name', 'email', 'contact', 'address', 'city', 'postalCode', 'categories', 'website', 'link'],
    table_short_headers_preview_text: ['Nome', 'Contato', 'Cidade', 'Cód.Postal', 'Link para Reservas', 'Ações'],
    table_short_headers_preview_values: ['name', 'contact', 'city', 'postalCode', 'link'],
    link_warning_services: 'Adiciona horário e serviços ativos primeiro',
    link_warning_menus: 'Adiciona horário e pratos ativos primeiro',
    link_text: 'Abrir link',
    delete_action: 'Apagar a empresa -',
    create_first_business_title: 'Cria a tua primeira empresa!',
    create_first_business_info: 'Quando estiver ativa, receberás um email para poderes configurá-la.',
    button: 'Adicionar Empresa'
  },
  business_preview: {
    title: 'Informação Básica',
    work_times_title: 'Horário',
    socials_title: 'Redes Sociais',
    pMethods_title: 'Métodos de Pagamento',
    folders_title: 'Grupos de Serviços',
    menus_title: 'Grupos de Ementas',
    sidebar: {
      agenda: 'Agenda',
      bookings: 'Reservas',
      services: 'Serviços',
      menus: 'Pratos',
      prefs: 'Preferências'
    }
  },
  work_times: {
    table_headers_text: ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo', 'Ações'],
    table_headers_values: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
    closed: 'Fechado',
    delete_action: 'Apagar o horário de trabalho?',
    button: 'Adicionar Horário'
  },
  socials: {
    table_headers_text: ['Nome', 'Link', 'Ações'],
    table_headers_values: ['name', 'url'],
    delete_action: 'Apagar a rede social -',
    button: 'Adicionar Redes Sociais'
  },
  pMethods: {
    table_headers_text: ['Nome', 'Ações'],
    table_headers_values: ['name'],
    delete_action: 'Apagar Método de Pagamento -',
    button: 'Adicionar Método de Pagamento'
  },
  folders: {
    table_headers_text: ['Nome', 'Ações'],
    table_headers_values: ['name'],
    delete_action: 'Apagar Grupo de Serviço -',
    button_services: 'Adicionar Grupo de Serviço',
    button_menus: 'Adicionar Grupo de Ementa'
  },
  bookings: {
    business_table_headers_text: ['Data', 'Hora', 'Nome', 'Contacto', 'Serviços', 'Duração', 'Tipo', 'Aceite pelo cliente', 'Aceite pela Empresa', 'Ações'],
    business_table_headers_values: ['date', 'time', 'fullName', 'contact', 'servicesNames', 'duration', 'isManual', 'isUserActive', 'isActive'],
    business_table_headers_menu_text: ['Data', 'Hora', 'Nome', 'Contacto', 'Nº Pessoas', 'Tipo', 'Aceite pelo cliente', 'Aceite pela Empresa', 'Ações'],
    business_table_headers_menu_values: ['date', 'time', 'fullName', 'contact', 'amount', 'isManual', 'isUserActive', 'isActive'],
    business_short_table_headers_text: ['Data', 'Hora', 'Nome', 'Tipo', 'Aceite pelo cliente', 'Aceite pela Empresa', 'Ações'],
    business_short_table_headers_values: ['date', 'time', 'fullName', 'isManual', 'isUserActive', 'isActive'],
    client_table_headers_text: ['Data', 'Hora', 'Empresa', 'Contato', 'Serviços', 'Duração', 'Nº Pessoas', 'Aceite por ti', 'Aceite pela Empresa', 'Ações'],
    client_table_headers_values: ['date', 'time', 'business.name', 'business.contact', 'servicesNames', 'duration', 'amount', 'isUserActive', 'isActive'],
    client_short_table_headers_text: ['Data', 'Hora', 'Empresa', 'Aceite por ti', 'Aceite pela Empresa', 'Ações'],
    client_short_table_headers_values: ['date', 'time', 'business.name', 'isUserActive', 'isActive'],
    type_online: 'Online',
    tyep_internal: 'Interna',
    current_button: 'Reservas Atuais',
    all_button: 'Todas as Reservas',
    delete_action: 'Apagar a reserva -',
    button: 'Adicionar Reserva'
  },
  agenda: {
    button: 'Hoje',
    closed: 'Fechado'
  },
  services: {
    table_headers_text: ['Icone', 'Nome', 'Preço', 'Duração', 'Estado', 'Ações'],
    table_headers_values: ['icon', 'name', 'price', 'time', 'isActive'],
    switch_on: 'Ativar',
    switch_off: 'Desativar',
    delete_action: 'Apagar o serviço -',
    button: 'Adicionar Serviço'
  },
  menus: {
    table_headers_text: ['Icone', 'Nome', 'Preço', 'Estado', 'Ações'],
    table_headers_values: ['icon', 'name', 'price', 'isActive'],
    switch_on: 'Ativar',
    switch_off: 'Desativar',
    delete_action: 'Apagar o prato -',
    button: 'Adicionar Prato'
  },
  clientBusinessServicePreview: {
    free_text: 'Grátis',
    allServices_folder: 'Todos os Serviços',
    allMenus_folder: 'Todos os Pratos'
  },
  feedback: {
    title: 'Formulário de Avaliação',
    rating_label: 'Como classifica a sua experiência?',
    description_label: 'Escreva uma breve descrição do que achou da sua experiência',
    button: 'Submeter'
  },
  carouselBusiness: {
    headers: [
      'Fácil',
      'Sem burocracia',
      'Double check',
      'Sem desconforto',
      'Informação é poder',
      'Bora!'
    ],
    descriptions: [
      'Com a nossa aplicação, pequenos empresários/empresas podem finalmente ter um controlo fácil sobre as suas reservas.',
      'Empresas podem registar-se na plataforma e submeter os seus serviços, e depois compartilhar um link direto com os seus clientes para que estes possam reservar os seus serviços sem burocracia!',
      'Temos um sistema de reservas de dois lados, o que significa que tanto a empresa quanto o cliente precisam confirmar a data e hora escolhidas antes da reserva ser confirmada.',
      'Isso significa que as reservas só são feitas quando ambas as partes concordam - nada de surpresas desagradáveis.',
      'Além disso, a nossa aplicação envia atualizações por e-mail para manter todos os envolvidos informados sobre as próximas reservas.',
      'Portanto se está á procura de uma maneira fácil e eficiente de gerir as suas reservas e manter os seus clientes felizes, dê uma oportunidade à nossa aplicação!'
    ]
  },
  carouselClient: {
    headers: [
      'Reserve com Facilidade',
      'Descubra Novos Locais',
      'Reservas Instantâneas',
      'Explore a Diversidade',
      'Planeie o Seu Dia',
      'Feedback Valorizado'
    ],
    descriptions: [
      'Faça as suas reservas de forma simples e rápida, sem complicações. Com apenas alguns cliques, garanta o seu lugar nos seus locais favoritos.',
      'Explore uma variedade de estabelecimentos e descubra novos locais para comer, relaxar ou desfrutar de atividades. Encontre o ambiente perfeito para cada ocasião.',
      'Não perca tempo em filas ou à espera. Faça as suas reservas de forma instantânea e garanta a sua presença nos melhores lugares, sem demoras.',
      'Desfrute da diversidade de opções que a nossa plataforma oferece. Desde restaurantes acolhedores a spas relaxantes, há algo para todos os gostos e necessidades.',
      'Organize o seu dia com antecedência, reservando os seus compromissos e atividades com facilidade. Planeie refeições, tratamentos e momentos de lazer de forma conveniente.',
      'Partilhe a sua opinião e ajude as empresas a melhorarem os seus serviços. O seu feedback é valioso e contribui para uma experiência ainda melhor para todos os utilizadores.'
    ]
  },
  typeChoice: {
    client: 'É um cliente?',
    business: 'É uma empresa?',
    yesClient: 'Sim, sou um Cliente!',
    yesBusiness: 'Sim, sou um Empresa!'
  },
  lastBusiness: {
    title: 'Últimas Empresas adicionadas'
  },
  mission: {
    title: 'A nossa missão de ajudar negócios locais a conquistar o seu espaço no mundo digital',
    text: 'Na era digital, queremos ser o parceiro confiável que ajuda os negócios locais a terem uma presença online. Capacitamos as empresas com ferramentas para melhorar a gestão de reservas e aumentar sua visibilidade. A nossa missão é ser a ponte que leva esses negócios ao sucesso.',
    join_us_text: 'Junte-se a nós e faça parte do crescimento!',
    join_us_button: 'Crie a sua conta já!'
  },
  brand: {
    meaning_title: 'Descubra o significado por trás do nome da nossa marca',
    meaning_text_1: 'é o nome que carrega em si o compromisso de oferecer um lugar especial para cada negócio local, possibilitando uma presença online marcante e uma gestão mais eficiente de reservas. É a plataforma que conecta empreendedores aos seus clientes, ampliando horizontes e encurtando distâncias.',
    meaning_text_2: 'é a porta de entrada para uma nova era de negócios locais, onde cada um tem o seu espaço, sua identidade e a oportunidade de crescer e florescer na era digital.',
    logo_title: 'O significado por trás do nosso logotipo',
    logo_text: 'Uma representação visual da nossa missão e valores. Com uma casa de férias à beira-mar como ícone, ele evoca sentimentos de tranquilidade, férias e descanso que é o que nós queremos para os nossos utilizadores.'
  },
  features: {
    title: 'Aproveite as Nossas Vantagens Únicas',
    names: [
      'Facilitar a gestão de reservas de clientes',
      'Oferecer uma plataforma de reservas fácil de usar',
      'Permitir que os clientes façam reservas em tempo real',
      'Fornecer notificações automáticas para os clientes e as empresas sobre as reservas',
      'Permitir que as empresas personalizem as suas páginas e perfil de acordo com as suas necessidades'
    ]
  },
  packages: {
    title: 'Explore os nossos pacotes',
    lists: [
      {
        name: 'Básico',
        text: [
          "Ideal para empresas que estão a dar os primeiros passos na gestão de reservas online.",
          "Permite configurar a sua empresa e mostrar a sua presença online com uma página personalizada.",
          "Gerencie os horários de trabalho e serviços disponíveis.",
          "Acesso à agenda para acompanhar as reservas.",
          "Sem reservas online ou alertas de email."
        ]
      },
      {
        name: 'Premium',
        text: [
          "Para empresas que buscam uma solução completa para a gestão de reservas.",
          "Inclui todas as funcionalidades do Pacote Básico.",
          "Capacidade de receber reservas online através da página personalizada da empresa.",
          "Alertas por email para manter os clientes informados sobre as suas reservas.",
          "Feedback dos clientes para melhorar a experiência."
        ]
      },
      {
        name: 'Restauração',
        text: [
          "Especialmente concebido para restaurantes e empresas de restauração.",
          "Oferece todas as funcionalidades do Pacote Premium.",
          "Personalização avançada para pratos e menus.",
          "Opções de reserva específicas para o setor de restauração.",
          "Gestão eficaz de reservas para otimizar a experiência do cliente."
        ]
      }

    ]
  },
  target_clients: {
    title1: 'Descubra a solução perfeita para o seu negócio',
    title2: 'e registe-se gratuitamente!',
    names: [
      'Barbearia',
      'Advogado',
      'Oficina de Automóveis',
      'Nutricionista',
      'Psicólogo',
      'Eletricista',
      'Carpinteiro',
      'Canalizador',
      'Montagem de Móveis',
      'Músico',
      'Personal Trainer',
      'Fotógrafo'
    ],
    more_text: 'E mais...'
  },
  last_updates: {
    titles: [
      'Grande atualização de tema 2.0',
      'Empresas agora podem escolher a categoria "Restauração"',
      'Serviços tem intervalos de preços',
      'Serviços separados por Grupos',
      'Informação sobre os métodos de pagamento aceites',
      'Serviços disponíveis na cloud',
      'Integração com Redes Sociais',
      'Grande atualização de tema',
      'Limitação de categoria removida',
      'Ação de visualizar detalhes da reserva',
      'Visualização detalhada do serviço',
      'Aprimoramentos na criação de reservas',
      'Melhorias na página de criar e atualizar serviços',
      'Nova página de seleção de tipo de conta',
      'Novo logo'
    ],
    descriptions: [
      'The theme has been updated to make the application visually more appealing and contemporary.',
      'From now on, companies can be included in the "Restoration" category.',
      'O tema foi atualizado para tornar a aplicação visualmente mais atraente e contemporânea.',
      'A partir de agora, as empresas podem ser incluídas na categoria "Restauração".',
      'Os serviços agora podem ter seus preços definidos em intervalos, oferecendo maior flexibilidade na configuração de valores',
      'Agora, com a nova funcionalidade de grupos de serviços, fica ainda mais fácil para os clientes encontrarem exatamente o que procuram.',
      'As empresas agora têm a opção de informar claramente aos clientes quais métodos de pagamento são aceitos, facilitando as transações.',
      'A infraestrutura da nossa aplicação foi completamente migrada para a cloud, garantindo um aumento significativo na confiabilidade e desempenho do serviço!',
      'As empresas podem agora colocar os links para as suas redes sociais para que os clientes possam seguir o seu trabalho por lá.',
      'O esquema de cores agora utiliza as cores principais presentes no nosso logotipo.',
      'Empresas podem ter uma ou mais categorias a partir de agora.',
      'Na tabela de reservas, adicionamos a opção de "Ver" os detalhes da reserva, para que você possa obter informações importantes com facilidade.',
      'Agora, ao clicar no nome do serviço na página da empresa, você pode ver todos os detalhes do serviço em uma visualização completa.',
      'Adicionamos uma caixa de texto para informações adicionais à reserva, permitindo que os clientes forneçam detalhes extras necessários para a empresa onde estão fazendo a reserva.',
      'Agora, a informação é apresentada de forma clara e organizada, e adicionamos uma caixa de texto para a descrição do produto, permitindo que você detalhe melhor o seu serviço.',
      'Agora, ao criar uma conta ou fazer login, poderá escolher facilmente o tipo de conta que melhor se adequa às suas necessidades. Com a nossa nova página, tornamos o processo ainda mais intuitivo e personalizado.',
      'Atualizamos a nossa imagem para refletir a simplicidade e inovação da nossa plataforma de reservas.'
    ],
    show_more_button: 'Mostrar ultimas atualizações',
    show_less_button: 'Ocultar ultimas atualizações'
  },
  modals: {
    business: {
      title_new: 'Nova Empresa',
      title_update: 'Atualizar Empresa',
      name_label: 'Nome',
      name_placeholder: 'Nome',
      categories_label: 'Categorias',
      categories_drop_default: 'Categorias',
      email_label: 'Email',
      email_placeholder: 'exmeplo@exemplo.com',
      address_label: 'Morada',
      address_placeholder: 'Morada',
      city_label: 'Cidade',
      city_placeholder: 'Cidade',
      postal_label: 'Código Postal',
      postal_placeholder: '****-***',
      contact_label: 'Contato',
      contact_placeholder: '9********',
      website_label: 'Website',
      website_placeholder: 'https://exemplo.pt'
    },
    work_times: {
      title_new: 'Novo Horário',
      title_update: 'Atualizar Horário',
      week_day_names: ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'],
      open_label: 'Abertura',
      close_label: 'Fecho'
    },
    services: {
      title_new: 'Novo Serviço',
      title_update: 'Atualizar Serviço',
      baisc_info_subtitle: 'Infomação Básica',
      name_label: 'Nome',
      name_placeholder: 'Nome',
      price_label: 'Preço',
      priceInput: {
        exact: 'Valor',
        fromto: 'De',
        upto: 'Até',
        from: 'A partir de'
      },
      duration_label: 'Duração',
      folder_label: 'Grupo',
      folder_empty_text: 'Sem grupo',
      description_label: 'Descrição',
      description_placeholder: 'Escreve uma breve descrição do teu serviço...',
      images_subtitle: 'Imagens',
      icon_subtitle: 'Personalizar Icone',
      icon_label: 'Icone',
      color_label: 'Cor',
      icon_preview_text: 'Vista do Icone'
    },
    menus: {
      title_new: 'Novo Prato',
      title_update: 'Atualizar Prato',
      baisc_info_subtitle: 'Infomação Básica',
      name_label: 'Nome',
      name_placeholder: 'Nome',
      price_label: 'Preço',
      priceInput: {
        exact: 'Valor',
        fromto: 'De',
        upto: 'Até',
        from: 'A partir de'
      },
      folder_label: 'Grupo',
      folder_empty_text: 'Sem grupo',
      description_label: 'Descrição',
      description_placeholder: 'Escreve uma breve descrição do teu prato...',
      images_subtitle: 'Imagens',
      icon_subtitle: 'Personalizar Icone',
      icon_label: 'Icone',
      color_label: 'Cor',
      icon_preview_text: 'Vista do Icone'
    },
    socials: {
      title_new: 'Nova Rede Social',
      title_update: 'Atualizar Rede Social',
      social_label: 'Rede Social',
      social_drop_default: 'Rede Social',
      link_label: 'Link',
      link_placeholder: 'https://exemplo.pt'
    },
    pMethods: {
      title_new: 'Novo Método de Pagamento',
      pMethod_label: 'Método de Pagamento',
      pMethod_drop_default: 'Método de Pagamento'
    },
    folders: {
      title_new_services: 'Novo Grupo de Serviços',
      title_update_services: 'Atualizar Grupo de Serviços',
      title_new_menus: 'Novo Grupo de Ementas',
      title_update_menus: 'Atualizar Grupo de Ementas',
      name_label: 'Nome'
    },
    service_preview: {
      title: 'Informação do Serviço',
      price_label: 'Preço',
      duration_label: 'Duração',
      description_label: 'Descrição',
      without_description_text: 'Sem descrição.',
      carousel_title: 'Imagens',
      button: 'Fechar'
    },
    business_preview: {
      title: 'Informação da Empresa',
      without_website_text: 'Website não está configurado.',
      button: 'Fechar'
    },
    booking: {
      title_new: 'Nova Reserva',
      title_update: 'Atualizar Reserva',
      date_label: 'Data',
      hour_label: 'Hora',
      hour_drop_text: 'Hora',
      amount_label: 'Nº Pessoas',
      services_label: 'Serviços',
      services_drop_text: 'Serviços',
      duration_label: 'Duração',
      add_info_label: 'Informação Adicional',
      add_info_placeholder: 'Escreve aqui informação adicional sobre a reserva...',
      booking_details_subtitle: 'Detalhes da Reserva',
      client_details_subtitle: 'Detalhes do Cliente',
      first_name_label: 'Nome',
      first_name_placeholder: 'Nome',
      last_name_label: 'Apelido',
      last_name_placeholder: 'Apelido',
      email_label: 'Email',
      email_placeholder: 'exmeplo@exemplo.com',
      contact_label: 'Contato',
      contact_placeholder: '9********',
      not_available: 'Indisponível neste dia'
    },
    booking_preview: {
      title: 'Informação da Reserva',
      business_label: 'Empresa',
      client_label: 'Cliente',
      date_label: 'Data',
      hour_label: 'Hora',
      contact_label: 'Contato',
      service_label: 'Serviços',
      duration_label: 'Duração',
      amount_label: 'Nº Pessoas',
      add_info_label: 'Informação Adicional',
      without_add_info_text: 'Sem informação adicional.',
      book_again: 'Reservar Novamente!'
    },
    confirm: {
      yes: 'Sim',
      no: 'Não'
    },
    close: 'Fechar',
    cancel: 'Cancelar',
    save: 'Gravar',
    remove: 'Remover'
  },
  client_business_preview: {
    client_business: {
      button: 'Reserva Já!',
      socials_title: 'Redes Sociais',
      pMethods_tile: 'Métodos de Pagamento'
    },
    work_times: {
      title: 'Horário',
      week_day_names: ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'],
      closed: 'Fechado'
    },
    services: {
      title: 'Serviços'
    },
    menus: {
      title: 'Pratos'
    }
  },
  new_business: {
    title_new: 'Nova Empresa',
    name_label: 'Nome',
    name_placeholder: 'Nome',
    categories_label: 'Categorias',
    categories_drop_default: 'Categorias',
    email_label: 'Email',
    email_placeholder: 'exmeplo@exemplo.com',
    address_label: 'Morada',
    address_placeholder: 'Morada',
    city_label: 'Cidade',
    city_placeholder: 'Cidade',
    postal_label: 'Código Postal',
    postal_placeholder: '****-***',
    contact_label: 'Contato',
    contact_placeholder: '9********',
    website_label: 'Website',
    website_placeholder: 'https://exemplo.pt',
    cancel: 'Cancelar',
    save: 'Gravar'
  },
  new_booking: {
    title: 'Nova Reserva',
    date_label: 'Data',
    hour_label: 'Hora',
    hour_drop_text: 'Hora',
    amount_label: 'Nº de Pessoas',
    services_label: 'Serviços',
    services_drop_text: 'Serviços',
    duration_label: 'Duração',
    add_info_label: 'Informação Adicional',
    add_info_placeholder: 'Escreve aqui informação adicional sobre a reserva...',
    not_available: 'Indisponível neste dia',
    button: 'Reservar',
    services: {
      title: 'Serviços'
    },
    menus: {
      title: 'Pratos'
    }
  },
  my_account: {
    title: 'Minha Conta',
    warning_message: 'Qualquer alteração no perfil irá necessitar de reinicio de sessão',
    sidebar: {
      change_password: 'Repôr Senha',
      basic_info: 'Informação Básica',
      delete_account: 'Apagar Conta'
    },
    change_password: {
      title: 'Repôr Senha',
      button: 'Repôr'
    },
    basic_info: {
      title: 'Informação Básica',
      first_name_label: 'Nome',
      first_name_placeholder: 'Nome',
      last_name_label: 'Apelido',
      last_name_placeholder: 'Apelido',
      day_of_birth_label: 'Data de Nascimento',
      address_label: 'Morada',
      address_placeholder: 'Morada',
      city_label: 'Cidade',
      city_placeholder: 'Cidade',
      postal_label: 'Código Postal',
      postal_placeholder: '****-***',
      contact_label: 'Contato',
      contact_placeholder: '9********',
      edit_button: 'Editar',
      cancel_button: 'Cancelar',
      save_button: 'Gravar'
    },
    delete_account: {
      title: 'Apagar Conta',
      button: 'Apagar'
    }
  },
  footer: {
    title: 'Fala connosco por aqui',
    support_button: 'Nosso Suporte'
  },
  multi_drop: {
    results_text: 'Sem resultados...'
  },
  image_input_gird: {
    message: 'Arraste e solte ou clique para carregar'
  },
  start_component: {
    title: 'Avaliação'
  },
  priceOptions: [
    { key: 'exact', text: 'Preço exato', value: 'exact' },
    { key: 'upto', text: 'Preço até', value: 'upto' },
    { key: 'from', text: 'Preço a partir de', value: 'from' },
    { key: 'range', text: 'Preço entre valores', value: 'range' },
  ],
  nothing_to_show: 'Nada para mostrar...',
  view: 'Ver',
  edit: 'Editar',
  delete: 'Apagar',
  accept: 'Aceitar',
  hour: 'hora',
  hours: 'horas',
  minute: 'minuto',
  minutes: 'minutos'
}
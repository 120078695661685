import React from 'react';
import { getAuth, signOut, sendPasswordResetEmail } from 'firebase/auth'
import { auth } from '../../../firebase';
import { Button, Container, Segment, Header } from 'semantic-ui-react';
import { LogErrors, decrytptString, destroyStorage, handleReload, notifyError, readValueInObjectInLocalStorage, translateContent, translateErrorMessages } from '../../../resources/lib';
import Breadcrumbs from '../../Global/Breadcrumbs';

const EmailPasswordUpdate = (props) => {
    const content = translateContent(localStorage.getItem('Language'))
    const errorMessages = translateErrorMessages(localStorage.getItem('Language'))

    async function handlePasswordReset(event) {
        event.preventDefault()
        auth = getAuth()
        sendPasswordResetEmail(auth, auth.currentUser.email)
            .then(() => {
                const isClient = decrytptString(readValueInObjectInLocalStorage('Type', 'type')) === 'client'
                destroyStorage()
                signOut(auth)
                window.location.pathname = isClient ? '/' : '/home/business'
                handleReload()
            })
            .catch((error) => {
                LogErrors(error)
                notifyError(errorMessages.change_password)
            })
    }

    return (
        <Container fluid>
            <Breadcrumbs list={[content.my_account.title, content.my_account.change_password.title]} />
            <Container>
                <Segment basic>
                    <Header inverted className='huge-text shadow-text' as='h1'>{content.my_account.change_password.title}</Header>
                </Segment>
                <div className='my-profile-size'>
                    <Segment basic>
                        <Button circular size='large' className='secondary-color-btn' onClick={handlePasswordReset} >{content.my_account.change_password.button}</Button>
                    </Segment>
                </div>
            </Container>
        </Container>
    )
}

export default EmailPasswordUpdate
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { auth } from '../../../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth'
import { Button, Container, Divider, Form, Header, Input, Segment } from 'semantic-ui-react';
import { saveValueInLocalStorage, notifyError, encryptString, translateContent, translateErrorMessages, LogErrors } from '../../../resources/lib';
import { getDataBy2Wheres, isLogged } from '../../../resources/firebaseLib';
import { Link, Navigate, useParams } from 'react-router-dom';
import Footer from '../../Global/Footer';

const Login = () => {
    const { type } = useParams()
    const [formData, setFormData] = useState({ email: '', password: '' })
    const [token, setToken] = useState('')
    const [isVerified, setIsVerified] = useState(process.env.REACT_APP_ENV === 'dev' ? true : false)
    const [showPWord, setShowPWord] = useState(false)

    const content = translateContent(localStorage.getItem('Language'))
    const errorMessages = translateErrorMessages(localStorage.getItem('Language'))

    async function postData(data) {
        try {
            await signInWithEmailAndPassword(auth, data.email, data.password)
            return true
        } catch (error) {
            LogErrors(error)
            switch (error.code) {
                case 'auth/too-many-requests':
                    notifyError(errorMessages.login_too_many)
                    break;
                case 'auth/invalid-email':
                    notifyError(errorMessages.login_invalid_email)
                    break;
                case 'auth/user-disabled':
                    notifyError(errorMessages.login_user_disabled)
                    break;
                case 'auth/user-not-found':
                    notifyError(errorMessages.login_user_not_found)
                    break;
                case 'auth/wrong-password':
                    notifyError(errorMessages.login_wrong_pword)
                    break;
                case 'auth/invalid-credential':
                    notifyError(errorMessages.login_wrong_cred)
                    break;
                default:
                    notifyError(`${errorMessages.generic_error}: `, error.message)
            }
            return false
        }
    }

    async function handleSubmit(event) {
        event.preventDefault()
        try {
            const realType = type === 'client' ? 'client_user' : 'client_business'
            if (isVerified) {
                let result = await postData(formData)
                if (result) {
                    if (isLogged(auth)) {
                        const date = new Date()
                        date.setHours(date.getHours() + 4)
                        const userData = await getDataBy2Wheres(realType, 'uid', 'isDelete', auth.currentUser.uid, 0)
                        if (userData.length > 0) {
                            if (userData[0].isActive > 0) {
                                const dbData = userData[0]
                                const dataToSave = {
                                    user: {
                                        firstName: dbData.firstName,
                                        lastName: dbData.lastName,
                                        token: encryptString(auth.currentUser.uid)
                                    }
                                }
                                saveValueInLocalStorage('UserInfo', dataToSave)
                                saveValueInLocalStorage('Type', {
                                    type: encryptString(type)
                                })
                                saveValueInLocalStorage('SessionTime', date)
                                if (localStorage.getItem('BookingUrl')) {
                                    window.location.href = localStorage.getItem('BookingUrl')
                                }
                                else
                                {
                                    window.location.pathname = type === 'client' ? "/" : "/home/business"
                                }
                            }
                            else {
                                notifyError(errorMessages.account_not_active)
                            }
                        }
                        else {
                            notifyError(errorMessages.account_doesnt_exist)
                        }
                    }
                    else
                        notifyError(errorMessages.login_contact_support)
                }
            }
            else {
                notifyError(errorMessages.verify_robot)
            }
        }
        catch (error) {
            LogErrors(error)
            notifyError(errorMessages.contact_support)
        }
    }

    function handleInputChange(event) {
        const { name, value } = event.target
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))
    }

    function handleVerify(token) {
        setToken(token)
        setIsVerified(true)
    }

    function handleExpired() {
        setToken('')
        setIsVerified(false)
    }

    if (localStorage.getItem('UserInfo'))
        return <Navigate to='/' />

    return (
        <Container fluid>
            <Segment basic className='space-segment'>
                <Header id='page-title' inverted className='huge-text shadow-text' as='h1'>{content.login.title}</Header>
                <Divider />
            </Segment>
            <Container fluid>
                <Container className='half-view'>
                    <div className='forms-small-box'>
                        <Form size='big' onSubmit={handleSubmit}>
                            <Form.Field required>
                                <label className='big-text shadow-text brown-text'>{content.login.email_label}</label>
                                <Input icon='user' iconPosition='left' type='email' maxLength="100" placeholder={content.login.email_placeholder} name="email" value={formData.email} onChange={handleInputChange} required />
                            </Form.Field>
                            {!showPWord &&
                                <Form.Field required>
                                    <label className='big-text shadow-text brown-text'>{content.login.password_label}</label>
                                    <Input icon='lock' iconPosition='left' action={
                                        <Button id='pword-btn' circular className='third-back-color' title={content.login.password_button_message_show} icon='eye' onClick={() => setShowPWord(!showPWord)}></Button>
                                    }
                                        type='password' minLength="8" maxLength="20" placeholder={content.login.password_placeholder} name="password" value={formData.password} onChange={handleInputChange} required />
                                </Form.Field>
                            }
                            {showPWord &&
                                <Form.Field required>
                                    <label className='big-text shadow-text brown-text'>{content.login.password_label}</label>
                                    <Input icon='lock' iconPosition='left' action={
                                        <Button id='pword-btn' circular className='third-back-color' title={content.login.password_button_message_hide} icon='eye slash' onClick={() => setShowPWord(!showPWord)}></Button>
                                    }
                                        type='text' minLength="8" maxLength="20" placeholder={content.login.password_placeholder} name="password" value={formData.password} onChange={handleInputChange} required />
                                </Form.Field>
                            }
                            {process.env.REACT_APP_ENV !== 'dev' &&
                                <Form.Field>
                                    <div className='center-contents'>
                                        <ReCAPTCHA size='compact' sitekey='6LepmogkAAAAALDhmuvQ3yjtIPKfT4QwihCdbN51' onChange={e => handleVerify(e, token)} onExpired={handleExpired} />
                                    </div>
                                </Form.Field>
                            }
                            <Form.Field>
                                <Link to='/recover' className='copy-link'>Esqueceu-se da sua senha?</Link>
                            </Form.Field>
                            <Button id='login-btn' circular size='big' className='secondary-color-btn' type='submit'>{content.login.button}</Button>
                        </Form>
                    </div>
                </Container>
            </Container>
            <Footer />
        </Container>
    )
}

export default Login
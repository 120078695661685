import React, { useState } from 'react'
import { Button, Container, Grid, Header, Icon, Image, Segment } from 'semantic-ui-react'

//Images
import newLogoImage from '../../../resources/other/updates/newLogo.png'
import accountTypeImage from '../../../resources/other/updates/accountType.png'
import bookingViewImage from '../../../resources/other/updates/bookingView.png'
import bookingTextImage from '../../../resources/other/updates/bookingText.png'
import serviceDetailsImage from '../../../resources/other/updates/serviceDetails.png'
import serviceNewDesignImage from '../../../resources/other/updates/serviceNewDesign.png'
import moreCatsImage from '../../../resources/other/updates/moreCats.png'
import newColors from '../../../resources/other/updates/colorsChange.png'
import socialNetworks from '../../../resources/other/updates/socialNetworks.png'
import cloudIntegration from '../../../resources/other/updates/cloudIntegration.png'
import pMethods from '../../../resources/other/updates/pMethods.png'
import serviceFolders from '../../../resources/other/updates/serviceFolders.png'
import servicePriceRanges from '../../../resources/other/updates/servicePriceRanges.png'
import newColors2 from '../../../resources/other/updates/colorsChange2.png'
import restaurantEnabled from '../../../resources/other/updates/restaurantEnabled.png'
import ImageModal from '../Modals/ImageModal'
import { translateContent } from '../../../resources/lib'

const lastestUpdates = [
    {
        image: newColors2,
        date: "04/02/2024",
        size: 'huge'
    },
    {
        image: restaurantEnabled,
        date: "28/01/2024",
        size: 'huge'
    },
    {
        image: servicePriceRanges,
        date: "27/12/2023",
        size: 'huge'
    },
    {
        image: serviceFolders,
        date: "27/12/2023",
        size: 'huge'
    },
    {
        image: pMethods,
        date: "26/12/2023",
        size: 'huge'
    },
    {
        image: cloudIntegration,
        date: "20/10/2023",
        size: 'huge'
    },
    {
        image: socialNetworks,
        date: "05/05/2023",
        size: 'huge'
    },
    {
        image: newColors,
        date: "24/05/2023",
        size: 'huge'
    },
    {
        image: moreCatsImage,
        date: "19/05/2023",
        size: 'huge'
    },
    {
        image: bookingViewImage,
        date: "15/05/2023",
        size: 'huge'
    },
    {
        image: serviceDetailsImage,
        date: "15/05/2023",
        size: 'big'
    },
    {
        image: bookingTextImage,
        date: "15/05/2023",
        size: 'big'
    },
    {
        image: serviceNewDesignImage,
        date: "12/05/2023",
        size: 'big'
    },
    {
        image: accountTypeImage,
        date: "11/05/2023",
        size: 'big'
    },
    {
        image: newLogoImage,
        date: "09/05/2023",
        size: 'medium'
    }
]

const LastestUpdates = () => {
    const [open, setOpen] = useState(false)

    const content = translateContent(localStorage.getItem('Language'))

    return (
        <Container fluid>
            <br />
            <Container>
                {!open &&
                    <Button circular size='massive' className='third-color-btn' onClick={() => setOpen(!open)}><Icon name='eye' />{content.last_updates.show_more_button}</Button>
                }
                {open &&
                    <Button circular size='massive' className='third-color-btn' onClick={() => setOpen(!open)}><Icon name='eye slash' />{content.last_updates.show_less_button}</Button>
                }
                {open &&
                    <Segment basic>
                        <Grid stackable columns='equal' textAlign='center'>
                            {lastestUpdates.map((item, index) => {
                                if (index % 2 === 1)
                                    return (
                                        <Grid.Row key={index} className='center-contents'>
                                            <Grid.Column>
                                                <ImageModal component={<Image size={item.size} src={item.image} centered rounded />} src={item.image} />
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Segment basic>
                                                    <Header as='h2' className='secondary-text-color shadow-text'>{content.last_updates.titles[index]}</Header>
                                                    <p className='large-text brown-text shadow-text'>{content.last_updates.descriptions[index]}</p>
                                                    <p className='large-text brown-text shadow-text'>{item.date}</p>
                                                </Segment>
                                            </Grid.Column>
                                        </Grid.Row>
                                    )
                                else
                                    return (
                                        <Grid.Row key={index} className='center-contents'>
                                            <Grid.Column>
                                                <Segment basic>
                                                    <Header as='h2' className='secondary-text-color shadow-text'>{content.last_updates.titles[index]}</Header>
                                                    <p className='large-text brown-text shadow-text'>{content.last_updates.descriptions[index]}</p>
                                                    <p className='large-text brown-text shadow-text'>{item.date}</p>
                                                </Segment>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <ImageModal component={<Image size={item.size} src={item.image} centered rounded />} src={item.image} />
                                            </Grid.Column>
                                        </Grid.Row>
                                    )
                            })
                            }
                        </Grid>
                    </Segment>
                }
            </Container>
            <br />
        </Container>
    )

}

export default LastestUpdates
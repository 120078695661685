import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Header, Modal } from 'semantic-ui-react';
import { notifyError, formatTime, convertStringArrayIntoNumberArray, getNamesFromIds, removeId, translateContent, translateErrorMessages, translateSuccessMessages, translateEmailTextUpdateBooking, translateEmailTitles, readLocalStorageUserValue, LogErrors } from '../../../resources/lib';
import AppLoader from '../../Global/AppLoader';
import CustomDropdown from '../../Global/Dropdown/Dropdown';
import CustomMultiDropdown from '../../Global/Dropdown/MultiDropdown';
import { createEmail, getDataBy, getDataBy3Wheres, getDocById, updateDataByDocId } from '../../../resources/firebaseLib';

const BookingModal = (props) => {
    const [open, setOpen] = useState(false)
    const [servicesData, setServicesData] = useState(null)
    const [worktimesData, setWorktimesData] = useState(null)
    const [formData, setFormData] = useState({ ...props.item })
    const [onLoad, setOnLoad] = useState(false)
    const [isRestaurant, setIsRestaurant] = useState(false)

    const content = translateContent(localStorage.getItem('Language'))
    const errorMessages = translateErrorMessages(localStorage.getItem('Language'))
    const successMessages = translateSuccessMessages(localStorage.getItem('Language'))

    const getBusinessData = useCallback(async () => {
        setOnLoad(true)
        try {
            const id = props.item.businessId
            const [servicesResponse, worktimesResponse] = await Promise.all([
                getDataBy3Wheres('business_services', 'businessId', 'isActive', 'isDelete', id, 1, 0),
                getDataBy('business_work_times', 'businessId', id),
            ])
            setIsRestaurant(props.item.services.length > 0)
            setServicesData(servicesResponse)
            setWorktimesData(worktimesResponse)
        } catch (error) {
            LogErrors(error)
            notifyError(error)
        }
        finally {
            setOnLoad(false)
        }
    }, [props])

    const restoreValues = useCallback(async () => {
        setFormData(props.item)
    }, [props.item])

    useEffect(() => {
        const fetchData = async () => {
            await getBusinessData()
        }

        if (!open)
            restoreValues()

        fetchData()
    }, [getBusinessData, restoreValues, open])


    async function handleSubmit(event) {
        event.preventDefault()
        if (props.isClient) {
            let result = await getDocById('booking', formData.id)
            if (result === null) {
                notifyError(errorMessages.booking_doesnt_exist)
            }
            else {
                if (result.isActive === 0) {
                    formData.isUserActive = 1
                    result = await updateDataByDocId('booking', formData.id, removeId(formData))
                    if (result) {
                        result = await createEmail(formData.business.email, translateEmailTitles('bookingUpdated'), translateEmailTextUpdateBooking(
                            {
                                businessName: formData.business.name,
                                firstName: readLocalStorageUserValue('firstName'),
                                lastName: readLocalStorageUserValue('lastName')
                            }
                        ))
                        if (result) {
                            setOpen(false)
                            props.handleModal(successMessages.updated)
                        }
                        else {
                            notifyError(errorMessages.contact_support)
                        }
                    }
                    else {
                        notifyError(errorMessages.contact_support)
                    }
                }
                else {
                    props.handleModal(successMessages.booking_confirmed_by_business)
                }
            }
        }
        else {
            formData.isUserActive = formData.isManual === 1 ? 1 : 0
            let result = await updateDataByDocId('booking', formData.id, removeId(formData))
            if (result) {
                result = await createEmail(formData.tempUser.email, translateEmailTitles('bookingUpdated'), translateEmailTextUpdateBooking(
                    {
                        businessName: formData.business.name,
                        firstName: formData.tempUser.firstName,
                        lastName: formData.tempUser.lastName
                    }
                ))
                if (result) {
                    setOpen(false)
                    props.handleModal(successMessages.updated)
                }
                else {
                    notifyError(errorMessages.contact_support)
                }
            }
            else {
                notifyError(errorMessages.contact_support)
            }
        }
    }

    function getMinBookingDate() {
        const date = new Date()
        date.setDate(date.getDate() + 1);
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        const formattedDate = `${year}-${month}-${day}`
        return formattedDate
    }

    function getBookingMinAndMaxTime(currDate) {
        if (currDate !== '') {
            const date = new Date(currDate)
            const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][date.getDay()].toLowerCase()
            const worktimes = worktimesData[0][weekday].split('-')
            return worktimes
        }
        return ''
    }

    function getHoursFromString(time) {
        const split = time.split(':')
        return Number(split[0])
    }

    function getMinutesFromString(time) {
        const split = time.split(':')
        return Number(split[1])
    }

    function getBookingTimes(times) {
        let timeList = []
        if (times !== '') {
            let startTime = getHoursFromString(times[0])
            let startMinutes = getMinutesFromString(times[0])
            let endTime = getHoursFromString(times[1])
            let endMinutes = getMinutesFromString(times[1])
            let endH = endTime
            if (endMinutes > 0)
                endH++
            for (let i = startTime; i < endH; i++) {
                let hour = String(i).padStart(2, '0')
                let startM = startMinutes
                if (i !== startTime)
                    startM = 0

                let endM = 60
                if (i === endTime)
                    endM = endMinutes
                for (let j = startM; j < endM; j += 15) {
                    let minutes = String(j).padStart(2, '0')
                    let exactTime = `${hour}:${minutes}`
                    timeList.push({
                        id: exactTime,
                        name: exactTime
                    })
                }
            }
        }
        return timeList
    }

    function handleDropdown(id) {
        setFormData((prevFormData) => ({ ...prevFormData, time: id }))
    }

    function handleInputChange(event) {
        const { name, value } = event.target
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))
    }

    function handleInputChangeUser(event) {
        const { name, value } = event.target

        setFormData((prevFormData) => ({
            ...prevFormData,
            tempUser: {
                ...prevFormData.tempUser,
                [name]: value,
            },
        }))
    }

    function handleMultiDropdown(services) {
        getChosenServicesData(services)
        setFormData((prevFormData) => ({ ...prevFormData, services: services.sort().join() }))
        setFormData((prevFormData) => ({ ...prevFormData, servicesNames: getNamesFromIds(services.sort(), servicesData) }))
    }

    function getChosenServicesData(services) {
        let data = []
        services.forEach(service => {
            data.push(servicesData.find(element => element.id === service))
        })
        return getServicesTotalDuration(data)
    }

    function getServicesTotalDuration(services) {
        let totalHours = 0
        let totalMins = 0
        services.forEach((service) => {
            let splitTime = service.time.split(':')
            let hours = Number(splitTime[0])
            let mins = Number(splitTime[1])
            totalHours += hours
            totalMins += mins

        })
        const minsToHours = totalMins / 60
        if (minsToHours >= 1) {
            const hoursToAdd = Math.floor(minsToHours)
            totalMins = totalMins - (hoursToAdd * 60)
            totalHours = totalHours + hoursToAdd
        }

        setFormData((prevFormData) => ({ ...prevFormData, duration: `${formatTimeNumber(totalHours)}:${formatTimeNumber(totalMins)}` }))
        return formatServiceDurationText(totalHours, totalMins)
    }

    function formatServiceDurationText(totalHours, totalMins) {
        let answer = ''
        if (totalHours > 0) {
            let hourText = totalHours === 1 ? content.hour : content.hours
            answer += `${totalHours} ${hourText},`
        }

        if (totalMins > 0) {
            let minsText = totalMins === 1 ? content.minute : content.minutes
            answer += ` ${totalMins} ${minsText}`
        }

        if (answer.length === 0)
            answer = '-'

        return answer
    }

    function formatTimeNumber(value) {
        return String(value).padStart(2, '0')
    }

    function handleOnlineBookingButtons() {
        const selectedDate = new Date(formData.date)
        const currentDate = new Date()
        if ((formData.date !== '' && selectedDate > currentDate && getBookingMinAndMaxTime(formData.date)[0] !== '') && ((formData.time !== '' && formData.services !== '') || formData.amount > 0))
            return <Button
                circular
                type='submit'
                content={content.modals.save}
                labelPosition='right'
                icon='checkmark'
                id='modal-accept-btn'
                className='primary-color-active-btn'
            />

        if (!((formData.date !== '' && selectedDate > currentDate && getBookingMinAndMaxTime(formData.date)[0] !== '') && ((formData.time !== '' && formData.services !== '') || formData.amount > 0)))
            return <Button
                disabled
                circular
                type='submit'
                content={content.modals.save}
                labelPosition='right'
                icon='checkmark'
                id='modal-accept-btn'
                className='primary-color-active-btn' />
    }

    function handleManualBookingButtons() {
        if (formData.date !== '' &&
            formData.time !== '' &&
            (formData.services !== '' || formData.amount > 0) &&
            formData.tempUser.firstName !== '' &&
            formData.tempUser.lastName !== '' &&
            formData.tempUser.email !== '' &&
            formData.tempUser.contacto !== '')
            return <Button
                circular
                type='submit'
                content={content.modals.save}
                labelPosition='right'
                icon='checkmark'
                id='modal-accept-btn'
                className='primary-color-active-btn'
            />

        if (!(formData.date !== '' &&
            formData.time !== '' &&
            (formData.services !== '' || formData.amount > 0) &&
            formData.tempUser.firstName !== '' &&
            formData.tempUser.lastName !== '' &&
            formData.tempUser.email !== '' &&
            formData.tempUser.contacto !== ''))
            return <Button
                disabled
                circular
                type='submit'
                content={content.modals.save}
                labelPosition='right'
                icon='checkmark'
                id='modal-accept-btn'
                className='primary-color-active-btn' />
    }

    const currDate = getMinBookingDate()

    if (onLoad)
        <AppLoader />

    if (open && !worktimesData)
        <AppLoader />

    if (!open || !worktimesData)
        return (
            <Modal
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                trigger={props.component}
            >
                {open &&
                    <>
                        <Modal.Header id='modal-title' className='black-back-color bigger-text shadow-text'>{content.modals.booking.title_update}</Modal.Header>
                        <Modal.Content className='forms-box primary-inverted-degra-color'>
                            <div className='forms-box'>
                                <Form size='big' onSubmit={e => handleSubmit(e)}>
                                    <Form.Group widths='equal'>
                                        <Form.Field>
                                            <label className='big-text shadow-text brown-text'>{content.modals.booking.date_label}</label>
                                            <input type="date" min={currDate} name="date" value={formData.date} onChange={handleInputChange} required />
                                        </Form.Field>
                                    </Form.Group>
                                </Form>
                            </div>
                            <Button circular color='black' onClick={() => setOpen(false)}>
                                {content.modals.cancel}
                            </Button>
                        </Modal.Content>
                    </>
                }
            </Modal>
        )

    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={props.component}
        >
            <Modal.Header id='modal-title' className='black-back-color bigger-text shadow-text'>{content.modals.booking.title_update}</Modal.Header>
            <Modal.Content scrolling className='forms-box secondary-back-color'>
                <div className='forms-box'>
                    <Form size='big' onSubmit={handleSubmit}>
                        {formData.isManual === 1 &&
                            <Header id='basic-info-subtitle' inverted className='bigger-text shadow-text'>{content.modals.booking.booking_details_subtitle}</Header>
                        }
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label className='big-text shadow-text brown-text'>{content.modals.booking.date_label}</label>
                                <input type="date" min={currDate} name="date" value={formData.date} onChange={handleInputChange} required />
                            </Form.Field>
                            {getBookingMinAndMaxTime(formData.date)[0] !== undefined && getBookingMinAndMaxTime(formData.date)[0] !== '' &&
                                <Form.Field>
                                    <label className='big-text shadow-text brown-text' htmlFor="search-input">{content.modals.booking.hour_label}</label>
                                    <CustomDropdown data={getBookingTimes(getBookingMinAndMaxTime(formData.date))} text={content.modals.booking.hour_drop_text} handleDropdown={handleDropdown}
                                        default={formData.time} />
                                </Form.Field>
                            }
                            {getBookingMinAndMaxTime(formData.date)[0] === '' &&
                                <Form.Field>
                                    <label className='big-text shadow-text brown-text'>{content.modals.booking.hour_label}</label>
                                    <input type="time" name="time" disabled />
                                    <Header as='h5' color='red'>{content.modals.booking.not_available}</Header>
                                </Form.Field>
                            }
                        </Form.Group>
                        {isRestaurant &&
                            <Form.Group widths='equal'>
                                {getBookingMinAndMaxTime(formData.date)[0] !== undefined && getBookingMinAndMaxTime(formData.date)[0] !== '' &&
                                    <Form.Field>
                                        <label className='big-text shadow-text brown-text'>{content.modals.booking.services_label}</label>
                                        <CustomMultiDropdown data={servicesData} text={content.modals.booking.services_drop_text} handleDropdown={handleMultiDropdown}
                                            default={convertStringArrayIntoNumberArray(formData.services.split(','))} />
                                    </Form.Field>
                                }
                                {getBookingMinAndMaxTime(formData.date)[0] !== undefined && getBookingMinAndMaxTime(formData.date)[0] !== '' &&
                                    <Form.Field>
                                        <label className='big-text shadow-text brown-text'>{content.modals.booking.duration_label}</label>
                                        <input name="total-time" value={formatTime(formData.duration)} disabled />
                                    </Form.Field>
                                }
                            </Form.Group>
                        }
                        {!isRestaurant &&
                            <Form.Group widths='equal'>
                                {getBookingMinAndMaxTime(formData.date)[0] !== undefined && getBookingMinAndMaxTime(formData.date)[0] !== '' &&
                                    <Form.Field required>
                                        <label className='big-text shadow-text brown-text'>{content.modals.booking.amount_label}</label>
                                        <input type="number" name="amount" value={formData.amount} onChange={handleInputChange} />
                                    </Form.Field>
                                }
                            </Form.Group>
                        }
                        {(getBookingMinAndMaxTime(formData.date)[0] !== undefined && getBookingMinAndMaxTime(formData.date)[0] !== '') &&
                            <Form.Field>
                                <label className='big-text shadow-text brown-text'>{content.modals.booking.add_info_label}</label>
                                <Form.TextArea
                                    name="notes"
                                    value={formData.notes}
                                    onChange={handleInputChange}
                                    maxLength={500}
                                    placeholder={content.modals.booking.add_info_placeholder}
                                />
                                <div style={{ textAlign: 'right' }}>{formData.notes.length} / 500</div>
                            </Form.Field>
                        }
                        {formData.isManual === 1 &&
                            <Header id='basic-info-subtitle' inverted className='bigger-text shadow-text'>{content.modals.booking.client_details_subtitle}</Header>
                        }
                        {formData.isManual === 1 &&
                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <label className='big-text shadow-text brown-text'>{content.modals.booking.first_name_label}</label>
                                    <input placeholder={content.modals.booking.first_name_placeholder} maxLength="30" name="firstName" value={formData.tempUser.firstName} onChange={handleInputChangeUser} required />
                                </Form.Field>
                                <Form.Field>
                                    <label className='big-text shadow-text brown-text'>{content.modals.booking.last_name_label}</label>
                                    <input placeholder={content.modals.booking.last_name_placeholder} maxLength="30" name="lastName" value={formData.tempUser.lastName} onChange={handleInputChangeUser} required />
                                </Form.Field>
                            </Form.Group>
                        }
                        {formData.isManual === 1 &&
                            <Form.Group widths='equal'>
                                <Form.Field>
                                    <label className='big-text shadow-text brown-text'>{content.modals.booking.email_label}</label>
                                    <input type='email' placeholder={content.modals.booking.email_placeholder} name="email" maxLength='100' value={formData.tempUser.email} onChange={handleInputChangeUser} required />
                                </Form.Field>
                                <Form.Field>
                                    <label className='big-text shadow-text brown-text'>{content.modals.booking.contact_label}</label>
                                    <input placeholder={content.modals.booking.contact_placeholder} name='contact' value={formData.tempUser.contact} onChange={handleInputChangeUser}
                                        maxLength='9'
                                        pattern='[0-9]{9}'
                                        title='ex: 961234567 or 221123456'
                                        required />
                                </Form.Field>
                            </Form.Group>
                        }
                        <Button
                            type='button'
                            circular
                            id='modal-cancel-btn'
                            className='third-color-active-btn'
                            onClick={() => setOpen(false)}>
                            {content.modals.cancel}
                        </Button>
                        {formData.isManual === 1 &&
                            handleManualBookingButtons()
                        }
                        {formData.isManual === 0 &&
                            handleOnlineBookingButtons()
                        }
                    </Form>
                </div>
            </Modal.Content>
        </Modal>
    )
}

export default BookingModal
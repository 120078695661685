import React from 'react';
import { Container, Divider, Header, Icon, Segment } from 'semantic-ui-react';
import Footer from '../../Global/Footer';
import { translateContent } from '../../../resources/lib';

const RegisterSuccess = () => {
    const content = translateContent(localStorage.getItem('Language'))

    return (
        <>
            <Container fluid className='half-view primary-degra-color'>
                <Segment basic className='space-segment'>
                    <Header id='page-title' inverted className='huge-text shadow-text' as='h1'>{content.register_success.title}</Header>
                    <Divider />
                </Segment>
                <Container>
                    <Segment basic textAlign='center'>
                        <Segment inverted color='green'>
                            <Container className="section-box">
                                <div className="notice-box">
                                    <Header id='message-header' className='huge-text' as='h1' inverted>{content.register_success.subtitle}</Header>
                                </div>
                                <div className="notice-inside-box secondary-back-color">
                                    <Header id='message' as='h2'>{content.register_success.message}</Header>
                                    <Icon size='massive' name='check' />
                                </div>
                            </Container>
                        </Segment>
                    </Segment>
                </Container>
            </Container>
            <Footer />
        </>
    )
}

export default RegisterSuccess
import React, { useEffect, useState } from 'react'
import { Button, Container, Divider, Form, Header, Segment } from 'semantic-ui-react'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { LogErrors, actionWithDelay, addDateDays, decrytptString, formatTime, getBookingEmptyObject, getCurrentDate, getFolderTabs, notifyError, notifySuccess, readLocalStorageUserValue, readValueInLocalStorage, translateContent, translateEmailTextNewBooking, translateEmailTitles, translateErrorMessages, translateSuccessMessages } from '../../../resources/lib'
import CustomDropdown from '../../Global/Dropdown/Dropdown'
import ClientBusinessServicePreview from './ClientBusinessServicesPreview'
import AppLoader from '../../Global/AppLoader';
import CustomMultiDropdown from '../../Global/Dropdown/MultiDropdown'
import NoLoginError from './NoLoginError';
import Footer from '../../Global/Footer';
import { addDataToCollection, collectionNames, createEmail, getDataBy, getDataBy3Wheres, getDocById } from '../../../resources/firebaseLib';

const CreateBook = () => {
    const location = useLocation()
    const { id } = useParams()
    const [businessData, setBusinessData] = useState(null)
    const [servicesData, setServicesData] = useState(null)
    const [menusData, setMenusData] = useState(null)
    const [worktimesData, setWorktimesData] = useState(null)
    const [formData, setFormData] = useState(getBookingEmptyObject(id))
    const queryParams = new URLSearchParams(location.search)
    const isValidLicense = queryParams.get('vtoken')

    const content = translateContent(localStorage.getItem('Language'))
    const successMessages = translateSuccessMessages(localStorage.getItem('Language'))
    const errorMessages = translateErrorMessages(localStorage.getItem('Language'))

    async function populateCollection(data) {
        try {
            return await addDataToCollection('booking', data)
        } catch (error) {
            LogErrors(error)
            return false
        }
    }

    async function postData(data) {
        try {
            let result = await populateCollection(data)
            if (result) {
                result = await createEmail(businessData.email, translateEmailTitles('bookingCreated'), translateEmailTextNewBooking(
                    {
                        businessName: businessData.name,
                        firstName: readLocalStorageUserValue('firstName'),
                        lastName: readLocalStorageUserValue('lastName'),
                        email: businessData.email
                    }
                ))
                if (result) {
                    notifySuccess(successMessages.created)
                    actionWithDelay("/bookings/today")
                }
            }
            else {
                notifyError(errorMessages.booking_creation_failed)
            }
        } catch (error) {
            LogErrors(error)
            notifyError(errorMessages.contact_support)
        }
    }

    useEffect(() => {
        if (localStorage.getItem('UserInfo')) {
            const accType = decrytptString(readValueInLocalStorage('Type', 'type'))
            if (accType === 'client') {
                localStorage.removeItem('BookingUrl')
                const fetchData = async () => {
                    try {
                        const [businessResponse, servicesResponse, menusResponse, worktimesResponse, foldersResponse, menusFoldersResponse] = await Promise.all([
                            getDocById('business', id),
                            getDataBy3Wheres('business_services', 'businessId', 'isActive', 'isDelete', id, 1, 0),
                            getDataBy3Wheres('business_menus', 'businessId', 'isActive', 'isDelete', id, 1, 0),
                            getDataBy('business_work_times', 'businessId', id),
                            getDataBy('business_folders', 'businessId', id),
                            getDataBy('business_menus_folders', 'businessId', id)
                        ])
                        const business = businessResponse
                        setBusinessData(businessResponse)
                        setServicesData(getFolderTabs(foldersResponse, servicesResponse))
                        setMenusData(getFolderTabs(menusFoldersResponse, menusResponse))
                        setWorktimesData(worktimesResponse)
                        const client = await getDataBy(collectionNames.client_account, 'uid', decrytptString(readLocalStorageUserValue('token')))
                        formData.business = {
                            name: business.name,
                            id: business.id,
                            contact: business.contact,
                            email: business.email
                        }
                        formData.tempUser = {
                            firstName: client[0].firstName,
                            lastName: client[0].lastName,
                            contact: client[0].contact,
                            email: client[0].email
                        }
                    } catch (error) {
                        LogErrors(error)
                        notifyError(error)
                    }
                }
                fetchData()
            }
        }
    }, [id, formData])

    function handleSubmit(event) {
        event.preventDefault()
        postData(formData)
    }

    function handleInputChange(event) {
        const { name, value } = event.target
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }))
    }

    function getBookingMinAndMaxTime(currDate) {
        if (currDate !== '') {
            const date = new Date(currDate)
            const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][date.getDay()].toLowerCase()
            const worktimes = worktimesData[0][weekday].split('-')
            return worktimes
        }
        return ''
    }

    function getHoursFromString(time) {
        const split = time.split(':')
        return Number(split[0])
    }

    function getMinutesFromString(time) {
        const split = time.split(':')
        return Number(split[1])
    }

    function getBookingTimes(times) {
        let timeList = []
        if (times !== '') {
            let startTime = getHoursFromString(times[0])
            let startMinutes = getMinutesFromString(times[0])
            let endTime = getHoursFromString(times[1])
            let endMinutes = getMinutesFromString(times[1])
            let endH = endTime
            if (endMinutes > 0)
                endH++
            for (let i = startTime; i < endH; i++) {
                let hour = String(i).padStart(2, '0')
                let startM = startMinutes
                if (i !== startTime)
                    startM = 0

                let endM = 60
                if (i === endTime)
                    endM = endMinutes
                for (let j = startM; j < endM; j += 15) {
                    let minutes = String(j).padStart(2, '0')
                    let exactTime = `${hour}:${minutes}`
                    timeList.push({
                        id: exactTime,
                        name: exactTime
                    })
                }
            }
        }
        return timeList
    }

    function handleDropdown(id) {
        setFormData((prevFormData) => ({ ...prevFormData, time: id }))
    }

    function handleMultiDropdown(services) {
        getChosenServicesData(services)
        setFormData((prevFormData) => ({ ...prevFormData, services: services.sort().join() }))
        setFormData((prevFormData) => ({ ...prevFormData, servicesNames: getServiceNamesFromIds(services.sort()) }))
    }

    function getServiceNamesFromIds(services) {
        let data = []
        services.forEach(service => {
            const info = servicesData[servicesData.length - 1].services.find(element => element.id === service)
            data.push(info.name)
        })
        let answer = ''
        data.forEach((item, index) => {
            if (index === 0)
                answer += `${item}`
            else
                answer += `;${item}`
        })
        return answer
    }

    function getChosenServicesData(services) {
        let data = []
        services.forEach(service => {
            data.push(servicesData[servicesData.length - 1].services.find(element => element.id === service))
        })
        return getServicesTotalDuration(data)
    }

    function getServicesTotalDuration(services) {
        let totalHours = 0
        let totalMins = 0
        services.forEach((service) => {
            let splitTime = service.time.split(':')
            let hours = Number(splitTime[0])
            let mins = Number(splitTime[1])
            totalHours += hours
            totalMins += mins

        })
        const minsToHours = totalMins / 60
        if (minsToHours >= 1) {
            const hoursToAdd = Math.floor(minsToHours)
            totalMins = totalMins - (hoursToAdd * 60)
            totalHours = totalHours + hoursToAdd
        }

        setFormData((prevFormData) => ({ ...prevFormData, duration: `${formatTimeNumber(totalHours)}:${formatTimeNumber(totalMins)}` }))
        return formatServiceDurationText(totalHours, totalMins)
    }

    function formatServiceDurationText(totalHours, totalMins) {
        let answer = ''
        if (totalHours > 0) {
            let hourText = totalHours === 1 ? content.hour : content.hours
            answer += `${totalHours} ${hourText},`
        }

        if (totalMins > 0) {
            let minsText = totalMins === 1 ? content.minute : content.minutes
            answer += ` ${totalMins} ${minsText}`
        }

        if (answer.length === 0)
            answer = '-'

        return answer
    }

    function formatTimeNumber(value) {
        return String(value).padStart(2, '0')
    }

    function vtokenIsValid(token) {
        const targetDate = new Date(token)
        const now = new Date()
        const dateBefore = new Date(now.getTime() - 15 * 60000)
        const dateAfter = new Date(now.getTime() + 15 * 60000)
        return targetDate >= dateBefore && targetDate <= dateAfter
    }

    if (!vtokenIsValid(decrytptString(isValidLicense)))
        return <Navigate to='/' />

    const currDate = addDateDays(getCurrentDate(), 1)
    if (!localStorage.getItem('UserInfo'))
        return <NoLoginError />

    const accType = decrytptString(readValueInLocalStorage('Type', 'type'))
    if (accType !== 'client')
        return <Navigate to='/' />
    if ((!servicesData && !menusData) || !worktimesData)
        return <AppLoader />
    if (localStorage.getItem('UserInfo')) {
        if ((servicesData[servicesData.length - 1].services.length === 0 && menusData[menusData.length - 1].services.length === 0) || worktimesData.length === 0)
            return <Navigate to='/' />
        else
            return (
                <>
                    <Container fluid className='business-container half-view'>
                        <Segment basic className='space-segment'>
                            <Header id='page-title' inverted className='huge-text shadow-text' as='h1'>{content.new_booking.title}</Header>
                            <Divider />
                        </Segment>
                        <Container>
                            <div className={getBookingMinAndMaxTime(formData.date)[0] !== undefined && getBookingMinAndMaxTime(formData.date)[0] !== '' ?
                                'forms-large-box' : 'forms-tiny-box'}>
                                <Segment basic>
                                    {businessData.isRestaurant &&
                                        <Form size='big' onSubmit={e => handleSubmit(e)}>
                                            <Form.Group widths='equal'>
                                                <Form.Field required>
                                                    <label className='big-text shadow-text brown-text'>{content.new_booking.date_label}</label>
                                                    <input type="date" min={currDate} name="date" value={formData.date} onChange={handleInputChange} required />
                                                </Form.Field>
                                                {getBookingMinAndMaxTime(formData.date)[0] !== undefined && getBookingMinAndMaxTime(formData.date)[0] !== '' &&
                                                    <Form.Field required>
                                                        <label className='big-text shadow-text brown-text'>{content.new_booking.hour_label}</label>
                                                        <CustomDropdown data={getBookingTimes(getBookingMinAndMaxTime(formData.date))} text={content.new_booking.hour_drop_text} handleDropdown={handleDropdown} />
                                                    </Form.Field>
                                                }
                                                {getBookingMinAndMaxTime(formData.date)[0] === '' &&
                                                    <Form.Field>
                                                        <label className='big-text shadow-text brown-text'>{content.new_booking.hour_label}</label>
                                                        <input type="time" name="time" disabled />
                                                        <Header as='h5' color='red'>{content.new_booking.not_available}</Header>
                                                    </Form.Field>
                                                }
                                                {getBookingMinAndMaxTime(formData.date)[0] !== undefined && getBookingMinAndMaxTime(formData.date)[0] !== '' &&
                                                    <Form.Field required>
                                                        <label className='big-text shadow-text brown-text'>{content.new_booking.amount_label}</label>
                                                        <input type="number" name="amount" value={formData.amount} onChange={handleInputChange} />
                                                    </Form.Field>
                                                }
                                            </Form.Group>
                                            {getBookingMinAndMaxTime(formData.date)[0] !== undefined && getBookingMinAndMaxTime(formData.date)[0] !== '' &&
                                                <Form.Field>
                                                    <label className='big-text shadow-text brown-text'>{content.new_booking.add_info_label}</label>
                                                    <Form.TextArea
                                                        name="notes"
                                                        value={formData.notes}
                                                        onChange={handleInputChange}
                                                        maxLength={500}
                                                        placeholder={content.new_booking.add_info_placeholder}
                                                    />
                                                    <div style={{ textAlign: 'right' }}>{formData.notes.length} / 500</div>
                                                </Form.Field>
                                            }
                                            {(formData.date !== '' && formData.time !== '' && formData.amount > 0) &&
                                                <Button circular size='big' className='secondary-color-btn' type='submit'>{content.new_booking.button}</Button>
                                            }
                                            {!(formData.date !== '' && formData.time !== '' && formData.amount > 0) &&
                                                <Button circular size='big' className='secondary-color-btn' type='submit' disabled>{content.new_booking.button}</Button>
                                            }
                                        </Form>
                                    }
                                    {!businessData.isRestaurant &&
                                        <Form size='big' onSubmit={e => handleSubmit(e)}>
                                            <Form.Group widths='equal'>
                                                <Form.Field required>
                                                    <label className='big-text shadow-text brown-text'>{content.new_booking.date_label}</label>
                                                    <input type="date" min={currDate} name="date" value={formData.date} onChange={handleInputChange} required />
                                                </Form.Field>
                                                {getBookingMinAndMaxTime(formData.date)[0] !== undefined && getBookingMinAndMaxTime(formData.date)[0] !== '' &&
                                                    <Form.Field required>
                                                        <label className='big-text shadow-text brown-text'>{content.new_booking.hour_label}</label>
                                                        <CustomDropdown data={getBookingTimes(getBookingMinAndMaxTime(formData.date))} text={content.new_booking.hour_drop_text} handleDropdown={handleDropdown} />
                                                    </Form.Field>
                                                }
                                                {getBookingMinAndMaxTime(formData.date)[0] === '' &&
                                                    <Form.Field>
                                                        <label className='big-text shadow-text brown-text'>{content.new_booking.hour_label}</label>
                                                        <input type="time" name="time" disabled />
                                                        <Header as='h5' color='red'>{content.new_booking.not_available}</Header>
                                                    </Form.Field>
                                                }
                                                {getBookingMinAndMaxTime(formData.date)[0] !== undefined && getBookingMinAndMaxTime(formData.date)[0] !== '' &&
                                                    <Form.Field required>
                                                        <label className='big-text shadow-text brown-text'>{content.new_booking.services_label}</label>
                                                        <CustomMultiDropdown data={servicesData[servicesData.length - 1].services} text={content.new_booking.services_drop_text} handleDropdown={handleMultiDropdown} />
                                                    </Form.Field>
                                                }
                                                {getBookingMinAndMaxTime(formData.date)[0] !== undefined && getBookingMinAndMaxTime(formData.date)[0] !== '' &&
                                                    <Form.Field>
                                                        <label className='big-text shadow-text brown-text'>{content.new_booking.duration_label}</label>
                                                        <input name="total-time" value={formatTime(formData.duration)} disabled />
                                                    </Form.Field>
                                                }
                                            </Form.Group>
                                            {getBookingMinAndMaxTime(formData.date)[0] !== undefined && getBookingMinAndMaxTime(formData.date)[0] !== '' &&
                                                <Form.Field>
                                                    <label className='big-text shadow-text brown-text'>{content.new_booking.add_info_label}</label>
                                                    <Form.TextArea
                                                        name="notes"
                                                        value={formData.notes}
                                                        onChange={handleInputChange}
                                                        maxLength={500}
                                                        placeholder={content.new_booking.add_info_placeholder}
                                                    />
                                                    <div style={{ textAlign: 'right' }}>{formData.notes.length} / 500</div>
                                                </Form.Field>
                                            }
                                            {(formData.date !== '' && formData.time !== '' && formData.services !== '') &&
                                                <Button circular size='big' className='secondary-color-btn' type='submit'>{content.new_booking.button}</Button>
                                            }
                                            {!(formData.date !== '' && formData.time !== '' && formData.services !== '') &&
                                                <Button circular size='big' className='secondary-color-btn' type='submit' disabled>{content.new_booking.button}</Button>
                                            }
                                        </Form>
                                    }
                                </Segment>
                            </div>
                        </Container>
                        <Divider />
                        <Segment basic>
                            <Header id='business-services-subtitle' inverted className='huge-text shadow-text' as='h1'>{businessData.isRestaurant ? content.new_booking.menus.title : content.new_booking.services.title}</Header>
                        </Segment>
                        <ClientBusinessServicePreview data={businessData.isRestaurant ? menusData : servicesData} isRestaurant={businessData.isRestaurant} />
                    </Container>
                    <Footer />
                </>
            )
    }
}

export default CreateBook
import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Header, Segment, Divider } from 'semantic-ui-react';
import { LogErrors, actionWithDelay, decrytptString, getCurrentDateTime, notifyError, notifySuccess, readValueInLocalStorage, translateContent, translateErrorMessages, translateSuccessMessages } from '../../../resources/lib';
import Footer from '../../Global/Footer';
import StarRating from './Feedback/StarRating';
import NoLoginError from './NoLoginError';
import { Navigate, useParams } from 'react-router-dom';
import { addDataToCollection, deleteDataBy, deleteDocById, getDataBy } from '../../../resources/firebaseLib';
import AppLoader from '../../Global/AppLoader';

const ClientFeedbackForm = () => {
    const { businessId, feedbackToken } = useParams()
    const [tokenExists, setTokenExists] = useState(null)
    const [ratingValue, setRatingValue] = useState(0)
    const [onLoad, setOnLoad] = useState(false)
    const [formData, setFormData] = useState({
        creationDate: getCurrentDateTime(),
        rating: 0,
        message: ''
    })

    const content = translateContent(localStorage.getItem('Language'))
    const errorMessages = translateErrorMessages(localStorage.getItem('Language'))
    const successMessages = translateSuccessMessages(localStorage.getItem('Language'))

    useEffect(() => {
        const fetchData = async () => {
            const tokenData = await getDataBy('feedback_token', 'guid', feedbackToken)
            const ratingData = await getDataBy('business_rating', 'businessId', businessId)
            setTokenExists(tokenData.length > 0)
            setRatingValue(ratingData.length > 0 ? ratingData[0] : null)
        }
        fetchData()
    }, [businessId, feedbackToken])

    const handleInputChange = (event) => {
        const { name, value } = event.target
        setFormData({ ...formData, [name]: value })
    }

    const handleRatingChange = (value) => setFormData({ ...formData, rating: value })

    async function populateCollection(data) {
        try {
            const insertData = {
                businessId: businessId,
                rating: data.rating,
                message: data.message,
                creationDate: data.creationDate
            }
            return await addDataToCollection('business_feedback', insertData)
        } catch (error) {
            LogErrors(error)
            return false
        }
    }

    async function populateCollectionRating(data) {
        try {
            const totalRating = ratingValue == null ? data.rating : data.rating + ratingValue.totalRating
            const feedbackNumber = ratingValue == null ? 1 : ratingValue.feedbackNumber + 1
            const insertData = {
                businessId: businessId,
                totalRating: totalRating,
                feedbackNumber: feedbackNumber,
                rating: parseFloat((totalRating / feedbackNumber).toFixed(2))
            }
            return await addDataToCollection('business_rating', insertData)
        } catch (error) {
            LogErrors(error)
            return false
        }
    }

    async function handleSubmit(event) {
        event.preventDefault()
        setOnLoad(true)
        let result = await populateCollection(formData)
        if (result) {
            result = ratingValue == null ? true : deleteDocById('business_rating', ratingValue.id)
            if(result){
                result = deleteDataBy('feedback_token', 'guid', feedbackToken)
                if (result) {
                    result = await populateCollectionRating(formData)
                    if (result) {
                        notifySuccess(successMessages.created)
                        actionWithDelay("/")
                    }
                    else {
                        notifyError(errorMessages.failed_to_create_rating)
                    }
                }
                else {
                    notifyError(errorMessages.failed_to_delete_feedback_token)
                }
            }
            else {
                notifyError(errorMessages.failed_to_delete_rating)
            }
        }
        else {
            notifyError(errorMessages.contact_support)
        }
    }

    if (!localStorage.getItem('UserInfo'))
        return <NoLoginError />

    const accType = decrytptString(readValueInLocalStorage('Type', 'type'))
    if (accType !== 'client')
        return <Navigate to='/' />

    if (tokenExists == null)
        return <AppLoader />

    if (!tokenExists)
        return <Navigate to='/' />

    return (
        <Container fluid className='space-segment full-view shadow-text'>
            <Segment basic className='space-segment'>
                <Header id='page-title' inverted className='huge-text shadow-text' as='h1'>{content.feedback.title}</Header>
                <Divider />
            </Segment>
            <Container>
                <Form onSubmit={handleSubmit}>
                    <Form.Field>
                        <label className='big-text shadow-text brown-text'>{content.feedback.rating_label}</label>
                        <br />
                        <StarRating getRating={handleRatingChange} />
                    </Form.Field>
                    <br />
                    <Form.Field>
                        <label className='big-text shadow-text brown-text'>{content.feedback.description_label}</label>
                        <br />
                        <textarea placeholder='Breve descrição sobre a experiência...' maxLength="30" name="message" value={formData.message} onChange={handleInputChange} />
                    </Form.Field>
                    {formData.rating > 0 && !onLoad ?
                        <Button circular size='big' className='secondary-color-btn' type='submit'>{content.feedback.button}</Button>
                        : <Button circular size='big' className='secondary-color-btn' type='submit' disabled>{content.feedback.button}</Button>
                    }
                </Form>
            </Container>
            <Footer />
        </Container>
    )
}

export default ClientFeedbackForm
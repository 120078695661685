import React, { useEffect, useState } from 'react';
import { LogErrors, decrytptString, notifyError, readValueInLocalStorage, sortByNameAsc, translateContent } from '../../../resources/lib';
import { Link, Navigate, useParams } from 'react-router-dom';
import Business from './Business';
import BusinessService from './BusinessServices';
import WorkTimes from './WortTimes';
import { Container, Header, Icon, Menu, Segment, Sidebar } from 'semantic-ui-react';
import BusinessBooking from './BusinessBooking';
import BusinessAgenda from './BusinessAgenda';
import AppLoader from '../../Global/AppLoader';
import Breadcrumbs from '../../Global/Breadcrumbs';
import Footer from '../../Global/Footer';
import Socials from './Socials';
import { getAllDocuments, getDataBy, getDocById, getDataBy2Wheres, getDataBy3Wheres } from '../../../resources/firebaseLib';
import PaymentMethods from './PaymentMethods';
import ServiceFolders from './ServiceFolders';
import BusinessMenus from './BusinessMenus';

const BusinessPreview = () => {
    const width = 768
    const { id, page } = useParams()
    const [isLoaded, setIsLoaded] = useState(true)
    const [businessData, setBusinessData] = useState(null)
    const [activeServicesData, setActiveServicesData] = useState(null)
    const [servicesData, setServicesData] = useState(null)
    const [activeMenusData, setActiveMenusData] = useState(null)
    const [menusData, setMenusData] = useState(null)
    const [worktimesData, setWorktimesData] = useState(null)
    const [socialNamesData, setSocialNamesData] = useState(null)
    const [socialNetworks, setSocialNetworksData] = useState(null)
    const [pMethodsNamesData, setPMethodsNamesData] = useState(null)
    const [pMethods, setPMethodsData] = useState(null)
    const [folders, setFoldersData] = useState(null)
    const [menusFolders, setMenusFoldersData] = useState(null)
    const [visible, setVisible] = useState(false)
    const [isHidden, setIsHidden] = useState(window.innerWidth > width)

    const content = translateContent(localStorage.getItem('Language'))

    useEffect(() => {
        const fetchData = async () => {
            setIsLoaded(false)
            await getBusiness(id)
            await getBusinessServices(id)
            await getBusinessActiveServices(id)
            await getBusinessMenus(id)
            await getBusinessActiveMenus(id)
            await getWorktimes(id)
            await getSocialNetworkNames()
            await getBusinessSocialNetworks(id)
            await getPaymentMethodsNames()
            await getBusinessPaymentMethods(id)
            await getBusinessFolders(id)
            await getBusinessMenusFolders(id)
            setIsLoaded(true)
        }
        fetchData()
    }, [id])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > width) {
                setIsHidden(true)
            } else {
                setIsHidden(false)
            }
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [width])

    async function getBusiness(id) {
        const fetchData = async () => {
            setIsLoaded(false)
            try {
                const data = await getDocById('business', id)
                setBusinessData(data)
            } catch (error) {
                LogErrors(error)
                notifyError(error)
            }
            setIsLoaded(true)
        }
        fetchData()
    }

    async function getBusinessServices(id) {
        const fetchData = async () => {
            setIsLoaded(false)
            try {
                const data = await getDataBy2Wheres('business_services', 'businessId', 'isDelete', id, 0)
                setServicesData(data)
            } catch (error) {
                LogErrors(error)
                notifyError(error)
            }
            setIsLoaded(true)
        }
        fetchData()
    }

    async function getBusinessMenus(id) {
        const fetchData = async () => {
            setIsLoaded(false)
            try {
                const data = await getDataBy2Wheres('business_menus', 'businessId', 'isDelete', id, 0)
                setMenusData(data)
            } catch (error) {
                LogErrors(error)
                notifyError(error)
            }
            setIsLoaded(true)
        }
        fetchData()
    }

    async function getBusinessActiveServices(id) {
        const fetchData = async () => {
            setIsLoaded(false)
            try {
                const data = await getDataBy3Wheres('business_services', 'businessId', 'isActive', 'isDelete', id, 1, 0)
                setActiveServicesData(data)
            } catch (error) {
                LogErrors(error)
                notifyError(error)
            }
            setIsLoaded(true)
        }
        fetchData()
    }

    async function getBusinessActiveMenus(id) {
        const fetchData = async () => {
            setIsLoaded(false)
            try {
                const data = await getDataBy3Wheres('business_menus', 'businessId', 'isActive', 'isDelete', id, 1, 0)
                setActiveMenusData(data)
            } catch (error) {
                LogErrors(error)
                notifyError(error)
            }
            setIsLoaded(true)
        }
        fetchData()
    }

    async function refreshServices(id) {
        await getBusinessServices(id)
        await getBusinessActiveServices(id)
    }

    async function refreshMenus(id) {
        await getBusinessMenus(id)
        await getBusinessActiveMenus(id)
    }

    async function getWorktimes(id) {
        const fetchData = async () => {
            setIsLoaded(false)
            try {
                const data = await getDataBy('business_work_times', 'businessId', id)
                setWorktimesData(data)
            } catch (error) {
                LogErrors(error)
                notifyError(error)
            }
            setIsLoaded(true)
        }
        fetchData()
    }

    async function getSocialNetworkNames() {
        const fetchData = async () => {
            setIsLoaded(false)
            try {
                const data = await getAllDocuments('socials')
                setSocialNamesData(data)
            } catch (error) {
                LogErrors(error)
                notifyError(error)
            }
            setIsLoaded(true)
        }
        fetchData()
    }

    async function getBusinessSocialNetworks(id) {
        const fetchData = async () => {
            setIsLoaded(false)
            try {
                const data = await getDataBy('business_socials', 'businessId', id)
                setSocialNetworksData(sortByNameAsc(data))
            } catch (error) {
                LogErrors(error)
                notifyError(error)
            }
            setIsLoaded(true)
        }
        fetchData()
    }

    async function getPaymentMethodsNames() {
        const fetchData = async () => {
            setIsLoaded(false)
            try {
                const data = await getAllDocuments('payment_methods')
                setPMethodsNamesData(data)
            } catch (error) {
                LogErrors(error)
                notifyError(error)
            }
            setIsLoaded(true)
        }
        fetchData()
    }

    async function getBusinessPaymentMethods(id) {
        const fetchData = async () => {
            setIsLoaded(false)
            try {
                const data = await getDataBy('business_payment_methods', 'businessId', id)
                setPMethodsData(sortByNameAsc(data))
            } catch (error) {
                LogErrors(error)
                notifyError(error)
            }
            setIsLoaded(true)
        }
        fetchData()
    }

    async function getBusinessFolders(id) {
        const fetchData = async () => {
            setIsLoaded(false)
            try {
                const data = await getDataBy('business_folders', 'businessId', id)
                setFoldersData(data)
            } catch (error) {
                LogErrors(error)
                notifyError(error)
            }
            setIsLoaded(true)
        }
        fetchData()
    }

    async function getBusinessMenusFolders(id) {
        const fetchData = async () => {
            setIsLoaded(false)
            try {
                const data = await getDataBy('business_menus_folders', 'businessId', id)
                setMenusFoldersData(data)
            } catch (error) {
                LogErrors(error)
                notifyError(error)
            }
            setIsLoaded(true)
        }
        fetchData()
    }

    if (!isLoaded)
        return <AppLoader />
    if (!localStorage.getItem('UserInfo'))
        return <Navigate to='/' />
    else {
        const accType = decrytptString(readValueInLocalStorage('Type', 'type'))
        if (accType !== 'business')
            return <Navigate to='/' />
        if (!businessData || !servicesData || !activeServicesData || !menusData || !activeMenusData || !worktimesData)
            return <AppLoader />
        return (
            <Container fluid id='container-no-margin'>
                <Sidebar.Pushable id='sidebar-menu'>
                    <Sidebar
                        as={Menu}
                        animation='overlay'
                        icon='labeled'
                        inverted
                        onHide={() => setVisible(false)}
                        vertical
                        visible={visible}
                        width='thin'
                    >
                        <Menu.Item id='sidebar-book' as={Link} to={`/business/bookings/${id}/today`} onClick={() => setVisible(false)} >
                            <Icon name='address book' />
                            {content.business_preview.sidebar.bookings}
                        </Menu.Item>
                        {worktimesData.length > 0 &&
                            <Menu.Item id='sidebar-agenda' as={Link} to={`/business/agenda/${id}`} onClick={() => setVisible(false)} >
                                <Icon name='calendar alternate' />
                                {content.business_preview.sidebar.agenda}
                            </Menu.Item>
                        }
                        {businessData.isRestaurant &&
                            <Menu.Item id='sidebar-menus' as={Link} to={`/business/menu/${id}`} onClick={() => setVisible(false)}>
                                <Icon name='food' />
                                {content.business_preview.sidebar.menus}
                            </Menu.Item>
                        }
                        {!businessData.isRestaurant &&
                            <Menu.Item id='sidebar-services' as={Link} to={`/business/services/${id}`} onClick={() => setVisible(false)}>
                                <Icon name='wrench' />
                                {content.business_preview.sidebar.services}
                            </Menu.Item>
                        }
                        <Menu.Item id='sidebar-prefs' as={Link} to={`/business/prefs/${id}`} onClick={() => setVisible(false)}>
                            <Icon name='setting' />
                            {content.business_preview.sidebar.prefs}
                        </Menu.Item>
                    </Sidebar>

                    <Sidebar.Pusher dimmed={visible}>
                        {isHidden &&
                            <div id='sidebar-icon' className='sidebar-toggle' onClick={() => setVisible(true)}>
                                <Icon name='chevron right' />
                            </div>
                        }
                        {!isHidden &&
                            <Menu id='sidebar-menu' inverted stackable className='square-corners'>
                                <Menu.Item id='sidebar-book' as={Link} to={`/business/bookings/${id}/today`} onClick={() => setVisible(false)} >
                                    <Icon name='address book' />
                                    {content.business_preview.sidebar.bookings}
                                </Menu.Item>
                                {worktimesData.length > 0 &&
                                    <Menu.Item id='sidebar-agenda' as={Link} to={`/business/agenda/${id}`} onClick={() => setVisible(false)} >
                                        <Icon name='calendar alternate' />
                                        {content.business_preview.sidebar.agenda}
                                    </Menu.Item>
                                }
                                {businessData.isRestaurant &&
                                    <Menu.Item id='sidebar-menus' as={Link} to={`/business/menu/${id}`} onClick={() => setVisible(false)}>
                                        <Icon name='food' />
                                        {content.business_preview.sidebar.menus}
                                    </Menu.Item>
                                }
                                {!businessData.isRestaurant &&
                                    <Menu.Item id='sidebar-services' as={Link} to={`/business/services/${id}`} onClick={() => setVisible(false)}>
                                        <Icon name='wrench' />
                                        {content.business_preview.sidebar.services}
                                    </Menu.Item>
                                }
                                <Menu.Item id='sidebar-prefs' as={Link} to={`/business/prefs/${id}`} onClick={() => setVisible(false)}>
                                    <Icon name='setting' />
                                    {content.business_preview.sidebar.prefs}
                                </Menu.Item>
                            </Menu>
                        }
                        <Container fluid>
                            {page === 'bookings' &&
                                <Segment basic>
                                    <Breadcrumbs list={[content.businesses.title, content.business_preview.sidebar.bookings]} />
                                    <Header id='page-title' inverted className='huge-text shadow-text' as='h1'>{content.business_preview.sidebar.bookings}</Header>
                                    <BusinessBooking businessId={id} isRestaurant={businessData.isRestaurant} />
                                </Segment>
                            }
                            {page === 'agenda' &&
                                <Segment basic>
                                    <Breadcrumbs list={[content.businesses.title, content.business_preview.sidebar.agenda]} />
                                    <Header id='page-title' inverted className='huge-text shadow-text' as='h1'>{content.business_preview.sidebar.agenda}</Header>
                                    <BusinessAgenda businessId={id} worktimes={worktimesData} />
                                </Segment>
                            }
                            {page === 'services' &&
                                <Segment basic>
                                    <Breadcrumbs list={[content.businesses.title, content.business_preview.sidebar.services]} />
                                    <Header id='page-title' inverted className='huge-text shadow-text' as='h1'>{content.business_preview.sidebar.services}</Header>
                                    <BusinessService data={servicesData} folders={folders} businessId={id} refreshServices={() => refreshServices(id)} />
                                </Segment>
                            }
                            {page === 'menu' &&
                                <Segment basic>
                                    <Breadcrumbs list={[content.businesses.title, content.business_preview.sidebar.menus]} />
                                    <Header id='page-title' inverted className='huge-text shadow-text' as='h1'>{content.business_preview.sidebar.menus}</Header>
                                    <BusinessMenus data={menusData} folders={menusFolders} businessId={id} refreshServices={() => refreshMenus(id)} />
                                </Segment>
                            }
                            {(page === 'prefs' || (page !== 'services' && page !== 'menu' && page !== 'bookings' && page !== 'agenda')) &&
                                <Segment basic>
                                    <Breadcrumbs list={[content.businesses.title, content.business_preview.sidebar.prefs]} />
                                    <Header id='page-title' inverted className='huge-text shadow-text' as='h1'>{content.business_preview.title}</Header>
                                    <Business data={[businessData]} isPreview={true} hasTimes={worktimesData.length > 0} hasServices={businessData.isRestaurant ? activeMenusData.length > 0 : activeServicesData.length > 0} getBusiness={getBusiness} />
                                    <Header id='worktimes-title' inverted className='huge-text shadow-text' as='h1'>{content.business_preview.work_times_title}</Header>
                                    <WorkTimes data={worktimesData} businessId={id} getWorktimes={() => getWorktimes(id)} />
                                    <br />
                                    <Header id='socials-title' inverted className='huge-text shadow-text' as='h1'>{content.business_preview.socials_title}</Header>
                                    <Socials data={socialNetworks} names={socialNamesData} businessId={id} getSocials={() => getBusinessSocialNetworks(id)} />
                                    <br />
                                    <Header id='pmethods-title' inverted className='huge-text shadow-text' as='h1'>{content.business_preview.pMethods_title}</Header>
                                    <PaymentMethods data={pMethods} names={pMethodsNamesData} businessId={id} getPMethods={() => getBusinessPaymentMethods(id)} />
                                    <br />
                                    <Header id='groups-title' inverted className='huge-text shadow-text' as='h1'>{businessData.isRestaurant ? content.business_preview.menus_title : content.business_preview.folders_title}</Header>
                                    <ServiceFolders data={businessData.isRestaurant ? menusFolders : folders} businessId={id} isRestaurant={businessData.isRestaurant} getFolders={!businessData.isRestaurant ? () => getBusinessFolders(id) : () => getBusinessMenusFolders(id)} />
                                </Segment>
                            }
                        </Container>
                    </Sidebar.Pusher>
                    <Footer />
                </Sidebar.Pushable>
            </Container>
        )
    }
}

export default BusinessPreview
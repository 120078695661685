import React, { useEffect, useState } from 'react';
import { Button, Grid, Icon, Segment } from 'semantic-ui-react';
import { translateContent } from '../../../resources/lib';

const ImageInputGrid = (props) => {
    const width = 768
    const [isHidden, setIsHidden] = useState(window.innerWidth > width)
    const [selectedFiles, setSelectedFiles] = useState(props?.data == null ? Array(1).fill(null) : props.data)

    const content = translateContent(localStorage.getItem('Language'))

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > width) {
                setIsHidden(true)
            } else {
                setIsHidden(false)
            }
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [width])

    const handleDragOver = (e, index) => {
        e.preventDefault()
    }

    function handleDrop(e, index) {
        e.preventDefault()
        const file = e.dataTransfer.files[0]
        if (file) {
            const updatedFiles = [...selectedFiles]
            updatedFiles[index] = file
            if (updatedFiles.length < props.inputTotal)
                updatedFiles.push(null)
            setSelectedFiles(updatedFiles)
            props.handleFiles(updatedFiles)
        }
    }

    function handleFileInputChange(e, index) {
        const file = e.target.files[0]
        if (file) {
            const updatedFiles = [...selectedFiles]
            updatedFiles[index] = file
            if (updatedFiles.length < props.inputTotal)
                updatedFiles.push(null)
            setSelectedFiles(updatedFiles)
            props.handleFiles(updatedFiles)
        }
    }

    function handleRemoveImage(e, index) {
        e.preventDefault()
        const updatedFiles = [...selectedFiles]
        props.handleRemoteFilesToRemove(updatedFiles[index])
        updatedFiles.splice(index, 1)
        const filteredFiles = updatedFiles.filter((file) => file !== null)
        filteredFiles.push(null)
        setSelectedFiles(filteredFiles)
        props.handleFiles(filteredFiles)
    }

    function showButton(index) {
        if (isHidden)
            return (<Button
                id='modal-cancel-btn'
                type='button'
                circular
                className='third-color-active-btn'
                content={content.modals.remove}
                onClick={e => handleRemoveImage(e, index)}
            />)
        else
            return (<Button
                id='modal-cancel-btn'
                type='button'
                circular
                className='third-color-active-btn'
                icon
                onClick={e => handleRemoveImage(e, index)}
            >
                <Icon name='delete' />
            </Button>)
    }

    return (
        <Grid columns={isHidden ? 3 : 1}>
            {selectedFiles.map((file, index) => (
                <Grid.Column key={index}>
                    <Segment
                        basic
                        onDragOver={(e) => handleDragOver(e, index)}
                        onDrop={(e) => handleDrop(e, index)}
                        textAlign="center"
                    >
                        <input
                            id={`fileInput${index}`}
                            type="file"
                            accept="image/*"
                            style={{ display: 'none' }}
                            onChange={(e) => handleFileInputChange(e, index)}
                        />
                        <div
                            onClick={() => document.getElementById(`fileInput${index}`).click()}
                            onDragEnter={(e) => e.preventDefault()}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={(e) => e.preventDefault()}
                            style={{
                                width: '100%',
                                height: '200px',
                                border: '2px dashed #ccc',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                cursor: 'pointer',
                                backgroundColor: 'white'
                            }}
                        >
                            {file ? (
                                <>
                                    <img
                                        src={file.isStorage != null ? file.url : URL.createObjectURL(file)}
                                        alt=""
                                        style={{ maxWidth: '100%', maxHeight: '100%' }}
                                    />
                                </>
                            ) : (
                                <p>{content.image_input_gird.message}</p>
                            )}
                        </div>
                        <br />
                        {file != null &&
                            showButton(index)
                        }
                    </Segment>
                </Grid.Column>
            ))}
        </Grid>
    )
}

export default ImageInputGrid






import React from 'react'
import { Container, Header, Segment } from 'semantic-ui-react'
import Footer from '../Footer'
import { decrytptString, readValueInObjectInLocalStorage } from '../../../resources/lib'
import { Link, Navigate } from 'react-router-dom'

const Guide = () => {
    if (!localStorage.getItem('UserInfo'))
        return <Navigate to='/' />

    const type = decrytptString(readValueInObjectInLocalStorage('Type', 'type'))
    if (type === 'business')
        return (
            <Container fluid>
                <Segment basic className='space-segment'>
                    <Header inverted className='huge-text shadow-text' as='h1'>Guia</Header>
                </Segment>
                <Container fluid>
                    <Container className='forms-small-box brown-text' textAlign='justified'>
                        <Header>1. Introdução</Header>

                        <p>Bem-vindo ao <b>Reservado</b>. O <b>Reservado</b> a solução completa para a gestão de reservas online no seu negócio.</p>

                        <Header>2. Gestão da <Link className='copy-link' to='/business'>Empresa</Link></Header>
                        <ol type="1">
                            <li><b>Preferências:</b> Configure horários, redes sociais, métodos de pagamento e grupos de serviços.</li>
                            <li><b>Serviços:</b> Crie e gerencie os serviços oferecidos pela sua empresa. Eles devem estar ativos para serem reserváveis.</li>
                            <li><b>Reservas:</b> Aqui pode visualizar, confirmar, editar ou cancelar as reservas recebidas.</li>
                            <li><b>Agenda:</b> Aceda à agenda para ter uma visão geral das reservas e compromissos agendados.</li>
                        </ol>

                        <Header>3. Gestão da <Link className='copy-link' to='/account/basic'>Conta do Utilizador</Link></Header>
                        <ol type="1">
                            <li><b>Informação Básica:</b> Aqui pode verificar e editar informações da sua conta, como nome, contacto e morada.</li>
                            <li><b>Apagar Conta:</b> Se necessário, pode apagar permanentemente a sua conta de empresa.</li>
                            <li><b>Repôr Palavra-Passe:</b> Mantenha a segurança da sua conta atualizando regularmente a sua palavra-passe.</li>
                        </ol>

                        <Header>4. Pacotes</Header>
                        <ol type="1">
                            <li><b>Básico:</b> Acesso à gestão de reservas, agenda e configurações básicas da empresa.</li>
                            <li><b>Premium:</b> Além das funcionalidades do Pacote Básico, as contas Premium incluem a capacidade de aceitar reservas online e receber alertas por email para lembretes de reservas.</li>
                        </ol>

                        <Header>5. Acesso à Página Web da <Link className='copy-link' to='/business'>Empresa</Link></Header>

                        <p>Após a ativação da conta e configuração dos serviços, a página web da sua empresa estará disponível, permitindo partilhar informações com os clientes.</p>

                        <Header>6. Contacto e Suporte Técnico</Header>
                        <p>Para assistência ou questões relacionadas com a aplicação, por favor contacte-nos através do botão "Nosso Suporte".</p>
                    </Container>
                </Container>
                <Footer />
            </Container>
        )
    else
        return (
            <Container fluid>
                <Segment basic className='space-segment'>
                    <Header inverted className='huge-text shadow-text' as='h1'>Guia</Header>
                </Segment>
                <Container fluid>
                    <Container className='forms-small-box brown-text' textAlign='justified'>
                        <Header>1. Introdução</Header>

                        <p>Bem-vindo ao <b>Reservado</b>. O <b>Reservado</b> a solução completa para a gestão de reservas online no seu negócio.</p>

                        <Header>2. Gestão de <Link className='copy-link' to='/bookings/today'>Reservas</Link></Header>
                        <ol type="1">
                            <li>
                                <p>Na página de Reservas, pode gerir todas as suas reservas atuais.</p>
                            </li>
                            <li>
                                <p>Aqui tem a opção de visualizar detalhes, editar, aceitar ou cancelar as suas reservas conforme necessário.</p>
                            </li>
                        </ol>

                        <Header>3. Uso da <Link className='copy-link' to='/agenda'>Agenda</Link></Header>
                        <ol type="1">
                            <li>
                                <p>A agenda oferece uma visão geral de todas as suas reservas agendadas para o dia selecionado.</p>
                            </li>
                            <li>
                                <p>Utilize esta funcionalidade para planear e organizar melhor o seu dia.</p>
                            </li>
                        </ol>

                        <Header>4. Gestão da <Link className='copy-link' to='/account/basic'>Conta do Utilizador</Link></Header>
                        <ol type="1">
                            <li><b>Informação Básica:</b> Aqui pode verificar e editar informações da sua conta, como nome, contacto e morada.</li>
                            <li><b>Apagar Conta:</b> Se necessário, pode apagar permanentemente a sua conta de empresa.</li>
                            <li><b>Repôr Palavra-Passe:</b> Mantenha a segurança da sua conta atualizando regularmente a sua palavra-passe.</li>
                        </ol>

                        <Header>5. Contacto e Suporte Técnico</Header>
                        <p>Para assistência ou questões relacionadas com a aplicação, por favor contacte-nos através do botão "Nosso Suporte".</p>
                    </Container>
                </Container>
                <Footer />
            </Container>
        )
}

export default Guide
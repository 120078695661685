import { useEffect, useState } from 'react';
import { Image, Button, Container, Grid, Header, Segment } from 'semantic-ui-react';
import { getAllFilesFromFolder } from '../../../resources/storageLib';
import AppLoader from '../AppLoader';
import { LogErrors, translateContent } from '../../../resources/lib';

const ImageCarouselCustom = (props) => {
  const [carouselItems, setCarouselItems] = useState(null)
  const [activeIndex, setActiveIndex] = useState(0)

  const content = translateContent(localStorage.getItem('Language'))

  useEffect(() => {
    async function fetchData() {
      const files = await getFolderFiles(props.path)
      const carouselItems = formatFilesForCarousel(files)
      setCarouselItems(carouselItems)
    }

    fetchData()
  }, [props.path])

  async function getFolderFiles(path) {
    if (path !== '') {
      try {
        return await getAllFilesFromFolder(path)
      } catch (error) {
        LogErrors('Error fetching files:', error)
        return []
      }
    }
    else {
      return []
    }
  }

  function formatFilesForCarousel(files) {
    try {
      let carouselItems = []
      files.forEach((file, index) => {
        carouselItems.push({
          id: index,
          src: file.url,
        })
      })
      return carouselItems
    }
    catch (error) {
      console.error('Error building carousel object:', error)
      return []
    }
  }

  const handleNext = () => {
    setActiveIndex((activeIndex + 1) % carouselItems.length)
  }

  const handlePrev = () => {
    setActiveIndex(activeIndex === 0 ? carouselItems.length - 1 : activeIndex - 1)
  }

  if (carouselItems == null)
    return <AppLoader />

  if (carouselItems.length === 0)
    return (
      <Container fluid>
        <Header className='bigger-text shadow-text brown-text'>{props.title}</Header>
        <p className='big-text shadow-text white-text'>{content.nothing_to_show}</p>
      </Container>
    )

  return (
    <Container fluid>
      <Header className='bigger-text shadow-text brown-text'>{props.title}</Header>
      <Container fluid>
        <Grid columns='three' textAlign="center">
          <Grid.Row>
            <Grid.Column onClick={handlePrev}>
              <Button circular secondary icon="angle left" />
            </Grid.Column>
            <Grid.Column>
            </Grid.Column>
            <Grid.Column onClick={handleNext}>
              <Button circular secondary icon="angle right" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Segment basic compact>
              <Grid.Column mobile={16} computer={16}>
                <Image src={carouselItems[activeIndex].src} fluid rounded className='service-image' />
              </Grid.Column>
            </Segment>
          </Grid.Row>
        </Grid>
        <br />
      </Container>
    </Container>
  )
}

export default ImageCarouselCustom

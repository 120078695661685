import { deleteObject, getDownloadURL, listAll, ref, uploadBytes } from "firebase/storage";
import { storage } from "../firebase";
import { v4 } from 'uuid';
import { LogErrors } from "./lib";

export async function getAllFilesFromFolder(path) {
    const imageRef = ref(storage, path);

    try {
        const files = await listAll(imageRef)
        const downloadURLs = await Promise.all(files.items.map(async (fileRef) => {
            const url = await getDownloadURL(fileRef)
            return { name: fileRef.name, url, isStorage: true }
        }))
        return downloadURLs
    } catch (error) {
        LogErrors(error)
        return []
    }
}

export async function uploadFiles(files, path) {
    try {
        if (files != null) {
            await Promise.all(files.map(async (file) => {
                const fileRef = ref(storage, `${path}/${v4()}`)
                if (file != null)
                    if (!file.hasOwnProperty('isStorage'))
                        await uploadBytes(fileRef, file)
            }))
        }
        return true
    }
    catch (error) {
        LogErrors(error)
        return false
    }
}

export async function deleteFiles(files, path) {
    try {
        if (files != null) {
            await Promise.all(files.map(async (fileData) => {
                const filePath = `${path}/${fileData.name}`
                if (path != null) {
                    const fileRef = ref(storage, filePath)
                    await deleteObject(fileRef)
                }
            }))
        }
        return true
    } catch (error) {
        LogErrors(error)
        return false
    }
}

//
export async function deleteFolder(folderPath) {
    const folderRef = ref(storage, folderPath)

    try {
        const result = await listAll(folderRef)

        // Delete each file in the folder.
        await Promise.all(
            result.items.map((itemRef) => {
                return deleteObject(itemRef)
            })
        )

        return true
    } catch (error) {
        LogErrors(error)
        return false
    }
}
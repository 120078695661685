import React, { useEffect, useState } from 'react'
import { Button, Container, Divider, Grid, Header, Icon, Segment } from 'semantic-ui-react'
import { formatCategoriesNames, translateCategoriesString, translateContent } from '../../../resources/lib'
import { Link } from 'react-router-dom'

const businessList = [
    {
        name: 'Rúben José',
        icon: 'wrench',
        categories: 'Furniture assembly;Electrician',
        contact: '917078089',
        isRestaurant: false,
        address: '',
        city: 'Olhão',
        postalCode: ''
    }
]

const LastBusinesses = () => {
    const width = 768
    const [isHidden, setIsHidden] = useState(window.innerWidth > width)

    const content = translateContent(localStorage.getItem('Language'))

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > width) {
                setIsHidden(true)
            } else {
                setIsHidden(false)
            }
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [width])

    function getBaseUrl() {
        const url = window.location.href
        const parts = url.split("/");
        return `${parts[0]}//${parts[2]}`
    }

    function formatName(name) {
        const formatName = name.replaceAll(" ", '-')
        return formatName
    }

    if (!isHidden)
        return (
            <Container fluid id='container-no-margin'>
                <Segment basic className='space-segment divider-container'>
                    <Segment basic className='segment-divider'>
                        <Header as='h2' className='brown-text huge-text shadow-text'>{content.lastBusiness.title}</Header>
                        <Divider />
                    </Segment>
                    <Grid stackable columns={businessList.length} textAlign='center'>
                        {businessList.map((item, index) => (
                            <Grid.Column key={index}>
                                <Icon name={item.icon} size='huge' className='primary-color-icon' />
                                <Header as='h2' className='secondary-text-color big-text shadow-text'>
                                    {item.name}
                                </Header>
                                <Header className='shadow-text primary-text-color' as='h1'>
                                    {translateCategoriesString(localStorage.getItem('Language'), formatCategoriesNames(item.categories), item.isRestaurant)}
                                </Header>
                                <Header as='h2' className='secondary-text-color big-text shadow-text'>
                                    <Icon className='secondary-color-icon' name='phone' />
                                    {item.contact}
                                </Header>
                                <Header inverted className='secondary-text-color big-text shadow-text'><Icon className='secondary-color-icon' name='marker' />
                                    {(item.address.length > 0 ? `${item.address}, ${item.postalCode}, ${item.city}` : item.city)}
                                </Header>
                                <Button circular size='massive' className='third-color-btn' as={Link} to={`${getBaseUrl()}/${formatName(item.name)}`} target='_blank' rel="noreferrer">{content.client_business_preview.client_business.button}</Button>
                            </Grid.Column>
                        ))}
                    </Grid>
                </Segment>
            </Container>
        )


    return (
        <Container fluid id='container-no-margin'>
            <Segment basic className='space-segment divider-container'>
                <Segment basic className='segment-divider'>
                    <Header as='h2' className='brown-text huge-text shadow-text'>{content.lastBusiness.title}</Header>
                    <Divider />
                </Segment>
                <br />
                <Grid stackable columns={businessList.length} textAlign='center'>
                    {businessList.map((item, index) => (
                        <Grid.Column key={index}>
                            <Icon name={item.icon} size='huge' className='primary-color-icon' />
                        </Grid.Column>
                    ))}
                </Grid>
                <Grid stackable columns={businessList.length} textAlign='center'>
                    {businessList.map((item, index) => (
                        <Grid.Column key={index}>
                            <Header as='h2' className='secondary-text-color big-text shadow-text'>
                                {item.name}
                            </Header>
                            <Header className='shadow-text primary-text-color' as='h1'>
                                {translateCategoriesString(localStorage.getItem('Language'), formatCategoriesNames(item.categories), item.isRestaurant)}
                            </Header>
                            <Header as='h2' className='secondary-text-color big-text shadow-text'>
                                <Icon className='secondary-color-icon' name='phone' />
                                {item.contact}
                            </Header>
                            <Header inverted className='secondary-text-color big-text shadow-text'><Icon className='secondary-color-icon' name='marker' />
                                {(item.address.length > 0 ? `${item.address}, ${item.postalCode}, ${item.city}` : item.city)}
                            </Header>
                            <Button circular size='massive' className='third-color-btn' as={Link} to={`${getBaseUrl()}/${formatName(item.name)}`} target='_blank' rel="noreferrer">{content.client_business_preview.client_business.button}</Button>
                        </Grid.Column>
                    ))}
                </Grid>
            </Segment>
        </Container>
    )
}

export default LastBusinesses
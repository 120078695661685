import React, { useCallback, useEffect, useState } from 'react'
import { Button, Icon, Segment, Table } from 'semantic-ui-react'
import { Link, useParams } from 'react-router-dom'
import AppLoader from '../../Global/AppLoader'
import { LogErrors, capitalize, changeHoursFromDate, formatTime, getBookingUserEmptyObject, getCurrentDate, isDate1Greater, isDate1GreaterOrEqual, notifyError, notifySuccess, removeId, sortArrayByJoinDateDesc, translateContent, translateEmailTextConfirmBooking, translateEmailTextDeleteBooking, translateEmailTextFeedback, translateEmailTextWarningBooking, translateEmailTitles, translateErrorMessages, translateSuccessMessages } from '../../../resources/lib'
import BookingModal from '../Modals/BookingModal'
import ConfirmModal from '../Modals/ConfirmModal'
import BookingPreviewModal from '../Modals/BookingPreviewModal'
import { createEmail, createFeedbackToken, deleteDocById, getAllBookingsBusiness, getAllBookingsFromTodayBusiness, updateDataByDocId } from '../../../resources/firebaseLib'
import BusinessBookingInsertModal from '../Modals/BusinessBookingInsertModal'
import { v4 as uuidv4 } from 'uuid'

const BusinessBooking = (props) => {
    const width = 768
    const { id, bookingType } = useParams()
    const [bookingsData, setBookingsData] = useState(null)
    const [lastDoc, setLastDoc] = useState(1)
    const [onLoad, setOnLoad] = useState(false)
    const [isHidden, setIsHidden] = useState(window.innerWidth > width)

    const content = translateContent(localStorage.getItem('Language'))
    const errorMessages = translateErrorMessages(localStorage.getItem('Language'))
    const successMessages = translateSuccessMessages(localStorage.getItem('Language'))

    function handleStatus(status) {
        if (Number(status) === 0)
            return <Icon size='big' color='red' name='remove circle' />
        else
            return <Icon size='big' color='green' name='check circle' />
    }

    function handleBookingType(type) {
        if (Number(type) === 0)
            return <Icon size='big' title={content.bookings.type_online} color='black' name='world' />
        else
            return <Icon size='big' title={content.bookings.tyep_internal} color='black' name='pencil' />
    }

    const handleGetData = useCallback(async (type, doc) => {
        setOnLoad(true)
        try {
            const data = type === 'all' ?
                await getAllBookingsBusiness(props.businessId, doc) :
                await getAllBookingsFromTodayBusiness(props.businessId, doc)
            setBookingsData(sortArrayByJoinDateDesc(data.dataList))
            setLastDoc(data.finishNumber)
        }
        catch (err) {
            LogErrors(err)
            notifyError(errorMessages.contact_support)
            setBookingsData([])
        }
        setOnLoad(false)
    }, [props, errorMessages])

    useEffect(() => {
        handleGetData(bookingType, 1)
        const handleResize = () => {
            if (window.innerWidth > width) {
                setIsHidden(true)
            } else {
                setIsHidden(false)
            }
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [width, bookingType, handleGetData])

    async function handleAccept(event, item) {
        event.preventDefault()
        if (onLoad) return
        setOnLoad(true)
        item.isActive = item.isActive === 1 ? 0 : 1
        let result = await updateDataByDocId('booking', item.id, removeId(item))
        if (result) {
            //Create confirmation email
            result = await createEmail(item.tempUser.email, translateEmailTitles('bookingConfirmed'), translateEmailTextConfirmBooking(
                {
                    businessName: item.business.name,
                    firstName: item.tempUser.firstName,
                    lastName: item.tempUser.lastName
                }
            ))
            if (result) {
                //Create booking warning email
                result = await createEmail(item.tempUser.email, translateEmailTitles('bookingWarning'), translateEmailTextWarningBooking(
                    {
                        businessName: item.business.name,
                        date: `${item.date} ${item.time}`
                    }
                ), item.bookingEmailId, changeHoursFromDate(`${item.date} ${item.time}`, -4))
                if (result) {
                    //Create feedback token
                    const guid = uuidv4()
                    result = await createFeedbackToken(guid, item.bookingEmailId)
                    if (result) {
                        //Create booking feedback email
                        result = await createEmail(item.tempUser.email, translateEmailTitles('bookingFeedback'), translateEmailTextFeedback(
                            {
                                businessId: item.businessId,
                                guid: guid
                            }
                        ), item.bookingEmailId, changeHoursFromDate(`${item.date} ${item.time}`, 24))
                        if (result) {
                            await handleGetData(bookingType, lastDoc)
                            notifySuccess(successMessages.updated)
                        }
                        else {
                            notifyError(errorMessages.failed_to_create_feedback_email)
                        }
                    }
                    else {
                        notifyError(errorMessages.failed_to_create_feedback_token)
                    }
                }
                else {
                    notifyError(errorMessages.failed_to_create_booking_warning_email)
                }
            }
            else {
                notifyError(errorMessages.failed_to_create_booking_confirm_email)
            }
        }
        else {
            notifyError(errorMessages.failed_to_update_booking_status)
        }
        setOnLoad(false)
    }

    async function handleDelete(item) {
        if (item.isManual === 1) {
            let result = await deleteDocById('booking', item.id)
            if (result) {
                await handleGetData(bookingType, lastDoc)
                notifySuccess(successMessages.booking_deleted)
            }
            else {
                notifyError(errorMessages.failed_to_delete_booking)
            }
        }
        else {
            let result = await deleteDocById('booking', item.id)
            if (result) {
                result = await deleteDocById('feedback_token', item.bookingEmailId)
                if (result) {
                    result = await deleteDocById('emails', item.bookingEmailId)
                    if (result) {
                        result = await createEmail(item.tempUser.email, translateEmailTitles('bookingDeleted'), translateEmailTextDeleteBooking(
                            {
                                businessName: item.business.name,
                                firstName: item.tempUser.firstName,
                                lastName: item.tempUser.lastName
                            }
                        ))
                        if (result) {
                            await handleGetData(bookingType, lastDoc)
                            notifySuccess(successMessages.booking_deleted)
                        }
                        else {
                            notifyError(errorMessages.failed_to_create_booking_delete_email)
                        }
                    }
                    else {
                        notifyError(errorMessages.failed_to_delete_booking_emails)
                    }
                }
                else {
                    notifyError(errorMessages.failed_to_delete_feedback_token)
                }
            }
            else {
                notifyError(errorMessages.failed_to_delete_booking)
            }
        }
    }

    async function handleClick(type, item) {
        if (type)
            await handleDelete(item)
    }

    function showAcceptBookingButton(item) {
        if (!item.isActive && item.isUserActive) {
            return <div><Icon title={content.accept} size='large' color='green' name='check' onClick={e => handleAccept(e, item)} /></div>
        }
    }

    function formatServiceNames(names) {
        if (names.length === 0)
            return ' - '

        return names.replaceAll(';', '; ')
    }

    async function handleTypeChange(event, type) {
        setOnLoad(true)
        await handleGetData(type, lastDoc)
        setOnLoad(false)
    }

    function selectedAccountType(type, value, name, id) {
        const realName = value
        if (type === realName) {
            return (
                <Button className='btn-pad primary-color-active-btn' circular size='big' as={Link} to={`/business/bookings/${id}/${realName}`} name={realName} onClick={e => handleTypeChange(e, realName)}>{capitalize(name)}</Button>
            )
        }
        else {
            return (
                <Button className='btn-pad primary-color-btn' circular size='big' as={Link} to={`/business/bookings/${id}/${realName}`} name={realName} onClick={e => handleTypeChange(e, realName)}>{capitalize(name)}</Button>
            )
        }
    }

    async function handleModal(message) {
        setOnLoad(true)
        await handleGetData(bookingType, lastDoc)
        setOnLoad(false)
        notifySuccess(message)
    }

    async function handleShowMoreBtn() {
        handleGetData(bookingType, lastDoc)
    }

    const tableHeaders = isHidden ? props.isRestaurant ? content.bookings.business_table_headers_menu_text : content.bookings.business_table_headers_text : content.bookings.business_short_table_headers_text
    const tableFields = isHidden ? props.isRestaurant ? content.bookings.business_table_headers_menu_values : content.bookings.business_table_headers_values : content.bookings.business_short_table_headers_values

    if (!bookingsData)
        return <AppLoader />
    if (onLoad)
        return <AppLoader />
    const currentDate = getCurrentDate()
    return (
        <div className={isHidden ? 'tables-box' : 'tables-box-no-padding'}>
            <Segment basic>
                {selectedAccountType(bookingType, 'today', content.bookings.current_button, id)}
                {selectedAccountType(bookingType, 'all', content.bookings.all_button, id)}
            </Segment>
            <div className='scrollable-table'>
                <Table id='bookings-table' className='text-big no-border shadow-text' celled stackable selectable textAlign='center'>
                    <Table.Header>
                        <Table.Row className='table-header-bg'>
                            {
                                tableHeaders.map((item) => (
                                    <Table.HeaderCell key={item}>{item}</Table.HeaderCell>
                                ))
                            }
                        </Table.Row>
                    </Table.Header>
                    <Table.Body className='table-body-bg'>
                        {bookingsData.length === 0 &&
                            <Table.Row>
                                <Table.Cell colSpan={tableHeaders.length}>{content.nothing_to_show}</Table.Cell>
                            </Table.Row>
                        }
                        {bookingsData.map(item => (
                            <Table.Row key={item.id}>
                                {tableFields.map((field) => (
                                    field === 'fullName' ? (
                                        <Table.Cell key={field}>{`${item.tempUser.firstName} ${item.tempUser.lastName}`}</Table.Cell>
                                    ) : (
                                        field === 'name' ? (
                                            props.isPreview ? (
                                                <Table.Cell key={field}>{item[field]}</Table.Cell>
                                            ) : (
                                                <Table.Cell key={field}><Link to={`/business/${item.id}`}>{item[field]}</Link></Table.Cell>
                                            )
                                        ) : (
                                            (field === 'isActive' || field === 'isUserActive') ? (
                                                <Table.Cell key={field}>{handleStatus(item[field])}</Table.Cell>
                                            ) : (
                                                (field === 'isManual') ? (
                                                    <Table.Cell key={field}>{handleBookingType(item[field])}</Table.Cell>
                                                ) : (
                                                    field === 'servicesNames' ? (
                                                        <Table.Cell key={field}>{formatServiceNames(item[field])}</Table.Cell>
                                                    ) : (
                                                        field === 'duration' ? (
                                                            <Table.Cell key={field}>{formatTime(item[field])}</Table.Cell>
                                                        ) : (
                                                            field === 'contact' ? (
                                                                <Table.Cell key={field}>{item.tempUser.contact}</Table.Cell>
                                                            ) : (
                                                                <Table.Cell key={field}>{item[field]}</Table.Cell>
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )
                                    )
                                ))
                                }
                                {item.isActive === 1 &&
                                    <Table.Cell>
                                        <div className={bookingType !== 'all' ? "icon-grid" : ""}>
                                            <BookingPreviewModal item={item} component={<Icon title={content.view} size='large' color='blue' name='eye' />} />
                                            {(item.isManual === 1 && isDate1GreaterOrEqual(item.date, currentDate)) &&
                                                <BookingModal handleModal={handleModal} item={item} component={<Icon title={content.edit} size='large' color='yellow' name='edit' />} />
                                            }
                                            {item.isManual === 1 ?
                                                <ConfirmModal item={item} handleClick={handleClick}
                                                    action={`${content.bookings.delete_action} '${item.date} ${item.time}'; '${item.tempUser.firstName} ${item.tempUser.lastName}'; '${formatServiceNames(item.servicesNames)}'; '${formatTime(item.duration)}; '${item.amount}''?`}
                                                    component={<Icon title={content.delete} size='large' color='red' name='delete' />} /> :
                                                isDate1Greater(item.date, currentDate) &&
                                                <ConfirmModal item={item} handleClick={handleClick}
                                                    action={`${content.bookings.delete_action} '${item.date} ${item.time}'; '${item.tempUser.firstName} ${item.tempUser.lastName}'; '${formatServiceNames(item.servicesNames)}'; '${formatTime(item.duration)}; '${item.amount}''?`}
                                                    component={<Icon title={content.delete} size='large' color='red' name='delete' />} />
                                            }
                                        </div>
                                    </Table.Cell>
                                }
                                {item.isActive === 0 &&
                                    <Table.Cell>
                                        <div className={bookingType !== 'all' ? "icon-grid" : ""}>
                                            {isDate1Greater(item.date, currentDate) &&
                                                showAcceptBookingButton(item)
                                            }
                                            <BookingPreviewModal item={item} component={<Icon title={content.view} size='large' color='blue' name='eye' />} />
                                            {isDate1GreaterOrEqual(item.date, currentDate) &&
                                                <BookingModal handleModal={handleModal} item={item} component={<Icon title={content.edit} size='large' color='yellow' name='edit' />} />
                                            }
                                            {isDate1GreaterOrEqual(item.date, currentDate) &&
                                                <ConfirmModal item={item} handleClick={handleClick}
                                                    action={`${content.bookings.delete_action} '${item.date} ${item.time}'; '${item.tempUser.firstName} ${item.tempUser.lastName}'; '${formatServiceNames(item.servicesNames)}'; '${formatTime(item.duration)}; '${item.amount}''?`}
                                                    component={<Icon title={content.delete} size='large' color='red' name='delete' />} />
                                            }
                                        </div>
                                    </Table.Cell>
                                }
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
            {bookingsData.length === 50 &&
                <Button id='show-bookings-btn' circular className='primary-color-btn' onClick={handleShowMoreBtn}><Icon name='undo' />Mostrar Mais</Button>
            }
            <Segment basic>
                <BusinessBookingInsertModal isRestaurant={props.isRestaurant} handleModal={handleModal} item={getBookingUserEmptyObject(id, 1)} component={<Button id='add-bookings-btn' circular size='big' as={Link} className='primary-color-btn'><Icon name='add' /> {content.bookings.button}</Button>} />
            </Segment>
        </div>
    )
}

export default BusinessBooking
import React, { useState } from 'react'
import { Button, Container, Header, Icon, Modal } from 'semantic-ui-react'
import LanguagesDrop from '../../../Global/NavBars/LanguagesDrop'

const TermsNConditionsModal = () => {
    const [open, setOpen] = useState(true)

    function handleClick() {
        localStorage.setItem('AcceptTerms', 'Yes')
        setOpen(false)
    }

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
        >
            {open &&
                <>
                    <Modal.Header className='black-back-color bigger-text shadow-text'>
                        <div>Terms and Conditions of Use</div>
                        <div><LanguagesDrop /></div>
                    </Modal.Header>
                    <Modal.Content className='forms-box secondary-back-color' scrolling>
                        <Modal.Description>
                            <Container fluid textAlign='justified'>
                                <Header>1. Introduction</Header>

                                <p>Welcome to <b>Reservado</b>. <b>Reservado</b> is provided by <b>Reservado</b> ("we," "our," or "company"). By using <b>Reservado</b>, you agree to the following <b>Terms and Conditions of Use</b> ("Terms"). Please read them carefully.</p>

                                <Header>2. Privacy</Header>
                                <ul className='no-bullets'>
                                    <li>
                                        <Header as='h4'>Confidentiality of Information</Header>
                                        <p>We are committed to protecting the privacy of our users' data.</p>
                                        <p>Below, we explain how we handle the collected information:</p>

                                        <ol type="a">
                                            <li>
                                                <p><b>Customer accounts</b></p>
                                                <p>If you have a customer account, your personal information will only be accessible by you and the businesses where you make reservations. These businesses will have access to your information only for reservation management purposes and will not share your information with unauthorized third parties.</p>
                                            </li>
                                            <li>
                                                <p><b>Business accounts</b></p>
                                                <p>If you have a business account, your personal information will only be accessible by you and your customers. Your customers will have access to your information only for the purpose of making and managing reservations and will not share your information with unauthorized third parties.</p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <Header as='h4'>Security</Header>
                                        <p>The security of your personal information is important to us. We have implemented appropriate technical and organizational measures to protect your personal information against unauthorized access, misuse, loss, destruction, or alteration. However, no method of transmission over the Internet or electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>
                                    </li>
                                </ul>

                                <Header>3. Data Collection and Usage</Header>

                                <p>By registering on <b>Reservado</b>, you agree to provide accurate and complete information, including your name, address, phone number, and email address. This information is collected to facilitate contact between customers and businesses, to ensure the accuracy of accounts, and for business intelligence purposes. By providing this information, you agree that we may use it in accordance with our Privacy Policy.</p>

                                <Header>4. Usage Restrictions</Header>

                                <Header as='h4'>You agree not to use <b>Reservado</b> to:</Header>
                                <ol type="a">
                                    <li>Create fake accounts or provide false information;</li>
                                    <li>Violate any applicable laws, regulations, or rules;</li>
                                    <li>Infringe on the intellectual property rights, privacy, or other rights of third parties;</li>
                                    <li>Harass, abuse, or harm other users;</li>
                                    <li>Publish, transmit, or share illegal, defamatory, obscene, or offensive content.</li>
                                </ol>

                                <Header>5. Intellectual Property</Header>

                                <p>All content on <b>Reservado</b>, including but not limited to text, graphics, logos, icons, images, audio clips, digital downloads, and software, is the property of <b>Reservado</b> or its respective owners and is protected by international and local copyright laws.</p>

                                <Header>6. Modifications to Terms and Conditions</Header>

                                <p>We reserve the right to modify the Terms at any time and at our sole discretion. Modifications will take effect immediately upon being posted on <b>Reservado</b>. It is your responsibility to periodically review the Terms to be aware of any changes. If you continue to use <b>Reservado</b> after changes to the Terms have been posted, this will constitute your acceptance of the changes.</p>

                                <Header>7. Disclaimer of Warranties and Limitation of Liability</Header>
                                <p><b>Reservado</b> is provided "as is" and "as available." We do not guarantee that <b>Reservado</b> will be uninterrupted, secure, error-free, or meet your expectations. We disclaim all warranties, express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
                                <p>Under no circumstances shall <b>Reservado</b> be liable for direct, indirect, incidental, special, consequential, or punitive damages resulting from the use or inability to use <b>Reservado</b>, even if we have been informed of the possibility of such damages. The limitation of liability applies to the maximum extent permitted by law.</p>

                                <Header>8. Indemnification</Header>
                                <p>You agree to indemnify, defend, and hold harmless <b>Reservado</b>, its directors, employees, agents, and affiliates from any claims, liabilities, damages, losses, and expenses, including, without limitation, reasonable attorney's fees, arising from or related to your use of <b>Reservado</b>, your violation of these Terms, or the violation of third-party rights.</p>

                                <Header>9. Applicable Law and Jurisdiction</Header>
                                <p>These Terms and any disputes or claims related to <b>Reservado</b> will be governed and interpreted in accordance with the laws of Portugal, without regard to conflicts of legal principles. You agree to submit to the exclusive jurisdiction of the courts located in Portugal to resolve any disputes arising from these Terms.</p>

                                <Header>10. Termination</Header>
                                <p>We reserve the right to terminate or suspend your access to <b>Reservado</b> at any time and for any reason, including, but not limited to, violations of these Terms. Upon termination, all provisions of these Terms that, by their nature, should survive termination will continue to be in effect.</p>

                                <Header>11. Contact</Header>
                                <p>If you have any questions about these Terms or <b>Reservado</b>, please contact us at the official email address <b>official.reservado@gmail.com</b>.</p>

                                <Header>By using this application, you agree to comply with and be bound by our Terms and Conditions of Use. Please read them carefully before proceeding.</Header>
                                <Header>Last updated: 07/04/2023</Header>
                            </Container>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions className='secondary-back-color'>
                        <Button id='modal-accept-btn' circular onClick={handleClick} className='primary-color-active-btn'>
                            Do not show again<Icon name='chevron right' />
                        </Button>
                    </Modal.Actions>
                </>
            }
        </Modal>
    )
}

export default TermsNConditionsModal
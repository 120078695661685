import React from 'react'
import { Dropdown } from 'semantic-ui-react'

const CustomDropdown = (props) => {
    function formatData(data) {
        if (!props.hasOptions) {
            let newData = []
            data.forEach((item, index) => {
                newData.push({
                    key: index,
                    text: item.name,
                    value: item.id
                })
            })
            newData.sort((a, b) => a.text.localeCompare(b.text))
            return newData
        }
        return data
    }

    function handleChange(event, data) {
        props.handleDropdown(data.value)
    }

    return (
        <Dropdown
            id={props.text.toLowerCase().replaceAll(' ', '_')}
            placeholder={props.text}
            search
            selection
            defaultValue={props.default}
            options={formatData(props.data)}
            onChange={handleChange}
            noResultsMessage='Sem resultados.'
            fluid
        />
    )
}

export default CustomDropdown